import React, {useContext} from "react";
import PropTypes from "prop-types";
import AutocompleterComponent from "../../forms/customMappers/AutocompleterComponent";
import {AUTOCOMPLETER_URL} from "../../../util/RestRoutes";
import {MyDataContext} from "../../../ToolsComponent";

export default function AutocompleterFilter(props: any) {
    const [state] = useContext(MyDataContext);

    return <AutocompleterComponent id={props.testId}
                                   key={props.tableName + props.columnName + "_autocompleterFilter"}
                                   label={props.label}
                                   name={props.columnName}
                                   value={props.filterValue}
                                   url={`${AUTOCOMPLETER_URL}/${props.dbName}`}
                                   customerObjectID={state.currentCustomer?.id}
                                   onChange={props.onChange}/>
}

AutocompleterFilter.propTypes = {
    filterValue: PropTypes.array,
    columnName : PropTypes.string.isRequired,
    testId     : PropTypes.string.isRequired,
    label      : PropTypes.string.isRequired,
    tableName  : PropTypes.string.isRequired,
    onChange: PropTypes.func.isRequired,
    dbName  : PropTypes.string.isRequired
}