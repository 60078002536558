import ColumnComponent, {CustomFilterListOptions, FilterOptions} from "./ColumnComponent";
import PercentageFilter from "../filters/percentageFilterComponent";
import {Filter, FilterCriterion, MuiTableColumn, ServerColumn} from "../../../classes";

export default class PercentageColumn extends ColumnComponent {

    getDisplayValue(value: number | null | undefined, stringsOnly = false) {
        if (value === undefined || value === null || isNaN(value)) {
            return value;
        }

        value = value * 100

        if (value > 0 && value < 0.1) {
            return "< 0.1%"
        }

        if (value > 99.9 && value < 100) {
            return "> 99.9%"
        }

        return value.toFixed(1) + "%";
    }

    getCustomFilterListOptions(column: ServerColumn): CustomFilterListOptions {
        return {
            render: v => {
                if (v[0] === "") return `${column.title}: Less than or equal to ${v[1]}%`;
                if (v[1] === "") return `${column.title}: Greater than or equal to ${v[0]}%`;
                return `${column.title}: ${v[0]}%-${v[1]}%`;
            }
        };
    }

    getFilterOptions(toolUUID: string | null, tableName: any, serverColumn: ServerColumn): FilterOptions | null {
        return {
            logic(pct: any, filters: any) {
                return false;
            },
            display: (filterList: any[], onChange: any, index: number, muiTableColumn: MuiTableColumn, filterData: any, toolFilters: Filter[] = []) => (
                <PercentageFilter label={muiTableColumn.label}
                                  filterList={filterList}
                                  onChange={onChange}
                                  index={index}
                                  column={muiTableColumn}/>
            ),
        };
    }

    getFilter(column: ServerColumn, filterArray: any[]) {
        if (!filterArray || filterArray.length === 0) {
            return null;
        }

        if (filterArray?.find(a => a !== undefined && a !== null) === undefined) {
            return null;
        }

        let filter = new Filter(column.attr)

        const leftNumber = filterArray[0];
        if (filterArray.length === 1) {
            filter.criteria.push(new FilterCriterion("eq", leftNumber * 0.01))
            return filter;
        }

        const rightNumber = filterArray[1];

        if (leftNumber && !rightNumber) {
            filter.criteria.push(new FilterCriterion("ge", leftNumber * 0.01))
        } else if (rightNumber && !leftNumber) {
            filter.criteria.push(new FilterCriterion("le", rightNumber * 0.01))
        } else if (leftNumber > rightNumber) {
            filter.criteria.push(new FilterCriterion("ge", rightNumber * 0.01))
            filter.criteria.push(new FilterCriterion("le", leftNumber * 0.01))
        } else {
            filter.criteria.push(new FilterCriterion("ge", leftNumber * 0.01))
            filter.criteria.push(new FilterCriterion("le", rightNumber * 0.01))
        }

        return filter;
    }


}