import React from 'react';
import ReactDOM from "react-dom";
import {EventType, InteractionRequiredAuthError, PublicClientApplication} from "@azure/msal-browser";
import {loginRequest, msalConfig} from "./auth/authConfig";
import App from "./App";
import axios from 'axios';
import 'react-pdf/dist/esm/Page/AnnotationLayer.css';
import axiosRetry from "axios-retry";
import './index.scss';
import {BrowserRouter} from "react-router-dom"; // Import regular stylesheet

axiosRetry(axios, {
    retries: 5, // number of retries
    retryDelay: (retryCount) => {
        console.log(`retry attempt: ${retryCount}`);
        return retryCount * 1000; // time interval between retries
    },
    retryCondition: (error) => {
        // if retry condition is not specified, by default idempotent requests are retried
        return error.response?.status !== 200;
    },
});


// For GET requests
axios.interceptors.request.use(
    async (config) => {
        const token = await refreshAccessToken();
        config.headers.Authorization = `Bearer ${token}`
        return config;
    },
    (error) => Promise.reject(error)
);
export const refreshAccessToken = async () => {
    const account = msalInstance.getAllAccounts()[0];
    try {
        const token = await msalInstance.acquireTokenSilent({
            scopes: loginRequest.scopes,
            account,
        });
        return token.accessToken;
    } catch (error) {
        if (error instanceof InteractionRequiredAuthError) {
            return msalInstance
                .acquireTokenPopup(loginRequest)
                .then((resp) => resp.idToken)
                .catch((err) => null);
        }
        console.error(error);
    }
};

// For POST requests
axios.interceptors.response.use(
    (res) => {
        // Add configurations here
        if (res.status === 201) {
            console.log('Posted Successfully');
        }
        return res;
    },
    (err) => {
        return Promise.reject(err);
    }
);


const msalInstance = new PublicClientApplication({
    ...msalConfig,
    auth: {...msalConfig.auth, redirectUri: window.location.href.split("#code=")[0]}
});

// Default to using the first account if no account is active on page load
if (!msalInstance.getActiveAccount() && msalInstance.getAllAccounts().length > 0) {
    // Account selection logic is app dependent. Adjust as needed for different use cases.
    msalInstance.setActiveAccount(msalInstance.getAllAccounts()[0]);
}


msalInstance.addEventCallback((event) => {
    if (
        (event.eventType === EventType.LOGIN_SUCCESS ||
            event.eventType === EventType.ACQUIRE_TOKEN_SUCCESS ||
            event.eventType === EventType.SSO_SILENT_SUCCESS) &&
        event.payload.account
    ) {
        msalInstance.setActiveAccount(event.payload.account);
    }
});

ReactDOM.render(
    <React.StrictMode>
        <BrowserRouter>
            <App instance={msalInstance}/>
        </BrowserRouter>
    </React.StrictMode>,
    document.getElementById('root')
)
