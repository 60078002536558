import PropTypes, {array} from 'prop-types'
import {FormGroup, FormLabel} from "@mui/material";
import React from "react";
import {CustomTextField} from "../../forms/customMappers/CustomTextField";

export default function NumberFilter(props) {
    const filterArray = props.filterList[props.index];
    return filterArray ? <div>
        <FormLabel>{props.label}</FormLabel>
        <FormGroup row>
            <CustomTextField
                id={props.column.testId + "_filter_min"}
                value={filterArray[0] || ''}
                label={"Min:"}
                type='number'
                onChange={event => {
                    filterArray[0] = event.target.value;
                    if (!filterArray[1]) {
                        filterArray[1] = null
                    }
                    props.onChange(filterArray, props.index, props.column);
                }}
            />
            <CustomTextField
                id={props.column.testId + "_filter_max"}
                type='number'
                label='max'
                value={filterArray[1] || ''}
                onChange={event => {
                    if (!filterArray[0]) {
                        filterArray[0] = null
                    }

                    filterArray[1] = event.target.value;
                    props.onChange(filterArray, props.index, props.column);
                }}
            />
        </FormGroup>
    </div> : null
}

NumberFilter.propTypes = {
    column: PropTypes.object.isRequired,
    filterList: PropTypes.arrayOf(array).isRequired,
    index: PropTypes.number.isRequired,
    label: PropTypes.string.isRequired,
    onChange: PropTypes.func.isRequired
}