import useFormApi from "@data-driven-forms/react-form-renderer/use-form-api";
import React, {useEffect} from "react";
import FormSpy from "@data-driven-forms/react-form-renderer/form-spy";
import {useFieldApi} from "@data-driven-forms/react-form-renderer";

const FieldListener = (props: any) => {
    const {getState, change} = useFormApi();
    const {input, formula} = useFieldApi(props);

    useEffect(() => {

        if (getState().values) {
            // @ts-ignore

            switch (formula.operation) {
                case "SUM":
                    change(input.name.split("_")[0], formula.values.map((v: any) => getState().values[v])
                        .filter((v: any) => v !== null && v !== undefined)
                        .map((v: any) => typeof v === "object" && v.value !== null && v.value !== undefined ? v.value : v)
                        .reduce((sum: any, current: any) => sum + current, 0));
                    break
                case "STRINGVALUE":
                    const value = getState().values[input.name.split("_")[0]];
                    if (typeof value === 'string') {
                        change(input.name.split("_")[0], value.split(','));
                    } else {
                        change(input.name, value?.filter((v: any) => v).join(","));
                    }
                    break
                default:
                    console.warn("Unsupported formula operation " + formula.operation)
            }

            // @ts-ignore
            // change(input.name.split("_")[0], `${getState().values} ${new Date().toGMTString()}`);
        }
    }, [getState().values]);

    return null;
};

export default function CalculatedField(props: any) {
    // @ts-ignore
    return <FormSpy subcription={{values: true}}>{() => <FieldListener {...props}/>}</FormSpy>;
}
