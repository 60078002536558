import useFieldApi from "@data-driven-forms/react-form-renderer/use-field-api";
import React, {useEffect} from "react";
import SelectCustom2 from "./SelectCustom2";


export default function SelectCustom(props: any) {
    const {
              label,
              isInTable,
              input,
              isRequired,
              isReadOnly,
              meta,
              initialValue,
          }                            = useFieldApi(props);
    const {dirty, submitFailed, error} = meta;
    const isError                      = (dirty || submitFailed) && error && typeof error === 'string';
    useEffect(() => {
        if (initialValue) {
            input.onChange(initialValue);
        }
    }, []);

    const readOnlyDisplay = <div>{label}: <span style={{fontWeight: "bold"}}>
        {props.options.find((o: any) => o.value === input.value)?.description}
        
    </span>
    </div>;
    return isReadOnly ? readOnlyDisplay :
        <SelectCustom2
            name={input.name}
            label={isInTable ? null : label}
            value={input.value}
            options={props.options}
            valid={meta.valid}
            onChange={input.onChange}
            isReadOnly={isReadOnly}
            isInTable={isInTable}
            isError={isError}
            isRequired={isRequired}
        />;
}