import Moment from "moment";
import React from "react";
import {startCase} from "lodash";

function formatData(dataToFormat) {

    if (dataToFormat === null) {
        return "--"
    }

    Moment.locale('en');
    let moment = Moment(dataToFormat, "YYYY-MM-DD", true);
    if (moment.isValid()) {
        return moment.format('YYYY/MM/DD')
    }


    let url = null

    try {
        url = new URL(dataToFormat)
    } catch {
        // do nothing
    }

    if (url !== null) {
        return <a href={dataToFormat} target="_blank" rel="noreferrer noopener">URL: {dataToFormat}</a>
    }

    return dataToFormat


}


function getEmpty(object) {

    let empty = true

    for (let i = 0; i < Object.values(object).length; i++) {
        let v = Object.values(object)[i]
        if (typeof v === 'object' && v !== null) {
            if (Array.isArray(v)) {
                if (v.length > 0) {
                    empty = v.every((item) => getEmpty(item))
                    if (!empty) {
                        break;
                    }
                }
            } else {
                empty = getEmpty(v);
                if (!empty) {
                    break;
                }
            }
        } else {
            empty = v === undefined || v === null || v === '';
            if (!empty) {
                break;
            }
        }
    }

    return empty;
}

function getFormattedKey(key) {
    if (key === key.toUpperCase()) {
        return key
    }

    return startCase(key)
}

export {getEmpty, getFormattedKey, formatData}

