import * as React from "react";
import {GoogleChartDashboard, GoogleChartWrapper, GoogleDataTable, GoogleViz, ReactGoogleChartProps,} from "../types";
import {emitCustomEvent} from 'react-custom-events'
import cloneDeep from "lodash.clonedeep"
import {loadDataTableFromSpreadSheet} from "../load-data-table-from-spreadsheet";
import {ContextConsumer} from "../Context";
import {isNumber} from "lodash";
import {DEFAULT_CHART_COLORS} from "../../../../styles/getMuiTheme";

const GRAY_COLOR = "#CCCCCC";
export type ChartDrawArgs = {
    data: ReactGoogleChartProps["data"];
};

export type GoogleChartDataTableProps = {
    googleChartWrapper: GoogleChartWrapper;
    google: GoogleViz;
    googleChartDashboard: GoogleChartDashboard | null;
};

interface State {
    hiddenColumns: string[];
}

export class GoogleChartDataTableInner extends React.Component<ReactGoogleChartProps & GoogleChartDataTableProps,
    State> {
    state = {
        hiddenColumns: [],
    } as State;

    private renderDynamicColumns = (data: any, options: any) => {

        let newData = [] as any[]

        data.forEach((d: any) => newData.push([...d]))

        if (options.dynamicColumns) {

            options.dynamicColumns.forEach((dc: any) => {
                newData[0].push(dc.name)
                for (let i = 1; i < newData.length; i++) {

                    let dataRow = newData[i];


                    switch (dc.type) {
                        case 'SUM':
                            let sum = 0

                            for (let j = 1; j < dataRow.length; j++) {
                                if (!this.state.hiddenColumns.includes(newData[0][j])) {
                                    const dataRowElement = dataRow[j];
                                    if (isNumber(dataRowElement)) {
                                        sum += dataRowElement
                                    }
                                }
                            }

                            newData[i].push(sum)
                            break
                        case 'AVG':
                            let avg   = 0
                            let count = 0
                            for (let j = 1; j < dataRow.length; j++) {
                                if (!this.state.hiddenColumns.includes(newData[0][j])) {
                                    const dataRowElement = dataRow[j];
                                    if (isNumber(dataRowElement)) {
                                        avg += dataRowElement
                                        count++
                                    }

                                }
                            }

                            newData[i].push(avg / count)

                    }


                }
            })

        }

        return newData;
    }

    private listenToLegendToggle = () => {
        const {google, googleChartWrapper} = this.props;
        google.visualization.events.addListener(
            googleChartWrapper,
            "select",
            () => {

                emitCustomEvent('google-chart-select', googleChartWrapper)

                const chart     = googleChartWrapper.getChart();
                const selection = chart.getSelection();
                const dataTable = googleChartWrapper.getDataTable();
                if (
                    selection.length === 0 ||
                    // We want to listen to when a whole row is selected. This is the case only when row === null
                    selection[0].row ||
                    !dataTable
                ) {
                    return;
                }
                const columnIndex = selection[0].column;
                const columnID    = this.getColumnID(dataTable, columnIndex);
                if (this.state.hiddenColumns.includes(columnID)) {
                    this.setState((state) => ({
                        ...state,
                        hiddenColumns: [
                            ...state.hiddenColumns.filter((colID) => colID !== columnID),
                        ],
                    }));
                } else {
                    this.setState((state) => ({
                        ...state,
                        hiddenColumns: [...state.hiddenColumns, columnID],
                    }));
                }
            }
        );
    };

    private applyFormatters      = (dataTable: GoogleDataTable, formatters: any[]) => {
        const {google} = this.props;
        for (let formatter of formatters) {
            switch (formatter.type) {
                case "ArrowFormat": {
                    const vizFormatter = new google.visualization.ArrowFormat(
                        formatter.options
                    );
                    vizFormatter.format(dataTable, formatter.column);
                    break;
                }
                case "BarFormat": {
                    const vizFormatter = new google.visualization.BarFormat(
                        formatter.options
                    );
                    vizFormatter.format(dataTable, formatter.column);
                    break;
                }
                case "ColorFormat": {
                    const vizFormatter = new google.visualization.ColorFormat(
                        formatter.options
                    );
                    const {ranges}     = formatter;
                    for (let range of ranges) {
                        vizFormatter.addRange(...range);
                    }
                    vizFormatter.format(dataTable, formatter.column);
                    break;
                }
                case "DateFormat": {
                    const vizFormatter = new google.visualization.DateFormat(
                        formatter.options
                    );
                    vizFormatter.format(dataTable, formatter.column);
                    break;
                }
                case "NumberFormat": {
                    const vizFormatter = new google.visualization.NumberFormat(
                        formatter.options
                    );
                    vizFormatter.format(dataTable, formatter.column);
                    break;
                }
                case "PatternFormat": {
                    const vizFormatter = new google.visualization.PatternFormat(
                        formatter.options
                    );
                    vizFormatter.format(dataTable, formatter.column);
                    break;
                }
            }
        }
    };
    private getColumnID          = (dataTable: GoogleDataTable, columnIndex: number) => {
        return (
            dataTable.getColumnId(columnIndex) ||
            dataTable.getColumnLabel(columnIndex)
        );
    };
    private draw                 = async ({
                                              data,
                                              diffdata,
                                              rows,
                                              columns,
                                              options,
                                              legend_toggle,
                                              legendToggle,
                                              chartType,
                                              formatters,
                                              spreadSheetUrl,
                                              spreadSheetQueryParameters,
                                          }: ReactGoogleChartProps) => {
        const {google, googleChartWrapper} = this.props;
        let dataTable: GoogleDataTable;
        let chartDiff                      = null;
        if (diffdata) {
            const oldData = google.visualization.arrayToDataTable(diffdata.old);
            const newData = google.visualization.arrayToDataTable(diffdata.new);
            chartDiff     = google.visualization[chartType].prototype.computeDiff(
                oldData,
                newData
            );
        }

        let dynamicData: any[] | null = null

        if (data !== null) {
            if (Array.isArray(data)) {
                dynamicData = this.renderDynamicColumns(data, options)
                dataTable   = google.visualization.arrayToDataTable(dynamicData);
            } else {
                dataTable = new google.visualization.DataTable(data);
            }
        } else if (rows && columns) {
            dataTable = google.visualization.arrayToDataTable([columns, ...rows]);
        } else if (spreadSheetUrl) {
            dataTable = (await loadDataTableFromSpreadSheet(
                google,
                spreadSheetUrl,
                spreadSheetQueryParameters
            )) as GoogleDataTable;
        } else {
            dataTable = google.visualization.arrayToDataTable([]);
        }
        const columnCount = dataTable.getNumberOfColumns();

        const viewColumns = Array(columnCount)
            .fill(0)
            .map((c, i) => {
                const columnID = this.getColumnID(dataTable, i);
                if (this.state.hiddenColumns.includes(columnID)) {
                    return {
                        label: dataTable.getColumnLabel(i),
                        type : dataTable.getColumnType(i),
                        calc : () => null,
                    };
                } else {
                    return i;
                }
            });
        const chart       = googleChartWrapper.getChart();
        if (googleChartWrapper.getChartType() === "Timeline") {
            chart && chart.clearChart();
        }
        googleChartWrapper.setChartType(chartType);

        let newOptions: any
        if (options?.dynamicColumns) {

            newOptions = {...options}
            if (!newOptions.series) {
                newOptions.series = {}
            }

            options.dynamicColumns.forEach((dc: any) => {
                if (dynamicData === null) return;
                let seriesIndex = dynamicData[0].indexOf(dc.name) - 1;
                if (dc.series) {
                    newOptions.series[seriesIndex] = dc.series
                }
            })

        }

        googleChartWrapper.setOptions(newOptions || options || {});
        const viewTable = new google.visualization.DataView(dataTable);
        viewTable.setColumns(viewColumns);
        googleChartWrapper.setDataTable(viewTable);
        googleChartWrapper.draw();
        if (this.props.googleChartDashboard !== null) {
            this.props.googleChartDashboard.draw(dataTable);
        }

        if (chartDiff) {
            googleChartWrapper.setDataTable(chartDiff);
            googleChartWrapper.draw();
        }
        if (formatters) {
            this.applyFormatters(dataTable, formatters);
            googleChartWrapper.setDataTable(dataTable);
            googleChartWrapper.draw();
        }
        if (legendToggle === true || legend_toggle === true) {
            this.grayOutHiddenColumns({options});
        }
        return;
    };
    private grayOutHiddenColumns = ({
                                        options,
                                    }: {
        options: ReactGoogleChartProps["options"];
    }) => {
        const {googleChartWrapper} = this.props;
        const dataTable            = googleChartWrapper.getDataTable();
        if (!dataTable) return;
        const columnCount      = dataTable.getNumberOfColumns();
        const hasAHiddenColumn = this.state.hiddenColumns.length > 0;

        const modifiedSeries = options && options.series ? cloneDeep(options.series) : null

        const colors = Array.from({length: columnCount - 1}).map(
            (dontcare, i) => {
                const columnID = this.getColumnID(dataTable, i + 1);
                if (this.state.hiddenColumns.includes(columnID)) {
                    if (modifiedSeries && modifiedSeries[i]) {
                        modifiedSeries[i].color = GRAY_COLOR
                    }
                    return GRAY_COLOR;
                } else if (options && options.colors) {
                    return options.colors[i];
                } else {
                    return DEFAULT_CHART_COLORS[i];
                }
            }
        );

        let newOptions = {...options, series: modifiedSeries}

        googleChartWrapper.setOptions({
            ...newOptions,
            colors,
        });
        googleChartWrapper.draw();
    };
    private onResize             = () => {
        const {googleChartWrapper} = this.props;
        googleChartWrapper.draw();
    };

    componentDidMount() {
        this.draw(this.props);
        window.addEventListener("resize", this.onResize);
        if (this.props.legend_toggle || this.props.legendToggle) {
            this.listenToLegendToggle();
        }
    }

    componentWillUnmount() {
        const {google, googleChartWrapper} = this.props;
        window.removeEventListener("resize", this.onResize);
        google.visualization.events.removeAllListeners(googleChartWrapper);
        if (googleChartWrapper.getChartType() === "Timeline") {
            googleChartWrapper.getChart() &&
            googleChartWrapper.getChart().clearChart();
        }
    }

    componentDidUpdate() {
        this.draw(this.props);
    }

    render() {
        return null;
    }
}

export class GoogleChartDataTable extends React.Component<GoogleChartDataTableProps> {
    componentDidMount() {
    }

    componentWillUnmount() {
    }

    shouldComponentUpdate() {
        return false;
    }

    render() {
        const {google, googleChartWrapper, googleChartDashboard} = this.props;
        return (
            <ContextConsumer
                render={(props) => {
                    return (
                        <GoogleChartDataTableInner
                            {...props}
                            google={google}
                            googleChartWrapper={googleChartWrapper}
                            googleChartDashboard={googleChartDashboard}
                        />
                    );
                }}
            />
        );
    }
}
