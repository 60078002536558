import React, {useContext} from "react";
import moment from "moment";
import Button from "@mui/material/Button";
import {mapCsvData} from "../../ColumnsUtil";
import {CircularProgress, Dialog, DialogContent, DialogContentText, DialogTitle, LinearProgress} from "@mui/material";
import {downloadCSV, transformData} from "../../util/utils";
import DynamicIconButton from "../icons/dynamicIconButtonComponent";
import {MyDataContext} from "../../ToolsComponent";
import {progressSpinnerColor} from "../../styles/getMuiTheme";
import PropTypes from "prop-types";
import {getForms} from "../../util/FormUtils";
import {TOOLS_URL} from "../../util/RestRoutes";
import {doPost} from "../../util/RestUtil";
import {MUIDataTableVirtualized} from "./mui-datatables-virtualized/MUIDataTableVirtualized";
import getTextLabels from "./mui-datatables/textLabels";


export default function DataTableBetaComponent(props: any) {

    const [state] = useContext(MyDataContext);

    const drilldownForms                        = getForms("DRILLDOWN", props.tool.dbName)
    const [showProgress, setShowProgress]       = React.useState(false);
    const [downloading, setDownloading]         = React.useState(false);
    const [progressMessage, setProgressMessage] = React.useState("starting...");
    const [progressValue, setProgressValue]     = React.useState(0);
    const maxRecords                            = 1000000;
    const recordsPerBatch                       = 10000;

    async function fetchReportForCsv(key: any,
                                     rebuild: boolean,
                                     params: any,
                                     statusFunc: any) {
        try {

            let accumulatedData = [];
            let response: any;
            let page            = 0;
            let total           = 0;
            do {
                params   = {...params, paging: {page: page++, rows: recordsPerBatch}};
                response = await doPost(`${TOOLS_URL}/${key}`, JSON.stringify(params), null, state.currentCustomer?.id)
                accumulatedData.push(...response.data);
                total += response.data.length;
                statusFunc(total);
            } while (response.data.length === recordsPerBatch && total < maxRecords);

            return accumulatedData;
        } catch (e) {
            console.log(e)
        }
    }

    async function downloadAsyncCsv(buildHead: any, buildBody: any, columns: any, data: any) {
        setProgressMessage("starting...");
        setProgressValue(0);
        setShowProgress(true);
        setDownloading(true);

        data                = await fetchReportForCsv(props.tool?.dbName, false, props.tool?.params,
            (count: number) => {
                const totalRecords = props.tool?.pagingInfo.recordCount < maxRecords ? props.tool?.pagingInfo.recordCount : maxRecords;
                const percent      = (count / totalRecords) * 100;
                setProgressValue(percent);
                const s = count.toLocaleString() + " of " + totalRecords.toLocaleString() + " records (" + percent.toFixed(0) + "%)";
                setProgressMessage(s);
            }
        )
        let transformedData = transformData(columns, data, options)

        let indexedData = [];

        for (let i = 0; i < transformedData.length; i++) {
            indexedData.push({index: i, data: transformedData[i]});
        }

        let mappedData = mapCsvData(props.tool?.columns, columns, indexedData);
        let csv        = buildHead(columns) + buildBody(mappedData);
        downloadCSV(csv, `${(props.tool?.title || "").replaceAll(" ", "_")}_${moment().format("YYYYMMDD_hhmmss")}.csv`)
        setShowProgress(false);
        setDownloading(false);
    }

    const options = {
        serverSide                  : true,
        setTableProps               : () => ({id: "data_table_" + props.tool.title.replaceAll(" ", "_")}),
        elevation                   : 0,
        filter                      : true,
        print                       : false,
        search                      : false,
        sortOrder                   : props.tool?.sortOrder,
        onFilterChange              : (filterList: any) => {
            state.handleToolFilterChange(props.tool, filterList);
        },
        onColumnSortChange          : (name: any, direction: any) => state.handleTableSortChange(props.tool, name, direction),
        onViewColumnsChange         : (changedColumn: any, action: any) => state.handleTableVisibleColumnsChange(props.tool, changedColumn, action),
        onColumnOrderChange         : (newColumnOrder: [], columnIndex: number, newPosition: number) => state.handleTableColumnOrderChange(props.tool, newColumnOrder, columnIndex, newPosition),
        onChangeRowsPerPage         : (numberOfRows: any) => state.handleTableNumberOfRowsChange(props.tool, numberOfRows),
        onChangePage                : (currentPage: any) => state.handleTablePageChange(props.tool, currentPage),
        enableNestedDataAccess      : ".",
        resizableColumns            : true,
        onRowClick                  : (rowData: string[], rowMeta: {
            dataIndex: number,
            rowIndex: number
        }) => state.handleTableRowClick(props.tool, rowData, rowMeta, drilldownForms),
        selectableRowsHeader        : false,
        selectableRowsHideCheckboxes: true,
        expandableRows              : false,
        confirmFilters              : true,
        onDownload                  : (buildHead: any, buildBody: any, columns: any, data: any) => {
            downloadAsyncCsv(buildHead, buildBody, columns, data).then(r => console.log(r));
            return false;
        },
        downloadOptions             : {
            filename: `${props.tool?.key}_${moment().format("YYYYMMDD_hhmmss")}.csv`
        },
        tableBodyMaxHeight          : 'calc(100vh - 155px)',
        tableBodyHeight             : 'calc(100% - 53px)',
        textLabels                  : {
            ...getTextLabels(),
            body: {
                noMatch: props.tool.isLoading || !props.tool.initialized ?
                    <CircularProgress
                        id={props.tool?.title.replaceAll(" ", "_") + "_loading"}
                        style={{
                            margin    : "20px",
                            marginLeft: "40vw",
                            color     : progressSpinnerColor.hexa(),
                            float     : "left"
                        }}/> : <div id={props.tool?.title.replaceAll(" ", "_") + "_noRecordsFound"}
                    >Sorry, no matching records found</div>,
            },
        },
        // Calling the applyNewFilters parameter applies the selected filters to the table
        customFilterDialogFooter: (currentFilterList: any, applyNewFilters: () => void) => {
            return (
                <Button color="primary"
                        style={{fontSize: '12px', position: "absolute", top: "24px", left: "164px"}}
                        onClick={() => applyNewFilters()}>Apply Filters</Button>
            );
        },

        expandableRowsHeader  : false,
        expandableRowsOnClick : drilldownForms.length > 0,
        jumpToPage            : true,
        fixedSelectColumn     : false,
        fixedHeader           : true,
        isRowExpandable       : (dataIndex: any, expandedRows: any) => {
            // Prevent expand/collapse of any row if there are 4 rows expanded already (but allow those already expanded to be collapsed)
            return !(expandedRows.data.length > 1 && expandedRows.data.filter((d: any) => d.dataIndex === dataIndex).length === 0);

        },
        draggableColumns      : {
            enabled       : true,
            transitionTime: 300
        },
        filterType            : "custom",
        selectToolbarPlacement: "none",
        rowsExpanded          : [],
        rowsPerPage           : props.tool?.params?.paging?.rows,
        responsive            : "standard",
        rowsPerPageOptions    : [10, 20, 40, 100, 200, 500],
        onRowExpansionChange  : (curExpanded: any, allExpanded: any, rowsExpanded: any) => state.handleTableRowExpansionChange(allExpanded)

    };

    return (
        !props.tool ? <>Loading</> :
            <>
                <Dialog open={showProgress}>
                    <DialogTitle>Downloading CSV
                        <DynamicIconButton id={"csv_download_close_button"}
                                           iconName={"close"}
                                           label={"Close"}
                                           style={{float: "right"}}
                                           onClick={() => setShowProgress(false)}/>
                    </DialogTitle>
                    <DialogContent>
                        {props.tool.pagingInfo?.recordCount > maxRecords ? "CSV Download Limited to 1000000 records." : null}
                        <DialogContentText>{progressMessage}</DialogContentText>
                        <LinearProgress variant={"determinate"} value={progressValue}/>
                    </DialogContent>
                </Dialog>
                <MUIDataTableVirtualized
                    tool={props.tool}
                    data={!props.tool.toolData || ((props.tool.isLoading || !props.tool.initialized) && !props.tool.silentReload) ? [] : props.tool.toolData}
                    columns={props.tool.mappedColumns || []}
                    handleTableSortChange={state.handleTableSortChange}
                    sortOrder={props.tool.sortOrder}
                    options={options}/>
            </>
    );
}

DataTableBetaComponent.propTypes = {
    tool     : PropTypes.object,
    showTitle: PropTypes.bool
}