import {Breadcrumbs, Link, Theme} from "@mui/material";
import {blackColor, displayFont, stateRefreshIconColor, themeColor1, whiteColor} from "../../styles/getMuiTheme";
import React, {useContext, useEffect} from "react";
import PropTypes from "prop-types";
import {makeStyles} from "@mui/styles";
import RefreshCircle from "../icons/refreshCircleComponent";
import {ArrowForwardIos, Sync} from "@mui/icons-material";
import {MyDataContext} from "../../ToolsComponent";
import {StackFrame, Tool, ToolGroup, ToolStack} from "../../classes";
import ReactMarkdown from "react-markdown";
import gfm from "remark-gfm";
import {Info} from "react-feather";
import {IconToolTip} from "../login/IconToolTip";
import Button from "@mui/material/Button";


export default function ToolBreadcrumbs(props: {
    toolStack: ToolStack | null;
    stackFrame: StackFrame | null,
    stateSaved: boolean
}) {

    const [state] = useContext(MyDataContext);

    const useStyles = makeStyles(
        // @ts-ignore

        (theme: Theme) => {

            return ({
                componentWrapper  : {
                    display        : "flex",
                    width: "calc(100vw - " + (state.sideBarWidth + 15) + "px)",
                    flexWrap       : "wrap",
                    height         : "fit-content",
                    backgroundColor: whiteColor.hexa()
                },
                breadcrumbsWrapper: {
                    display   : "flex",
                    width     : "100%",
                    alignItems: "center",
                },
                titleLineWrapper  : {
                    display    : "flex",
                    width      : "100%",
                    alignItems : "center",
                    marginTop  : "22px",
                    paddingLeft: "9px"
                },
                titleText         : {
                    display     : "flex",
                    color       : blackColor.hexa(),
                    fontSize    : "24px",
                    fontFamily  : displayFont,
                    fontWeight  : "500",
                    whiteSpace  : "nowrap",
                    overflow    : "hidden",
                    textOverflow: "ellipsis"
                },
                refreshArea       : {
                    display    : "flex",
                    alignItems : "center",
                    marginRight: "10px",
                },
                bcEndArrow        : {
                    height      : "14px !important",
                    width       : "14px !important",
                    marginTop   : "5px",
                    marginBottom: "2px",
                    marginLeft  : "0",
                    color       : "rgba(0,0,0,0.5)"
                },
                link              : {
                    fontSize: "12px",
                    cursor  : "pointer"
                },
                selectedLink      : {
                    fontSize  : "12px",
                    fontWeight: theme.typography.fontWeightBold
                },
                breadcrumbs       : {
                    paddingLeft : "10px",
                    paddingRight: "10px",
                    paddingTop  : "5px"
                },
                lastUpdated       : {
                    fontSize  : "12px",
                    paddingTop: "3px",
                },
                stateIcon         : {
                    width       : "25px",
                    height      : "25px",
                    paddingTop  : "5px",
                    marginBottom: "-5px",
                    color: stateRefreshIconColor.hexa()
                }

            });
        });
    let classes     = useStyles()

    let [displayTitle, setDisplayTitle]             = React.useState(null)
    let [displayDescription, setDisplayDescription] = React.useState(null)

    function getDisplayTitle(frame: StackFrame, showTab: boolean = true) {
        if (frame.type === "GROUP") {
            let toolGroup = frame.toolOrGroup as ToolGroup

            let selectedTab = null
            if (toolGroup.tabs?.length > 0 && showTab) {
                selectedTab = toolGroup.tabs[toolGroup.selectedTabIndex >= 0 ? toolGroup.selectedTabIndex : 0]
            }


            return (toolGroup.customTitle ? toolGroup.customTitle : toolGroup.name) + (selectedTab ? ' [' + selectedTab.name + ']' : '');
        } else {
            let tool = frame.toolOrGroup as Tool
            if ((tool.isLoading || !tool.initialized) && tool.customTitle) {
                return "Loading... ";
            } else {
                if (tool.customTitle) {
                    return tool.customTitle;
                } else {
                    return tool.customTitle || tool.title;
                }
            }

        }
    }

    function getDisplayDescription(frame: StackFrame) {
        //props.stackFrame.toolOrGroup.description
        if (frame.type === "GROUP") {
            let toolGroup = frame.toolOrGroup as ToolGroup
            return toolGroup.parsedTools && toolGroup.parsedTools.length === 1 ? toolGroup.parsedTools[0].description : toolGroup.description;
        } else {
            let tool = frame.toolOrGroup as Tool
            return tool.description
        }
    }

    useEffect(() => {
        if (props.stackFrame) {
            setDisplayTitle(getDisplayTitle(props.stackFrame, false))
            setDisplayDescription(getDisplayDescription(props.stackFrame))
        }
    }, [props.stackFrame])

    let links = [] as any[]

    props.toolStack?.stackFrames.forEach((f: StackFrame) => {
        links.push({
            title   : getDisplayTitle(f),
            selected: f.selected,
            uuid    : f.uuid,
            frame   : f
        })
    })

    return <div className={classes.componentWrapper}>
        <div className={classes.breadcrumbsWrapper}>
            <Breadcrumbs className={classes.breadcrumbs} aria-label="breadcrumb"
                         maxItems={5}
                         separator={<ArrowForwardIos className={classes.bcEndArrow}/>}>
                {/*Tool Group link for reference*/}
                <Link className={classes.link}
                      key={props.toolStack?.toolCategoryName}
                      id={props.toolStack?.toolCategoryName.replaceAll(" ", "_") + "_breadcrumb"}
                      underline={"none"}
                      color="rgba(0,0,0,0.6)">
                    {props.toolStack?.toolCategoryName}
                </Link>
                {/*Stack frame links*/}
                {
                    links.map((link) => {
                            return <Link className={link.selected ? classes.selectedLink : classes.link}
                                         key={link.uuid}
                                         id={link.title.replaceAll(" ", "_") + "_breadcrumb"}
                                         underline={link.id ? "hover" : "none"}
                                         color={themeColor1.hexa()}
                                         onClick={(e) => state.handleBreadcrumbClick(link.frame)}>
                                {link.title}
                            </Link>;
                        }
                    )
                }
            </Breadcrumbs>
            {/*State saving icon*/}
            {
                props.toolStack && props.stackFrame && !props.stateSaved ?
                    <div id={'toolStateNotSaved'} className={classes.lastUpdated}>
                        <Sync className={classes.stateIcon}/>
                    </div> : <span id={'toolStateSaved'}></span>
            }

        </div>

        {/*Title, refresh circle, last refreshed*/}

        {
            props.toolStack && props.stackFrame ?
                <div className={classes.titleLineWrapper}>
                    <div className={classes.titleText}>
                        {displayTitle}
                    </div>
                    {
                        props.stackFrame.toolOrGroup?.forms && props.stackFrame.toolOrGroup?.forms.map((f: any) => {
                            return <div>
                                <Button variant={"text"}
                                        onClick={() => state.handleFormButtonClick(f.view)}>{f.label}</Button>
                            </div>
                        })
                    }
                    <div className={classes.refreshArea} id={"stackframe_reload_button"}>
                        {displayDescription &&
                            <IconToolTip iconButton={<Info style={{width: "24px"}}/>} title={"Info"}>
                                <ReactMarkdown remarkPlugins={[gfm]}>
                                    {displayDescription}
                                </ReactMarkdown>
                            </IconToolTip>
                        }
                        <RefreshCircle onClick={() => state.handleStackFrameReload(props.stackFrame)}
                                       error={props.stackFrame?.toolOrGroup.error}
                                       label={"Reload"}/>
                    </div>
                </div> : null
        }
    </div>


}
ToolBreadcrumbs.propTypes = {
    toolStack : PropTypes.object,
    stateSaved: PropTypes.bool,
    stackFrame: PropTypes.object,
}