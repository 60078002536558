import React from "react";
import useFieldApi from "@data-driven-forms/react-form-renderer/use-field-api";
import {StyledToolTip} from "../../../ColumnsUtil";
import {CustomTextField} from "./CustomTextField";

const paragraphStyle = {
    marginTop   : 0,
    marginBottom: 4,
};

export default function FileFieldCustom(props: any) {
    const {
              label,
              description,
              isInTable,
              input,
              isRequired,
              meta: {error, touched}
          } = useFieldApi(props);

    let textFieldLabel: any;

    if (isInTable) {
        textFieldLabel = null;
    } else if (description) {
        textFieldLabel = <StyledToolTip key={input.name + "tooltip"} title={description}>
            <div>{label}</div>
        </StyledToolTip>;
    } else {
        textFieldLabel = label;
    }


    return <>

        <CustomTextField
            id={input.name}
            label={textFieldLabel}
            type={"file"}
            name={input.name}
            error={error}
            aria-describedby="my-helper-text"
            required={isRequired}
            onChange={input.onChange}/>
        {touched && error && <p style={paragraphStyle}>{error}</p>}
    </>;
}
