import React from "react";
import {useMsal} from "@azure/msal-react";
import {loginRequest} from "../../auth/authConfig";
import {Button} from "@mui/material";
import {signInButtonBgColor, signInButtonTxColor} from "../../styles/getMuiTheme";

function handleLogin(instance) {
    instance.loginRedirect(loginRequest).catch(e => {
        console.error(e);
    });
}

/**
 * Renders a button which, when selected, will redirect the page to the login prompt
 */
export const SignInButton = (props) => {
    const {instance} = useMsal();

    return (
        <Button style={{
            backgroundColor: signInButtonBgColor.hexa(),
            color: signInButtonTxColor.hexa()
        }}
                variant={"contained"}
                type="button"
                className={props.className}
                onClick={() => handleLogin(instance)}>Sign In</Button>
    );
}
