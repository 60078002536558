import React, {useContext} from "react";
import * as PropTypes from "prop-types";
import {makeStyles} from "@mui/styles";
import Button from "@mui/material/Button";
import {getForms} from "../../util/FormUtils";
import {Table, TableBody, TableContainer, TableFooter, TablePagination} from "@mui/material";
import TableRow from "@mui/material/TableRow";
import {getTotalPages} from "../../util/utils";
import {MyDataContext} from "../../ToolsComponent";

const useStyles = makeStyles((theme) => {

    return ({
        wrapper: {
            display: "flex",
            alignItems: "center"
        },
        rowDisplayBadge: {
            fontSize: "14px",
            fontWeight: "500",
            width: 'fit-content',
            whiteSpace: "nowrap",
            marginRight: "10px",
            padding: "4px 10px",
            borderRadius: "16px",
            backgroundColor: "#EFF8FF",
            color: "#175CD3"
        },
    });
});

export function DataTableTitle(props) {

    const classes = useStyles()
    const [state] = useContext(MyDataContext);

    const page = props.tool.params.paging.page;
    const rows = props.tool.params.paging.rows;
    const count = props.tool?.pagingInfo?.recordCount;
    const recordsOnPageIfFull = (page * rows) + rows;
    const headerForms = getForms("HEADER", props.tool.dbName)

    const startRow = ' ' + (count === 0 ? 0 : ((page * rows) + 1));
    const endRow = (count !== null && count !== undefined && !isNaN(count) && recordsOnPageIfFull > count ? count : recordsOnPageIfFull) + ' ';
    const rowCount = ' ' + (count !== null && count !== undefined && !isNaN(count) ? count : '(loading...)');

    const rowMessage = <span>Rows
        <span id={props.tool?.title.replaceAll(" ", "_") + "_startRow"}>{startRow}</span>
        –
        <span id={props.tool?.title.replaceAll(" ", "_") + "_endRow"}>{endRow}</span>
        of
        <span id={props.tool?.title.replaceAll(" ", "_") + "_rowCount"}>{rowCount}</span>
    </span>;

    return <>
        {!props.tool ? "Preparing" : props.tool?.isRunning ? "running" :
            props.tool?.pagingInfo ?
                <div className={classes.wrapper}>
                    <div className={classes.rowDisplayBadge}>
                        {rowMessage}
                    </div>
                    <div className={classes.paginationWrapper}>
                        {/*The below table container stuff is to avoid a stupid error within MuiTablePagination*/}
                        <TableContainer>
                            <Table>
                                <TableBody/>
                                <TableFooter>
                                    <TableRow>
                                        <TablePagination
                                            id={props.tool?.title.replaceAll(" ", "_") + "_pagination"}
                                            count={props.tool?.pagingInfo?.recordCount}
                                            labelRowsPerPage={"Rows:"}
                                            labelDisplayedRows={
                                                ({from, to, count, page}) => {
                                                    if (count === undefined || count === null) {
                                                        return "Loading..."
                                                    }
                                                    let totalPages = getTotalPages(count, props.tool.params.paging.rows);
                                                    let pageNumber = page + 1;
                                                    return <>Page <span
                                                        id={props.tool?.title.replaceAll(" ", "_") + "_currentPage"}>{pageNumber}</span> of <span
                                                        id={props.tool?.title.replaceAll(" ", "_") + "_totalPages"}>{totalPages}</span>
                                                    </>;
                                                }
                                            }
                                            onRowsPerPageChange={(event) => state.handleTableNumberOfRowsChange(props.tool, event.target.value)}
                                            rowsPerPageOptions={[10, 20, 40, 100, 200, 500]}
                                            page={props.tool.params.paging.page}
                                            rowsPerPage={props.tool.params.paging.rows}
                                            onPageChange={(e, n) => state.handleTablePageChange(props.tool, n)}/>
                                    </TableRow>
                                </TableFooter>
                            </Table>
                        </TableContainer>
                        {props.tool.forms ?
                            <Button onClick={() => state.handleCreateButtonClick(props.tool)}>Create</Button> : null}
                    </div>
                    {headerForms && headerForms.map(f => {
                        return <div key={f.label} className={classes.createButton}>
                            <Button variant={"contained"}
                                    onClick={() => state.handleFormButtonClick(f.view)}>{f.label}</Button>
                        </div>
                    })
                    }

                </div> : null
        }

    </>;
}

DataTableTitle.propTypes = {
    tool: PropTypes.object,
    showTitle: PropTypes.bool
}