import React from "react";
import {useDrilldownStyles} from "./DrillDownModal";
import DrillDownAccordion from "./DrillDownAccordion";
import {getFormattedKey} from "./DrillDownFormattingUtils";
import DrillDownTableRow from "./DrillDownTableRow";

export default function DrillDownTable(props) {

    let tableRows = props.tableRows
    let key = props.name
    let depth = props.depth


    let columnNames = Object.keys(tableRows[0])
    let classes = useDrilldownStyles({depth: depth})()

    if (typeof tableRows[0] === 'string') {
        return <div>{tableRows.map((r) => <div>{r}</div>)}</div>
    }

    return <DrillDownAccordion defaultExpanded={true}
                               summarytext={`${getFormattedKey(key)} (${tableRows.length})`}
                               depth={depth}>
        <div style={{overflow: "auto"}}>
            <table className={classes.table}>
                <thead>
                <tr>
                    {columnNames.map((k, i) => <th key={getFormattedKey(k)}
                                                   className={classes.tableHeader}>{getFormattedKey(k)}</th>)}
                </tr>
                </thead>
                <tbody>
                {tableRows.map((row, i) => <DrillDownTableRow key={i} row={row} name={key} index={i} depth={depth}/>)}
                </tbody>
            </table>
        </div>
    </DrillDownAccordion>


}