import PropTypes from 'prop-types'
import {CircularProgress} from "@mui/material";
import React from "react";

export default function LoadingCircle(props) {

    return props.active ?
        <CircularProgress size={props.size || 20}
                          thickness={4.0}/>
        : null;

}

LoadingCircle.propTypes = {
  active: PropTypes.bool,
  size: PropTypes.number
}