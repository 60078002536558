import ColumnComponent, {CustomFilterListOptions, FilterOptions} from "./ColumnComponent";
import TextFilter from "../filters/textFilterComponent";
import {Filter, MuiTableColumn, ServerColumn} from "../../../classes";
import {getTextColorAdvanced} from "../../../util/ColorUtil";

export default class TextColumn extends ColumnComponent {

    getDisplayValue(value: any, stringsOnly = false) {
        return <div style={{backgroundColor: value, color: getTextColorAdvanced(value).hexa()}}>{value}</div>
    }

    getCustomFilterListOptions(column: ServerColumn): CustomFilterListOptions {
        return {
            render: v => {
                if (v.length > 1) {
                    return `${column.title}: ${v.join(", ")}`;
                } else {
                    return `${column.title}: ${v[0]}`;
                }
            },
            update: (filterList: any[], filterPos: number, index: number) => {
                filterList[index] = [];
                return filterList;
            }
        }
    }

    getFilterOptions(toolUUID: string | null, tableName: string, serverColumn: ServerColumn): FilterOptions | null {
        return {
            logic(value, filters) {
                return false;
            },
            display: (filterList: any[], onChange: any, index: number, muiTableColumn: MuiTableColumn, filterData: any, toolFilters: Filter[] = []) => (
                <TextFilter label={muiTableColumn.label}
                            filterList={filterList}
                            tableName={tableName}
                            toolUUID={toolUUID}
                            onChange={value => {
                                filterList[index] = [value];
                                onChange(filterList[index], index, muiTableColumn);
                            }}
                            index={index}
                            column={muiTableColumn}/>
            ),
        };
    }

}

