import moment from "moment/moment";
import ColumnComponent, {CustomFilterListOptions, FilterOptions} from "./ColumnComponent";
import {Filter, FilterCriterion, MuiTableColumn, ServerColumn} from "../../../classes";
import MonthFilter from "../filters/monthFilterComponent";
import {convertToMonthNames} from "../../../util/DateUtil";

export default class MonthColumn extends ColumnComponent {

    getDisplayValue(value: any, stringsOnly = false): string {
        if (value === undefined || value === null) {
            return "";
        }

        const monthVal = moment().month(value - 1).format("MMMM");
        return monthVal === "undefined" ? value.toString() : monthVal;
    }

    getCustomFilterListOptions(rc: ServerColumn): CustomFilterListOptions {
        return {
            render: (monthIndexes: any) => {
                return `${rc.title}: ${convertToMonthNames(monthIndexes).join(', ')}`;
            },
            update: (filterList: any[], filterPos: number, index: number) => {
                filterList[index] = [];
                return filterList;
            }
        }
    }

    getFilterOptions(toolUUID: string | null, tableName: string, serverColumn: ServerColumn): FilterOptions | null {
        return {
            logic(value: any, filters: any) {
                return false;
            },
            display: (filterList: any[], onChange: any, index: number, muiTableColumn: MuiTableColumn, filterData: any, toolFilters: Filter[] = [], displayAsList: boolean, multiselect: boolean) => {

                const filterValue = filterList && filterList[index] && filterList[index].length ? filterList[index] : [];

                return <MonthFilter label={muiTableColumn.label}
                                    filterValue={filterValue}
                                    key={muiTableColumn.name + index}
                                    existingFilters={toolFilters}
                                    tableName={tableName}
                                    multiselect={multiselect}
                                    displayAsList={displayAsList}
                                    onChange={event => {
                                        if (Array.isArray(event.target.value)) {
                                            filterList[index] = [...event.target.value];
                                        } else {
                                            filterList[index] = [event.target.value];
                                        }
                                        onChange(filterList[index], index, muiTableColumn);
                                    }}
                                    testId={muiTableColumn.testId}
                                    columnName={muiTableColumn.name}/>;
            }
        };
    }

    getFilter(column: ServerColumn, filterArray: any[]): Filter | null {
        if (!filterArray || filterArray.length === 0) {
            return null;
        }

        if (filterArray?.find(a => a !== undefined && a !== null) === undefined) {
            return null;
        }

        const filter = new Filter(column.attr);
        filterArray  = filterArray.map(v => v === "(blank)" ? null : v)
        if (filterArray.length === 1) {
            filter.criteria.push(new FilterCriterion("eq", filterArray[0]))
        } else {
            filter.criteria.push(new FilterCriterion("in", filterArray))
        }

        return filter;
    }


}

