import React from "react";
import {X} from "react-feather";
import {themeColor1, whiteColor} from "../../../styles/getMuiTheme";
import PropTypes from "prop-types";
import {makeStyles} from "@mui/styles";
import {Theme} from "@mui/material";

export default function AutocompleterDisplay(props: any) {

    const useStyles = makeStyles(
        // @ts-ignore
        (theme: Theme) => {
            return ({
                label    : {
                    marginLeft: "-5px",
                    fontWeight: 500,
                    fontSize  : "14px"
                },
                container: {
                    display        : "flex",
                    justifyContent : 'space-between',
                    backgroundColor: themeColor1.hexa(),
                    color          : whiteColor.hexa(),
                    padding        : "8px",
                    margin         : "5px 5px 5px -5px",
                    lineHeight     : "18px",
                    borderRadius   : "8px",
                    whiteSpace     : 'pre-line',
                    fontSize       : "14px",
                    fontWeight     : 600
                }
            });
        }
    );

    const classes = useStyles()

    return <div>
        <div className={classes.label}>{props.label}</div>
        <div className={classes.container}>
            <div>{props.displayValue}</div>
            {props.isReadOnly ? null : <div><X size={16} onClick={props.onClose}/></div>}
        </div>
    </div>
}

AutocompleterDisplay.propTypes = {
    displayValue: PropTypes.string.isRequired,
    isReadOnly  : PropTypes.bool.isRequired,
    label       : PropTypes.string.isRequired,
    onClose     : PropTypes.func.isRequired
}