import React from "react";
import PropTypes from "prop-types";
import {mapLayout} from "./FreeformUtils";
import Paper from "@mui/material/Paper";

export default function PaperLayout(props: { meta: any, data: any }) {

    return <Paper key={JSON.stringify(props.meta)} style={{padding: "5px"}}>
        {props.meta.fields?.map((f: any) => mapLayout(f, props.data ? props.data : null))}
    </Paper>

}

PaperLayout.propTypes = {
    meta: PropTypes.any.isRequired,
    data: PropTypes.any
}