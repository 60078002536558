import jsonpath from "jsonpath";
import {Typography} from "@mui/material";
import React, {useContext} from "react";
import PropTypes from "prop-types";
import {FreeformToolContext} from "../../JsonTool";

export default function TypographyField(props: { meta: any, data: any }) {

    const [data] = useContext(FreeformToolContext);

    let value

    if (props.meta.fixedvalue) {
        value = props.meta.fixedvalue
    } else {
        try {
            if (props.data) {
                value = jsonpath.query(props.data, props.meta.value);
            } else {
                value = data ? jsonpath.query(data, props.meta.value) : null;
            }
        } catch (e) {
            value = props.meta.value
        }
    }

    let styles = {} as any

    if (props.meta.padding) {
        styles["padding"] = props.meta.padding
    }
    if (props.meta.margin) {
        styles["margin"] = props.meta.margin
    }

    return <Typography sx={styles} variant={props.meta.variant || 'body1'}
                       key={JSON.stringify(props.meta)}>{value}</Typography>
}

TypographyField.propTypes = {
    meta: PropTypes.any.isRequired,
    data: PropTypes.any
}