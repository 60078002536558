import {AuthenticatedTemplate, UnauthenticatedTemplate} from "@azure/msal-react";
import LoginPage from "./login/login";

/**
 * Renders the navbar component with a sign-in button if a user is not authenticated
 */
export const PageLayout = (props) => {
    return (
        <>
            <UnauthenticatedTemplate>
                <LoginPage/>
            </UnauthenticatedTemplate>

            <AuthenticatedTemplate>
                {props.children}
            </AuthenticatedTemplate>
        </>
    );
}