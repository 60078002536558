import jsonpath from "jsonpath";
import {useContext} from "react";
import {FreeformToolContext} from "../../JsonTool";
import PropTypes from "prop-types";
import {mapLayout} from "./FreeformUtils";

export function ListLayout(props: { meta: any, data: any }) {

    const [data, datasources] = useContext(FreeformToolContext);
    let listData: any
    try {
        listData = data ? jsonpath.query(data, props.meta.value) : null;
        if (!listData || listData.length === 0) {
            listData = [{'Result': 'No Data Found'}]
        }
    } catch (e) {
        listData = [{'Result': 'No Data Found'}]
    }

    let grainValues: any
    try {
        grainValues = data ? jsonpath.query(data, props.meta.value + "." + props.meta.grain) : null;
        if (!grainValues || grainValues.length === 0) {
            grainValues = []
        }
    } catch (e) {
        grainValues = []
    }
    grainValues = [...new Set(grainValues)];

    return <>
        {grainValues.map((v: string) => {
            return <div key={v}>{
                props.meta.fields.map((f: any) => {
                    const data1 = listData.filter((d: any) => d[props.meta.grain] === v);
                    return mapLayout(f, data1, JSON.stringify(f) + v);
                })
            } </div>
        })}
    </>

    // return <pre>{JSON.stringify(grainValues, null, 2)}</pre>;
}

ListLayout.propTypes = {
    meta: PropTypes.any.isRequired,
    data: PropTypes.any
}