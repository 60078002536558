import React from 'react';
import PropTypes from 'prop-types';

import {DatePicker} from '@mui/x-date-pickers';

import {input, meta} from '@data-driven-forms/common/prop-types-templates';
import {useFieldApi} from '@data-driven-forms/react-form-renderer';
import {validationError} from "@data-driven-forms/mui-component-mapper";
import moment from "moment";
import {CustomTextField} from "./CustomTextField";

export default function DatePickerCustom(props: any) {
    const {
              input,
              isReadOnly,
              isDisabled,
              placeholder,
              isRequired,
              label,
              helperText,
              description,
              validateOnMount,
              meta,
              DatePickerProps,
          }       = useFieldApi(props);

    const invalid = validationError(meta, validateOnMount);
    const styles = {margin: "22px 0 5px 0"};

    return (
        <DatePicker
            sx={styles}
            renderInput={(props: any) => (
                <CustomTextField
                    {...props}
                    fullWidth
                    margin="normal"
                    label={label}
                    helperText={invalid || ((meta.touched || validateOnMount) && meta.warning) || helperText || description}
                    placeholder={placeholder}
                    required={!!isRequired}
                    error={!!invalid}
                />
            )}
            label={label}
            disabled={isDisabled || isReadOnly}
            readOnly={isReadOnly}
            {...input}
            value={input.value ? moment(input.value) : null}
            {...DatePickerProps}
        />
    );
}

DatePickerCustom.propTypes = {
    input,
    meta,
    isReadOnly        : PropTypes.bool,
    isDisabled        : PropTypes.bool,
    placeholder       : PropTypes.node,
    isRequired        : PropTypes.bool,
    label             : PropTypes.node,
    helperText        : PropTypes.node,
    validateOnMount   : PropTypes.bool,
    description       : PropTypes.node,
    FormFieldGridProps: PropTypes.object,
    DatePickerProps   : PropTypes.object,
};

DatePickerCustom.defaultProps = {
    FormFieldGridProps: {},
    DatePickerProps   : {},
};
