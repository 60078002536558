import {formatData, getFormattedKey} from "./DrillDownFormattingUtils";
import DrillDownArray from "./DrillDownArray";
import DrillDownObject from "./DrillDownObject";
import React from "react";
import {CircularProgress, Grid} from "@mui/material";
import {errorColor, progressSpinnerColor} from "../../styles/getMuiTheme";
import {useTheme} from "@mui/styles";

export default function DrillDownDetail(props) {

    const theme = useTheme()

    let object = props.object;
    let depth = props.depth;

    if (!object) {
        return <div style={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            width: '100%',
            height: '100%',
        }}>
            <CircularProgress style={{color: progressSpinnerColor.hexa()}}/>
        </div>
    }

    return <div style={{maxWidth: "1200px"}}>
        <Grid container>
            {
                Object.keys(object).filter((k) => k !== "FormLabel").map((key, index) => {

                        if (depth === 0 && index === 0) {
                            return null;
                        }

                        let objAttr = object[key];

                        if (!objAttr) {
                            return null
                        }

                        switch (typeof objAttr) {
                            case "string":
                            case "number":
                                return <Grid key={key} item xs={12} sm={12} md={12} lg={12} xl={12}>
                                    <div style={{display: 'flex', justifyContent: 'space-between', margin: '4px 10px'}}>
                                        <div>{getFormattedKey(key)}</div>
                                        <div style={{
                                            flexGrow: 1,
                                            borderBottom: '1px solid black',
                                            margin: "0 2px 4px 2px"
                                        }}></div>
                                        <div>{formatData(objAttr)}</div>
                                    </div>
                                </Grid>
                            case "object":
                                if (Array.isArray(objAttr)) {
                                    return <Grid key={key} item xs={12} sm={12} md={12} lg={12} xl={12} style={{paddingBottom: "10px"}}>
                                        <DrillDownArray arr={objAttr} name={key} depth={depth + 1}/>
                                    </Grid>;
                                } else {
                                    return <Grid key={key} item xs={12} sm={12} md={12} lg={12} xl={12} style={{paddingBottom: "10px"}}>
                                        <DrillDownObject object={objAttr} name={key} depth={depth + 1}/>
                                    </Grid>;

                                }
                            default:
                                return <div style={{color: errorColor.hexa()}}>SHOULD NOT SHOW UP {typeof objAttr}</div>
                        }
                    }
                )
            }
        </Grid>
    </div>;
}
