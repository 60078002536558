import React, {useContext, useState} from "react";
import PropTypes from "prop-types";
import {getColumnType} from "../../ColumnsUtil";
import YearFilter from "../datatable/filters/yearFilterComponent";
import {FreeformToolContext, FreeformToolDispatchContext} from "../../JsonTool";
import MonthFilter from "../datatable/filters/monthFilterComponent";
import EnumFilter from "../datatable/filters/enumFilterComponent";
import {Filter, FilterCriterion} from "../../classes";
import AutocompleterFilter from "../datatable/filters/AutocompleterFilterComponent";


export default function FilterField(props: { meta: any, data: any }) {


    const [data, datasources, detailID, idColumnName] = useContext(FreeformToolContext);
    const dispatch = useContext(FreeformToolDispatchContext);
    let filterDefinitionStrs

    console.log("FILTER", props, props.meta.filter)

    if (typeof props.meta.filter === "string") {
        filterDefinitionStrs = props.meta.filter.split("|")
    }

    const filterDatasource  = filterDefinitionStrs ? filterDefinitionStrs[0] : props.meta.filter.source
    const filterColumnName  = filterDefinitionStrs ? filterDefinitionStrs[1] : props.meta.filter.column
    const filterDisplayName = filterDefinitionStrs ? filterDefinitionStrs[2] : props.meta.filter.displayName
    const filterColumnType  = filterDefinitionStrs ? filterDefinitionStrs[3] : props.meta.filter.type
    const filterDbName      = filterDefinitionStrs ? filterDefinitionStrs[4] : props.meta.filter.dbName

    const filteredDataSourceNames = filterDatasource.indexOf("[") >= 0 ? filterDatasource.replaceAll("[", "").replaceAll("]", "").split(",") : [filterDatasource];
    const filteredDataSources     = datasources?.filter((x: any) => filteredDataSourceNames.includes(x.name));
    const firstDataSource         = filteredDataSources[0];
    const filters                 = firstDataSource.params?.filters || []
    const filterValue             = filters.find((f: Filter) => f.attr === filterColumnName)?.criteria?.map((c: FilterCriterion) => c.value) || []

    const [value, setValue] = useState(filterValue)

    const metadataColumns  = firstDataSource?.metadata?.columns;
    const columnDefinition = metadataColumns?.find((c: any) => c.name.split("|")[0] === filterColumnName);
    const columns          = firstDataSource?.columns;
    const column           = columns?.find((c: any) => c.attr.split("|")[0] === filterColumnName);


    let columnType  = filterColumnType ? filterColumnType : getColumnType(filterColumnName, columnDefinition?.type || "", metadataColumns, true)
    const tableName = firstDataSource.source.split("|")[0]

    if (columnType.includes(",")) {
        columnType = columnType.split(",").find((s: string) => s !== 'HIDDEN') || columnType
    }

    let options = props.meta.options || {}

    let reload      = options.reload || 'RELOAD' as 'RELOAD' | 'CLEAR' | 'CLEAR_ALL' | 'NONE'
    let multiselect = options.multiselect !== null && options.multiselect !== undefined ? options.multiselect : true

    if (multiselect === "false") {
        multiselect = false
    } else if (multiselect === "true") {
        multiselect = true
    }

    const getFilter = () => {
        switch (columnType) {
            case 'YEAR':
                return <YearFilter label={filterColumnName}
                                   filterValue={value}
                                   key={filterColumnName + filterDatasource}
                                   multiselect={multiselect}
                                   existingFilters={[]}
                                   tableName={tableName}
                                   onChange={(e) => {
                                       let val = e.target.value
                                       if (!Array.isArray(val)) {
                                           val = [val]
                                       }
                                       setValue(val)
                                       dispatch.handleFilterChange(filteredDataSources, filterColumnName, e.target.value, reload)
                                   }}
                                   testId={filterColumnName + filterDatasource}
                                   columnName={filterColumnName}/>;
            case 'MONTH':
                return <MonthFilter label={filterColumnName}
                                    filterValue={value}
                                    multiselect={multiselect}
                                    key={filterColumnName + filterDatasource}
                                    existingFilters={[]}
                                    tableName={tableName}
                                    onChange={(e) => {
                                        let val = e.target.value
                                        if (!Array.isArray(val)) {
                                            val = [val]
                                        }
                                        setValue(val)
                                        dispatch.handleFilterChange(filteredDataSources, filterColumnName, e.target.value, reload)
                                    }}
                                    testId={filterColumnName + filterDatasource}
                                    columnName={filterColumnName}/>;
            case 'LIMITED_TEXT':
                return <EnumFilter label={filterColumnName}
                                   filterValue={value}
                                   multiselect={multiselect}
                                   key={filterColumnName + filterDatasource}
                                   existingFilters={[]}
                                   tableName={tableName}
                                   onChange={(e) => {
                                       let val = e.target.value
                                       if (!Array.isArray(val)) {
                                           val = [val]
                                       }
                                       setValue(val)
                                       dispatch.handleFilterChange(filteredDataSources, filterColumnName, e.target.value, reload)
                                   }}
                                   testId={filterColumnName + filterDatasource}
                                   selectOptions={column.selectOptions}
                                   columnName={filterColumnName}/>;
            case 'AUTOCOMPLETER':
                return <AutocompleterFilter label={filterDisplayName}
                                            filterValue={value}
                                            key={filterColumnName + filterDatasource}
                                            tableName={tableName}
                                            dbName={filterDbName}
                                            onChange={(e, value) => {
                                                let val = value
                                                if (!Array.isArray(val)) {
                                                    val = [val]
                                                }
                                                setValue(val)
                                                dispatch.handleFilterChange(filteredDataSources, filterColumnName, value, reload)
                                            }}
                                            testId={filterColumnName + filterDatasource}
                                            columnName={filterColumnName}/>
            default:
                return <div>{filterColumnName} {columnType}</div>
        }
    }

    const filter = getFilter();
    return <div>{filter}</div>

}

FilterField.propTypes = {
    meta: PropTypes.any.isRequired,
    data: PropTypes.any
}