import React from "react";
import useFieldApi from "@data-driven-forms/react-form-renderer/use-field-api";
import {Textarea} from "@data-driven-forms/mui-component-mapper";

const paragraphStyle = {
    marginTop   : 0,
    marginBottom: 4,
};

export default function TextAreaCustom(props: any) {
    const {autocompleter, longtext, label, isInTable, input, isRequired, meta: {error, touched}} = useFieldApi(props);

    if (autocompleter) {

        const subscribeMethod = (topic: string, msg: any) => {
            input.onChange(msg ? msg[autocompleter.field] : null)
        }

        PubSub.subscribe('autocompleter' + autocompleter.name, subscribeMethod);
    }
    const styles = {margin: "22px 0 5px 0"};

    return (
        <>
            <Textarea id={input.name}
                      InputLabelProps={{shrink: false}}
                      label={isInTable ? null : label}
                      disabled={false}
                      placeholder={label}
                      sx={styles}
                      name={input.name}
                      error={error}
                      minRows={longtext?.minrows || 2}
                      value={input.value}
                      aria-describedby="my-helper-text"
                      required={isRequired}
                      onChange={input.onChange}/>
            {touched && error && <p style={paragraphStyle}>{error}</p>}
        </>
    );
}
