import React from "react";
import PropTypes from "prop-types";
import AutocompleterComponent from "../forms/customMappers/AutocompleterComponent";
import {AUTOCOMPLETER_URL} from "../../util/RestRoutes";

export default function AutocompleterField(props: { meta: any, data: any }) {

    return <AutocompleterComponent id={'asdf'}
                                   key={JSON.stringify(props.meta)}
                                   label={props.meta.label}
                                   name={props.meta.name}
                                   url={`${AUTOCOMPLETER_URL}/${props.meta.url}`}
                                   onChange={(e) => console.log(e)}/>
}

AutocompleterField.propTypes = {
    meta: PropTypes.any.isRequired,
    data: PropTypes.any
}