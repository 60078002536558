import React, {useContext} from "react";
import DynamicIconButton from "../icons/dynamicIconButtonComponent";
import {signOutButtonColor} from "../../styles/getMuiTheme";
import {MyDataContext} from "../../ToolsComponent";

/**
 * Renders a button which, when selected, will redirect the page to the logout prompt
 */
export const SignOutButton = (props: any) => {
    const [state] = useContext(MyDataContext);

    return (
        <DynamicIconButton id={"sign_out_button"}
                           color={signOutButtonColor.hexa()}
                           iconName={"account_circle"}
                           onClick={() => state.handleLogout()} label={"Sign Out"}/>
    );
}