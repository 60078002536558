import ColumnComponent, {FilterOptions} from "./ColumnComponent";
import NumberFilter from "../filters/numberFilterComponent";
import {Filter, FilterCriterion, MuiTableColumn, ServerColumn} from "../../../classes";

export default class NumberColumn extends ColumnComponent {

    getDisplayValue(value: any, stringsOnly = false) {
        return value === undefined || value === null ? "" : value.toLocaleString();
    }

    getCustomFilterListOptions(rc: ServerColumn) {
        return {
            render: (v: any[]) => {
                if (!v[0]) return `${rc.title}: Less than or equal to ${v[1]}`;
                if (!v[1]) return `${rc.title}: Greater than or equal to ${v[0]}`;
                return `${rc.title}: ${v[0]} to ${v[1]}`;
            }
        };
    }

    getSortCompare(rc: ServerColumn): any {
        return (order: string) => {
            return (obj1: any, obj2: any) => {
                let val1 = parseInt(obj1.data, 10);
                let val2 = parseInt(obj2.data, 10);
                return (val1 - val2) * (order === 'asc' ? 1 : -1);
            };
        }
    }

    getFilterOptions(toolUUID: string | null, tableName: string, serverColumn: ServerColumn): FilterOptions | null {
        return {
            names: [],
            logic(n: any, filters: any) {
                return false;
            },
            display: (filterList: any[], onChange: any, index: number, muiTableColumn: MuiTableColumn, filterData: any, toolFilters: Filter[] = []) =>
                <NumberFilter label={muiTableColumn.label}
                              filterList={filterList}
                              onChange={onChange}
                              index={index}
                              column={muiTableColumn}/>
        };
    }

    getFilter(column: ServerColumn, filterArray: any[]): Filter | null {
        if (!filterArray || filterArray.length === 0) {
            return null;
        }

        if (filterArray?.find(a => a !== undefined && a !== null) === undefined) {
            return null;
        }

        let filter = new Filter(column.attr)

        const leftNumber = filterArray[0];
        if (filterArray.length === 1) {
            filter.criteria.push(new FilterCriterion("eq", leftNumber))
            return filter;
        }

        const rightNumber = filterArray[1];

        if (leftNumber && !rightNumber) {
            filter.criteria.push(new FilterCriterion("ge", leftNumber));
        } else if (rightNumber && !leftNumber) {
            filter.criteria.push(new FilterCriterion("le", rightNumber));
        } else if (leftNumber > rightNumber) {
            filter.criteria.push(new FilterCriterion("ge", rightNumber))
            filter.criteria.push(new FilterCriterion("le", leftNumber))
        } else {
            filter.criteria.push(new FilterCriterion("ge", leftNumber))
            filter.criteria.push(new FilterCriterion("le", rightNumber))
        }

        return filter;
    }


}