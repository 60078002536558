import Chip from '@mui/material/Chip';
import PropTypes from 'prop-types';
import React from 'react';
import clsx from 'clsx';

export default function TableFilterListItem(props: any) {
    const filterProps = props.filterProps || {};

    const className = filterProps.className ? clsx(props.className, filterProps.className) : props.className;

    return <Chip label={props.label}
                 onDelete={props.onDelete}
                 className={className}
                 {...filterProps} />;
}

TableFilterListItem.propTypes = {
    label    : PropTypes.node,
    onDelete : PropTypes.func.isRequired,
    className: PropTypes.string.isRequired,
};
