import DrillDownSubTable from "./DrillDownSubTable";
import DrillDownObject from "./DrillDownObject";
import {formatData} from "./DrillDownFormattingUtils";
import React from "react";
import {useDrilldownStyles} from "./DrillDownModal";

export default function DrillDownTableRow(props) {

    let row = props.row
    let key= props.name
    let index= props.index
    let depth = props.depth

    let classes = useDrilldownStyles({depth: depth})()

    return <tr key={key + "_" + index}>
        {Object.keys(row).map((rowKey, i) => {
            let rowValue = row[rowKey];
            if (typeof rowValue === 'object' && rowValue !== null) {
                if (Array.isArray(rowValue)) {
                    return <td key={rowKey+i} className={classes.tableCell}>
                        <DrillDownSubTable tableRows={rowValue} name={rowKey} depth={depth + 1}/>
                    </td>
                } else {
                    return <td key={rowKey+i} className={classes.tableCell}>
                        <DrillDownObject object={rowValue} name={rowKey} depth={depth + 1}/>
                    </td>
                }
            } else {
                return <td key={rowKey+i} className={classes.tableCell}>{formatData(rowValue)}</td>
            }
        })}
    </tr>
}