import PropTypes from 'prop-types'
import React from "react";
import {Icon, IconButton, Tooltip} from "@mui/material";

export default function DynamicIconButton(props) {
    return <Tooltip title={props.label} style={props.style} onClick={props.onClick}>
        <IconButton id={props.id} style={{color: props.color}}>
            <Icon>{props.iconName}</Icon>
        </IconButton>
    </Tooltip>
}

DynamicIconButton.propTypes = {
    id: PropTypes.string.isRequired,
    iconName: PropTypes.string.isRequired,
    label: PropTypes.string.isRequired,
    onClick: PropTypes.func.isRequired,
    color: PropTypes.string,
    style: PropTypes.object
}