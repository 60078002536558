import {FormControl, InputLabel, MenuItem, Select} from "@mui/material";
import React from "react";
import useFieldApi from "@data-driven-forms/react-form-renderer/use-field-api";
import * as Icon from "react-feather";

export default function IconFormComponent(props: any) {
    const {
              label,
              input,
              isRequired,
              meta
          }                            = useFieldApi(props);
    const {dirty, submitFailed, error} = meta;
    const isError                      = (dirty || submitFailed) && error && typeof error === 'string';

    return (
        <FormControl>
            <InputLabel id="demo-simple-select-label">{label}</InputLabel>
            <Select value={input.value}
                    name={input.name}
                    label={label}
                    error={isError}
                    required={isRequired}
                    onChange={input.onChange}>
                <MenuItem value="">
                    -- Select {label} --
                </MenuItem>

                {
                    Object.values(Icon).map((o) => {
                        // @ts-ignore
                        const IconTag = Icon[o.displayName];

                        return <MenuItem
                            key={o.displayName}
                            value={o.displayName}>
                            <IconTag/><span style={{marginLeft: "10px"}}>{o.displayName}</span>
                        </MenuItem>;
                    })
                }
            </Select>
        </FormControl>
    );
}
