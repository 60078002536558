import React, {useContext} from "react";
import PropTypes from "prop-types";
import {mapLayout} from "./FreeformUtils";
import Expansion from "../forms/customMappers/Expansion";
import jsonpath from "jsonpath";
import {FreeformToolContext} from "../../JsonTool";

export default function ExpansionLayout(props: { meta: any, data: any }) {

    let description           = props.meta.description
    let title                 = props.meta.title
    const [data, datasources] = useContext(FreeformToolContext);

    if (props.meta.description?.startsWith("$")) {
        if (props.data) {
            description = jsonpath.query(props.data, props.meta.description);
        } else {
            description = data ? jsonpath.query(data, props.meta.description) : null;
        }
    }
    if (props.meta.title?.startsWith("$")) {
        if (props.data) {
            title = jsonpath.query(props.data, props.meta.title);
        } else {
            title = data ? jsonpath.query(data, props.meta.title) : null;
        }
    }



    return <Expansion key={JSON.stringify(props.meta)}
                      title={title}
                      sx={props.meta.styles}
                      expansionOpened={props.meta.options.initialExpanded === "ALL" || true}
                      description={description}>
        {props.meta.fields?.map((f: any) => mapLayout(f, props.data ? props.data : null))}
    </Expansion>
}
ExpansionLayout.propTypes = {
    meta: PropTypes.any.isRequired,
    data: PropTypes.any
}