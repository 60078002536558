import {ServerColumn} from "../../../classes";
import ColumnComponent from "./ColumnComponent";

export default class JsonColumn extends ColumnComponent {

    getDisplayValue(value: any, stringsOnly = false) {
        return <pre>{JSON.stringify(JSON.parse(value), null, 2)}</pre>
    }

    getFilterType(rc: ServerColumn) {
        return "multiselect"
    }
}