import moment from "moment/moment";
import ColumnComponent, {CustomFilterListOptions, FilterOptions} from "./ColumnComponent";
import DateFilter from "../filters/dateFilterComponent";
import {Filter, FilterCriterion, MuiTableColumn, ServerColumn} from "../../../classes";

export default class DateColumn extends ColumnComponent {

    getDisplayValue(value: any, stringsOnly = false) {
        return value === undefined || value === null ? "" : moment(value).format("yyyy/MM/DD");
    }

    getCustomFilterListOptions(column: ServerColumn): CustomFilterListOptions {
        return {
            render: (value: any) => {

                const format = "MMM D, YYYY";
                if (!value[0] || value[0] === "") {
                    return `${column.title}: On or before ${moment(value[1]).format(format)}`;
                }

                if (!value[1] || value[1] === "") {
                    return `${column.title}: On or after ${moment(value[0]).format(format)}`;
                }

                return `${column.title}: ${moment(value[0]).format(format)} to ${moment(value[1]).format(format)}`;
            },
            update: (filterList: any[], filterPos: number, index: number) => {
                filterList[index] = [];
                return filterList;
            }

        };
    }

    getFilterOptions(toolUUID: string | null, tableName: string, serverColumn: ServerColumn): FilterOptions | null {
        return {
            names: [],
            logic(date: any, filters: any) {
                return false;
            },
            display: (filterList: any[], onChange: any, index: number, muiTableColumn: MuiTableColumn, filterData: any, toolFilters: Filter[] = []) => (
                <DateFilter label={muiTableColumn.label}
                            filterList={filterList}
                            onChange={onChange}
                            index={index}
                            column={muiTableColumn}/>
            ),
        };
    }

    getFilter(column: ServerColumn, filterArray: any[]) {
        if (!filterArray || filterArray.length === 0) {
            return null;
        }

        if (filterArray?.find(a => a !== undefined && a !== null) === undefined) {
            return null;
        }

        let filter = new Filter(column.attr)

        const leftDate  = filterArray[0];
        const rightDate = filterArray[1];

        if (leftDate && !rightDate) {
            filter.criteria.push(new FilterCriterion("ge", leftDate))
        } else if (rightDate && !leftDate) {
            filter.criteria.push(new FilterCriterion("le", rightDate))
        } else if (leftDate > rightDate) {
            filter.criteria.push(new FilterCriterion("ge", rightDate))
            filter.criteria.push(new FilterCriterion("le", leftDate))
        } else {
            filter.criteria.push(new FilterCriterion("ge", leftDate))
            filter.criteria.push(new FilterCriterion("le", rightDate))
        }

        return filter;
    }
}