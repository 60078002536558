import moment from "moment/moment";


function processDualYStackedBarChart(toolData) {

    //MONTH             H1352 - PRO   H1352 - INS   H1352 - DME   H6502 - PRO   H6502 - INS   H6502 - DME
    // 2021 - March     5821          520           94            13171         2142          43
    // 2021 - April     34            5             0             78            5             0
    // 2021 - May       26965         7690          1997          61712         15200         3033
    // 2021 - June      9227          2226          973           21542         4824          1701
    // 2021 - July      10129         2695          1066          25299         6217          2572
    // 2021 - August    19529         4160          1440          29841         6438          2827
    // 2021 - September 7866          2188          1496          18908         4674          2858
    // 2021 - October   14390         11684         2134          30380         8846          3116
    // 2021 - November  10680         2824          1419          28410         6604          3004
    // 2021 - December  7010          1145          427           12782         2634          832

    // MONTH            H6502 - PRO   H6502 - INS   H6502 - DME
    // 2021 - March     13171         2142          43
    // 2021 - April     78            5             0
    // 2021 - May       61712         15200         3033
    // 2021 - June      21542         4824          1701
    // 2021 - July      25299         6217          2572
    // 2021 - August    29841         6438          2827
    // 2021 - September 18908         4674          2858
    // 2021 - October   30380         8846          3116
    // 2021 - November  28410         6604          3004
    // 2021 - December  12782         2634          832

    let originalColumnHeaders = toolData[0];

    let firstColumnHeaderPart = null
    let newData = []
    let newColumnHeaders = [originalColumnHeaders[0]]
    let headersComplete = false;
    for (let i = 1; i < toolData.length; i++) {
        const toolDataRow = toolData[i];

        let currentDataArray = []
        firstColumnHeaderPart = null

        let hasMultipleBars = false
        let columnHeaderParts
        for (let j = 1; j < originalColumnHeaders.length; j++) {
            let columnHeader = originalColumnHeaders[j]
            columnHeaderParts = columnHeader.split(" - ")
            if (firstColumnHeaderPart === null) {
                firstColumnHeaderPart = columnHeaderParts[0]
                currentDataArray.push(toolDataRow[0] + " - " + firstColumnHeaderPart)
            }
            if (columnHeaderParts[0] !== firstColumnHeaderPart) {
                if (!headersComplete) {
                    newData.push(newColumnHeaders)
                }
                headersComplete = true
                hasMultipleBars = true
                firstColumnHeaderPart = columnHeaderParts[0]
                newData.push([...currentDataArray])
                currentDataArray = []
                currentDataArray.push(toolDataRow[0] + " - " + firstColumnHeaderPart)
            } else {
                if (!headersComplete) {
                    newColumnHeaders.push(columnHeaderParts[1])
                }
            }
            currentDataArray.push(toolDataRow[j])

        }

        if (!hasMultipleBars) {
            if (!headersComplete) {
                newData.push(newColumnHeaders)
            }
            headersComplete = true
            firstColumnHeaderPart = columnHeaderParts[0]
            // currentDataArray.push(toolDataRow[0] + " - " + firstColumnHeaderPart)
            // currentDataArray.push(toolDataRow[j])
            newData.push([...currentDataArray])

            // for (let j = 1; j < newData.length; j++) {
            //     newData[0] = newData[0].split(" - ")
            // }


        } else {
            newData.push([...currentDataArray])

            let nullArray = ['']

            for (let j = 1; j < newData[0].length; j++) {
                nullArray.push(null)
            }
            newData.push([...nullArray])

        }


    }

    return [...newData]
}

// const data1 = processDualYStackedBarChart([
//     ["MONTH", "H1352 - PRO", "H1352 - INS", "H1352 - DME", "H6502 - PRO", "H6502 - INS", "H6502 - DME"],
//     ["2021 - March", 5821, 520, 94, 13171, 2142, 43],
//     ["2021 - April", 34, 5, 0, 78, 5, 0],
//     ["2021 - May", 26965, 7690, 1997, 61712, 15200, 3033],
//     ["2021 - June", 9227, 2226, 973, 21542, 4824, 1701],
//     ["2021 - July", 10129, 2695, 1066, 25299, 6217, 2572],
//     ["2021 - August", 19529, 4160, 1440, 29841, 6438, 2827],
//     ["2021 - September", 7866, 2188, 1496, 18908, 4674, 2858],
//     ["2021 - October", 14390, 11684, 2134, 30380, 8846, 3116],
//     ["2021 - November", 10680, 2824, 1419, 28410, 6604, 3004],
//     ["2021 - December", 7010, 1145, 427, 12782, 2634, 832]
// ])
// const data2 = processDualYStackedBarChart([
//     ["MONTH", "H6502 - PRO", "H6502 - INS", "H6502 - DME"],
//     ["2021 - March", 95, 25, 4],
//     ["2021 - April", 20131, 4467, 2108],
//     ["2021 - May", 686, 488, 67],
//     ["2021 - June", 289, 232, 34],
//     ["2021 - July", 391, 207, 65],
//     ["2021 - August", 11657, 815, 203],
//     ["2021 - September", 400, 214, 113],
//     ["2021 - October", 1804, 2806, 174],
//     ["2021 - November", 2150, 462, 291],
//     ["2021 - December", 466, 192, 94]
// ])
//
// console.log(data1)
// console.log(data2)
function getChartData(data) {
    let headers = data[0];

    for (let i = 1; i < data.length; i++) {
        for (let j = 0; j < headers.length; j++) {
            if (headers[j].type) {
                if (headers[j].type === "datetime" || headers[j].type === "date") {
                    data[i][j] = moment(data[i][j]).toDate()
                }
            }
        }
    }

    return data;
}

export {
    processDualYStackedBarChart,
    getChartData
}
