import React from "react";
import BooleanFilter from "../filters/booleanFilterComponent";
import ColumnComponent, {FilterOptions} from "./ColumnComponent";
import {Filter, FilterCriterion, MuiTableColumn, ServerColumn} from "../../../classes";

export const TRUE_VALUES = ['t', 'true', 'y', 'yes', '1']
export const FALSE_VALUES = ['f', 'false', 'n', 'no', '0']

export default class BooleanColumn extends ColumnComponent {
    getDisplayValue(value: any, stringsOnly = false) {

        if (value === undefined || value === null) {
            return "";
        }

        let truthy = true;

        if (!value) {
            truthy = false
        } else if (typeof value === "string") {
            truthy = TRUE_VALUES.includes(value.toLowerCase().trim());
        }

        return truthy ? "Yes" : "No";
    }


    getCustomFilterListOptions(column: ServerColumn) {

        const formatBean = (v: any) => {

            if (v === "(blank)") {
                return v
            }

            const displayValue = this.getDisplayValue(v);
            return displayValue === "" ? "(blank)" : displayValue;
        };

        return {
            render: (value: any) => {
                return value.length > 1 ? `${column.title}: ${value.map(formatBean).join(", ")}` : `${column.title}: ${formatBean(value[0])}`;
            }
        }
    }

    getFilterOptions(toolUUID: string | null, tableName: string, serverColumn: ServerColumn): FilterOptions | null {
        return {
            logic(value: any, filters: any) {
                return false;
            },
            display: (filterList: any[], onChange: any, index: number, muiTableColumn: MuiTableColumn, filterData: any, toolFilters: Filter[] = [], displayAsList: boolean, multiselect: boolean) => {

                const filterValue = filterList && filterList[index] && filterList[index].length ? filterList[index] : [];

                const selectOptions = [{key: "Yes", value: "1"}, {key: "No", value: "0"}, {
                    key  : "(blank)",
                    value: "(blank)"
                }];

                return <BooleanFilter label={muiTableColumn.label}
                                      filterValue={filterValue}
                                      key={muiTableColumn.name + index}
                                      existingFilters={toolFilters}
                                      tableName={tableName}
                                      multiselect={multiselect}
                                      displayAsList={displayAsList}
                                      selectOptions={selectOptions}
                                      onChange={event => {
                                          if (Array.isArray(event.target.value)) {
                                              filterList[index] = [...event.target.value];
                                          } else {
                                              filterList[index] = [event.target.value];
                                          }
                                          onChange(filterList[index], index, muiTableColumn);
                                      }}
                                      testId={muiTableColumn.testId}
                                      columnName={muiTableColumn.name}/>;
            }
        };
    }

    getFilter(column: ServerColumn, filterArray: any[]): Filter | null {
        if (!filterArray || filterArray.length === 0) {
            return null;
        }

        if (filterArray?.find(a => a !== undefined && a !== null) === undefined) {
            return null;
        }

        const filter = new Filter(column.attr);
        filterArray  = filterArray.map(v => v === "(blank)" ? null : v)
        if (filterArray.length === 1) {
            filter.criteria.push(new FilterCriterion("eq", filterArray[0]))
        } else {
            filter.criteria.push(new FilterCriterion("in", filterArray))
        }

        return filter;
    }


}

