import React from "react";
import useFieldApi from "@data-driven-forms/react-form-renderer/use-field-api";

const paragraphStyle = {
    marginTop   : 0,
    marginBottom: 4,
};

export default function DisplayField(props: any) {
    const {autocompleter, label, input, isRequired, meta: {error, touched}} = useFieldApi(props);

    if (autocompleter) {

        const subscribeMethod = (topic: string, msg: any) => {
            input.onChange(msg ? msg[autocompleter.field] : null)
        }

        PubSub.subscribe('autocompleter' + autocompleter.name, subscribeMethod);
    }

    const subscribeMethod = (topic: string, msg: any) => {
        input.onChange(msg)
    }

    PubSub.subscribe('self_' + input.name, subscribeMethod);

    return <div>{props.label}</div>;
}
