import CurrencyFormat from "react-currency-format";
import ColumnComponent, {FilterOptions} from "./ColumnComponent";
import MoneyFilter from "../filters/moneyFilterComponent";
import {Filter, FilterCriterion, MuiTableColumn, ServerColumn} from "../../../classes";

export default class MoneyColumn extends ColumnComponent {

    getDisplayValue(value: any, stringsOnly = false) {
        if (value === undefined || value === null) return "";

        if (stringsOnly) {
            return value
        }

        return <CurrencyFormat value={value}
                               fixedDecimalScale={true}
                               decimalScale={2}
                               thousandSeparator={true}
                               displayType={'text'}
                               prefix={"$"}/>
    }

    getCustomFilterListOptions(rc: ServerColumn): any {
        return {
            render: (value: any) => {
                if (!value[0]) return <span>{rc.title}: Less than or equal to <CurrencyFormat value={value[1]}
                                                                                              fixedDecimalScale={true}
                                                                                              decimalScale={2}
                                                                                              thousandSeparator={true}
                                                                                              displayType={'text'}
                                                                                              prefix={"$"}/></span>;
                if (!value[1]) return <span>{rc.title}: Greater than or equal to <CurrencyFormat value={value[0]}
                                                                                                 fixedDecimalScale={true}
                                                                                                 decimalScale={2}
                                                                                                 thousandSeparator={true}
                                                                                                 displayType={'text'}
                                                                                                 prefix={"$"}/></span>;
                return <span>{rc.title}: <CurrencyFormat value={value[0]}
                                                         fixedDecimalScale={true}
                                                         decimalScale={2}
                                                         thousandSeparator={true}
                                                         displayType={'text'}
                                                         prefix={"$"}/> to <CurrencyFormat value={value[1]}
                                                                                           fixedDecimalScale={true}
                                                                                           decimalScale={2}
                                                                                           thousandSeparator={true}
                                                                                           displayType={'text'}
                                                                                           prefix={"$"}/></span>;
            }
        };
    }

    getSortCompare(rc: ServerColumn): any {
        return (order: string) => {
            return (obj1: any, obj2: any) => {
                let val1 = parseInt(obj1.data, 10);
                let val2 = parseInt(obj2.data, 10);
                return (val1 - val2) * (order === 'asc' ? 1 : -1);
            };
        }
    }

    getFilterOptions(toolUUID: string | null, tableName: string, serverColumn: ServerColumn): FilterOptions | null {
        return {
            logic(n: any, filters: any) {
                return false;
            },
            display: (filterList: any[], onChange: any, index: number, muiTableColumn: MuiTableColumn, filterData: any, toolFilters: Filter[] = []) =>
                <MoneyFilter label={muiTableColumn.label}
                             filterList={filterList}
                             onChange={onChange}
                             index={index}
                             column={muiTableColumn}/>
        };
    }

    getFilter(column: ServerColumn, filterArray: any[]): Filter | null {
        if (!filterArray || filterArray.length === 0) {
            return null;
        }

        if (filterArray?.find(a => a !== undefined && a !== null) === undefined) {
            return null;
        }

        let filter = new Filter(column.attr)

        const leftNumber = filterArray[0];
        if (filterArray.length === 1) {
            filter.criteria.push(new FilterCriterion("eq", leftNumber))
            return filter;
        }

        const rightNumber = filterArray[1];

        if (leftNumber && !rightNumber) {
            filter.criteria.push(new FilterCriterion("ge", leftNumber));
        } else if (rightNumber && !leftNumber) {
            filter.criteria.push(new FilterCriterion("le", rightNumber));
        } else if (leftNumber > rightNumber) {
            filter.criteria.push(new FilterCriterion("ge", rightNumber))
            filter.criteria.push(new FilterCriterion("le", leftNumber))
        } else {
            filter.criteria.push(new FilterCriterion("ge", leftNumber))
            filter.criteria.push(new FilterCriterion("le", rightNumber))
        }

        return filter;
    }


}