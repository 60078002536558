import React, {useContext} from "react";
import PropTypes from "prop-types";
import Button from "@mui/material/Button";
import {FreeformToolDispatchContext} from "../../JsonTool";

export default function ActionButton(props: { meta: any, data: any }) {
    const dispatch = useContext(FreeformToolDispatchContext);

    return <Button color={"primary"}
                   type="button"
                   variant={"contained"}
                   onClick={() => dispatch.handleAction(props.meta.action)}>{props.meta.text}</Button>

}

ActionButton.propTypes = {
    meta: PropTypes.any.isRequired,
    data: PropTypes.any
}