import TypographyField from "./TypographyField";
import DivLayout from "./DivLayout";
import TableLayout from "./TableLayout";
import KvLayout from "./KvLayout";
import React from "react";
import CenterLayout from "./CenterLayout";
import ExpansionLayout from "./ExpansionLayout";
import PaperLayout from "./PaperLayout";
import AutocompleterField from "./AutocompleterField";
import GridItem from "./GridItem";
import GridContainer from "./GridContainer";
import FilterField from "./FilterField";
import ActionButton from "./ActionButton";
import CalendarComponent from "../calendar/CalendarComponent";
import FormButton from "./FormButton";
import TabsLayout from "./TabsLayout";
import FormLayout from "./FormLayout";
import {ListLayout} from "./ListLayout";

export function mapLayout(meta: any, data: any = null, key: string | null = null) {

    if (typeof data === "number") {
        data = null
    }

    switch (meta.type) {
        case 'typography-field':
            return <TypographyField key={key ? key : JSON.stringify(meta)} meta={meta} data={data}/>
        case 'div-layout':
            return <DivLayout key={key ? key : JSON.stringify(meta)} meta={meta} data={data}/>
        case 'center-layout':
            return <CenterLayout key={key ? key : JSON.stringify(meta)} meta={meta} data={data}/>;
        case 'expansion-layout':
            return <ExpansionLayout key={key ? key : JSON.stringify(meta)} meta={meta} data={data}/>
        case 'paper-layout':
            return <PaperLayout key={key ? key : JSON.stringify(meta)} meta={meta} data={data}/>
        case 'tabs-layout':
            return <TabsLayout key={key ? key : JSON.stringify(meta)} meta={meta} data={data}/>
        case 'autocompleter-field':
            return <AutocompleterField key={key ? key : JSON.stringify(meta)} meta={meta} data={data}/>
        case 'grid-item':
            return <GridItem key={key ? key : JSON.stringify(meta)} meta={meta} data={data}/>
        case 'grid-container':
            return <GridContainer key={key ? key : JSON.stringify(meta)} meta={meta} data={data}/>
        case 'table-layout':
            return <TableLayout key={key ? key : JSON.stringify(meta)} meta={meta} data={data}/>
        case 'kv-layout':
            return <KvLayout key={key ? key : JSON.stringify(meta)} meta={meta} data={data}/>
        case 'filter-field':
            return <FilterField key={key ? key : JSON.stringify(meta)} meta={meta} data={data}/>
        case 'action-button':
            return <ActionButton key={key ? key : JSON.stringify(meta)} meta={meta} data={data}/>
        case 'calendar-layout':
            return <CalendarComponent key={key ? key : JSON.stringify(meta)} meta={meta} data={data}/>
        case 'form-button':
            return <FormButton key={key ? key : JSON.stringify(meta)} meta={meta} data={data}/>
        case 'form-layout':
            return <FormLayout key={key ? key : JSON.stringify(meta)} meta={meta} data={data}/>
        case 'list-layout':
            return <ListLayout key={key ? key : JSON.stringify(meta)} meta={meta} data={data}/>
        default:
            return `Layout type '${meta.type}' not implemented`
    }
}
