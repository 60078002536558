import Button from '@mui/material/Button';
import FormControl from '@mui/material/FormControl';
import Grid from '@mui/material/Grid';
import PropTypes from 'prop-types';
import React, {useState} from 'react';
import Typography from '@mui/material/Typography';
import clsx from 'clsx';
import {withStyles} from 'tss-react/mui';
import cloneDeep from 'lodash.clonedeep';
import _ from "lodash";

export const defaultFilterStyles = (theme: any) => ({
    root           : {
        backgroundColor: theme.palette.background.default,
        padding        : '24px 24px 36px 24px',
        fontFamily     : 'Roboto',
    },
    header         : {
        flex          : '0 0 auto',
        marginBottom  : '16px',
        width         : '100%',
        display       : 'flex',
        justifyContent: 'space-between',
    },
    title          : {
        display   : 'inline-block',
        marginLeft: '7px',
        color     : theme.palette.text.primary,
        fontSize  : '14px',
        fontWeight: 500,
    },
    noMargin       : {
        marginLeft: '0px',
    },
    reset          : {
        alignSelf: 'left',
    },
    resetLink      : {
        marginLeft: '16px',
        fontSize  : '12px',
        cursor    : 'pointer',
    },
    filtersSelected: {
        alignSelf: 'right',
    },
    /* checkbox */
    checkboxListTitle       : {
        marginLeft  : '7px',
        marginBottom: '8px',
        fontSize    : '14px',
        color       : theme.palette.text.secondary,
        textAlign   : 'left',
        fontWeight  : 500,
    },
    checkboxFormGroup       : {
        marginTop: '8px',
    },
    checkboxFormControl     : {
        margin: '0px',
    },
    checkboxFormControlLabel: {
        fontSize  : '15px',
        marginLeft: '8px',
        color     : theme.palette.text.primary,
    },
    checkboxIcon            : {
        width : '32px',
        height: '32px',
    },
    checkbox                : {},
    checked                 : {},
    gridListTile            : {
        marginTop: '16px',
    },
});

function TableFilter(props: any) {

    const {classes, columns, options, customFooter, filterList, components = {}} = props;
    const confirmFilters                                                         = options.confirmFilters
    const textLabels                                                             = options.textLabels.filter;
    const [state, setState]                                                      = useState({filterList: cloneDeep(props.filterList)})

    function handleFilterChange(value: any, index: number, column: any) {
        filterUpdate(index, value, column.name, column.filterType);

        if (confirmFilters !== true) {
            props.onFilterUpdate(index, value, column.name, column.filterType);
        }
    }

    function filterUpdate(index: number, value: any, column: any, type: any, customUpdate: any = null) {
        let newFilterList = state.filterList.slice(0);

        updateFilterByType(newFilterList, index, value, type, customUpdate);
        setState({
            filterList: newFilterList,
        });
    }

    function updateFilterByType(filterList: any[], index: number, value: any, type: any, customUpdate: any) {
        const filterPos = filterList[index].findIndex((filter: any) => _.isEqual(filter, value));

        if (customUpdate) {
            filterList = customUpdate(filterList, filterPos, index);
        } else {
            filterList[index] = value;
        }
    }

    function resetFilters() {
        setState({filterList: props.columns.map(() => []),});
        if (confirmFilters !== true) {
            props.onFilterReset();
        }
    }

    function renderColumnFilter(column: any, index: number) {
        const {classes, filterData, options} = props;
        const {filterList}                   = state;
        const width                          = (column.filterOptions && column.filterOptions.fullWidth) === true ? 12 : 6;
        const display                        = (column.filterOptions && column.filterOptions.display) || (options.filterOptions && options.filterOptions.display);

        if (!display) {
            console.error('Property "display" is required when using custom filter type.');
            return;
        }

        if (column.filterListOptions && column.filterListOptions.renderValue) {
            console.warn('"renderValue" is ignored for custom filter fields');
        }

        return (
            <Grid
                item
                key={index}
                xs={width}
                classes={{'grid-xs-12': classes.gridListTile, 'grid-xs-6': classes.gridListTile}}>
                <FormControl key={index}>
                    {display(filterList, handleFilterChange, index, column, filterData, props.toolFilters)}
                </FormControl>
            </Grid>
        );
    }

    function applyFilters() {
        state.filterList.forEach((filter: any, index: number) => {
            props.onFilterUpdate(index, filter, props.columns[index], 'custom');
        });

        props.handleClose(); // close filter dialog popover

        if (props.options.onFilterConfirm) {
            props.options.onFilterConfirm(state.filterList);
        }

        return state.filterList;
    }


    return <div className={classes.root}>
        <div className={classes.header}>
            <div className={classes.reset}>
                <Typography variant="body2" className={clsx({[classes.title]: true})}>
                    {textLabels.title}
                </Typography>
                <Button color="primary"
                        className={classes.resetLink}
                        tabIndex={0}
                        aria-label={textLabels.reset}
                        data-testid={'filterReset-button'}
                        onClick={resetFilters}>
                    {textLabels.reset}
                </Button>
            </div>
            <div className={classes.filtersSelected}/>
        </div>
        <Grid container direction="row" justifyContent="flex-start" alignItems="center" spacing={4}>
            {columns.map((column: any, index: number) => column.filter ? renderColumnFilter(column, index) : null)}
        </Grid>
        {customFooter ? customFooter(filterList, applyFilters) : ''}
    </div>;
}

TableFilter.propTypes = {
    /** Data used to populate filter dropdown/checkbox */
    filterData: PropTypes.array.isRequired,
    /** Data selected to be filtered against dropdown/checkbox */
    filterList: PropTypes.array.isRequired,
    /** Current tool filters */
    toolFilters: PropTypes.array,
    /** Options used to describe table */
    options: PropTypes.object.isRequired,
    /** Callback to trigger filter update */
    onFilterUpdate: PropTypes.func,
    /** Callback to trigger filter reset */
    onFilterReset: PropTypes.func,
    /** Extend the style applied to components */
    classes: PropTypes.object,
};

export default withStyles(TableFilter, defaultFilterStyles, {name: 'MUIDataTableFilter'});
