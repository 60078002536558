import {Grid, Typography} from "@mui/material";
import React from "react";
import {styled} from "@mui/styles";
import {useFormApi} from "@data-driven-forms/react-form-renderer";

const PREFIX = 'SubForm';

const classes = {
    grid: `${PREFIX}-grid`,
};

const StyledGrid = styled(Grid)(() => ({
    [`&.${classes.grid}`]: {
        paddingRight: 0,
        paddingLeft : 0,
    },
}));

export default function SubFormCustom({
                                          fields,
                                          title,
                                          description,
                                          component,
                                          TitleGridProps,
                                          TitleProps,
                                          DescriptionProps,
                                          DescriptionGridProps,
                                          ItemsGridProps,
                                          ...rest
                                      }: any) {
    const {renderForm} = useFormApi();
    return (
        <StyledGrid item xs={12} container className={classes.grid} {...rest}>
            {title && (
                <Grid item xs={12} {...TitleGridProps}>
                    <Typography variant="h5" {...TitleProps}>
                        {title}
                    </Typography>
                </Grid>
            )}
            {description && (
                <Grid item xs={12} {...DescriptionGridProps}>
                    <Typography paragraph {...DescriptionProps}>
                        {description}
                    </Typography>
                </Grid>
            )}
            <Grid item xs={12} container rowSpacing={2} {...ItemsGridProps}>
                {renderForm(fields)}
            </Grid>
        </StyledGrid>
    );
};
