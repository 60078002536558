import ls from "local-storage";
import moment from "moment";
import {Customer, StackState} from "../classes";

export function clearCacheAndReload() {
    // @ts-ignore
    clearCache()
    // @ts-ignore
    window.location.reload(true);
}

export function clearCache() {
    // not clearing currentCustomer
    // @ts-ignore
    ls.remove("nav")
    // @ts-ignore
    ls.remove("dbStatus")
    // @ts-ignore
    ls.remove("forms")
    // @ts-ignore
    ls.remove("descriptions")
    // @ts-ignore
    ls.remove("drilldowns")
    // @ts-ignore
    ls.remove("customers")
    // @ts-ignore
    ls.remove("toolStackStates")
    // @ts-ignore
    ls.remove("toolMetadata")
}

export function setLocalStorageItem(k: string, v: any, lifespanSeconds: number) {
    // @ts-ignore
    ls.set(k, {"exp": moment().add(lifespanSeconds, "s").toISOString(), "data": v})
}

function getLocalStorageItem(k: string) {
    // @ts-ignore
    let item        = ls.get(k)
    let currentTime = moment()
    return item && item.exp > currentTime.toISOString() ? item.data : null;
}


export const setToolMetadata = (toolView: string, metadata: any) => {
    let toolMetadata       = getLocalStorageItem("toolMetadata") || {}
    toolMetadata[toolView] = metadata
    setLocalStorageItem("toolMetadata", toolMetadata, 86400)
};
export const getToolMetadata = (toolView: string) => {
    let toolMetadata = getLocalStorageItem("toolMetadata")
    return toolMetadata ? toolMetadata[toolView] : null
};

export const getCurrentCustomer    = (): Customer => getLocalStorageItem("currentCustomer");
export const getCustomers          = (): Customer[] => getLocalStorageItem("customers");
export const getDbStatus           = (): any => getLocalStorageItem("dbStatus") || {};
export const getDescriptions       = (): any => getLocalStorageItem("descriptions");
export const getDrilldowns         = (): any => getLocalStorageItem("drilldowns");
export const getForms              = (): any => getLocalStorageItem("forms");
export const getNavigation         = (): any => getLocalStorageItem("nav");
export const getToolStackStates    = (): StackState[] => getLocalStorageItem("toolStackStates");
export const getNewToolStackStates = (): StackState[] => getLocalStorageItem("newToolStackStates");
