import * as React from 'react';
import {useContext} from 'react';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import Box from '@mui/material/Box';
import PropTypes from "prop-types";
import {Tool, ToolTab} from "../../classes";
import ToolGridComponent from "../grid/ToolGridComponent";
import BaseToolComponent from "../BaseToolComponent";
import getColumnLabelString from "../../util/TextUtils";
import {MyDataContext} from "../../ToolsComponent";
import {
    lightGray,
    tabBgColor,
    tabHoverBgColor,
    tabHoverTxColor,
    tabIndicatorColor,
    tabSelectedBgColor,
    tabSelectedHoverBgColor,
    tabSelectedHoverTxColor,
    tabSelectedTxColor,
    tabTxColor,
    whiteColor
} from "../../styles/getMuiTheme";

interface TabPanelProps {
    children?: React.ReactNode;
    index: number;
    value: number;
}

function TabPanel(props: TabPanelProps) {
    const {children, value, index, ...other} = props;

    return (
        <div
            role="tabpanel"
            hidden={value !== index}
            id={`simple-tabpanel-${index}`}
            aria-labelledby={`simple-tab-${index}`}
            {...other}
        >
            {value === index && <div>{children}</div>}
        </div>
    );
}

function a11yProps(index: number) {
    return {
        'aria-controls': `simple-tabpanel-${index}`,
    };
}

const styles = {
    '& .MuiTab-root'                               : {
        backgroundColor: tabBgColor.hexa(),
        color          : tabTxColor.hexa(),
        textTransform  : "none",
        whiteSpace     : "pre",
        maxWidth       : "none",
        minHeight      : "42px",
        // boxShadow      : "inset 0 0 5px gray",
        paddingTop   : "5px !important",
        paddingBottom: "5px !important",
        margin       : "6px 0 0 10px",
        // borderRight    : "1px solid " + lightGray.hex(),
        // borderTop      : "1px solid " + lightGray.hex(),
        // borderLeft     : "1px solid " + lightGray.hex(),
        boxShadow       : "0 0 4px 2px" + lightGray.hex(),
        borderRadius    : "6px 6px 0 0",
        '&.Mui-selected': {
            color          : tabSelectedTxColor.hexa(),
            backgroundColor: tabSelectedBgColor.hexa(),
            '&:hover'      : {
                color          : tabSelectedHoverTxColor.hexa(),
                backgroundColor: tabSelectedHoverBgColor.hexa(),
            }
        },
        '&:hover'       : {
            color          : tabHoverTxColor.hexa(),
            backgroundColor: tabHoverBgColor.hexa(),
        },
    },
    '& .MuiTabs-flexContainer'                     : {
        backgroundColor: whiteColor.hexa(),
    },
    '& .MuiTabs-indicator'                         : {
        backgroundColor: tabIndicatorColor.hexa(),
    },
    '& .MuiTabs-scroller'                          : {
        paddingRight: "10px",
    },
    '& .MuiTabScrollButton-root'                   : {
        '&$disabled': {
            opacity: 0.2,
        },
    },
    '& .MuiTabScrollButton-horizontal.Mui-disabled': {
        width             : 0,
        transitionProperty: "width",
        transitionDuration: ".1s"
    }
}

export default function BasicTabs(props: any) {

    const [state] = useContext(MyDataContext);

    const handleChange = (event: React.SyntheticEvent, newValue: number) => {
        state.handleTabSelect(props.groupOrTab, newValue)
    };

    function getTabHeight() {
        let tabHeight: number

        const values = props.groupOrTab?.tabs
            .map((t: ToolTab) => t.name)
            .map((n: string) => getColumnLabelString(n, 15))
            .map((s: string) => {
                const newVar = s.match(/\n/g) || [];
                return newVar.length;
            });

        let maxNewlinesInTabs = values.reduce((a: number, b: number) => Math.max(a, b), -Infinity);

        switch (maxNewlinesInTabs) {
            case 2:
                tabHeight = 66
                break;
            case 1:
                tabHeight = 50
                break;
            default:
                tabHeight = 50
                break;
        }

        if (props.existingTabPadding) {
            tabHeight = tabHeight + props.existingTabPadding
            // tabHeight = tabHeight - 17
        }
        return tabHeight;
    }

    const tabHeight = getTabHeight();

    return <Box>
        <Box sx={{
            borderBottom: 1,
            borderColor : 'divider',
            maxWidth    : "calc(100vw - " + state.sideBarWidth + "px) !important"
        }}>
            <Tabs value={props.groupOrTab.selectedTabIndex || 0}
                  sx={styles}
                  variant={"scrollable"}
                  scrollButtons={"auto"}
                  onChange={handleChange}>
                {props.groupOrTab.tabs?.map((t: ToolTab, index: number) => {
                    const label = getColumnLabelString(t.name, 15)
                    return <Tab key={t.uuid}
                                sx={styles}
                                id={t.name.replaceAll(" ", "_") + "_tab"}
                                label={label} {...a11yProps(index)}/>;
                })}
            </Tabs>
        </Box>
        {props.groupOrTab.tabs?.map((t: any, index: number) => {
            return <TabPanel key={t.uuid} value={props.groupOrTab.selectedTabIndex || 0} index={index}>
                {
                    t.parsedTools?.length == 1 && t.parsedTools[0].toolType === 'PDF' ? t.parsedTools?.map((tool: Tool) =>
                            <BaseToolComponent key={tool.uuid}
                                               tool={tool}/>) :
                        <ToolGridComponent toolGroupOrToolTab={t} existingTabPadding={tabHeight}/>
                }
            </TabPanel>;
        })}
    </Box>;
}

BasicTabs.propTypes = {
    groupOrTab        : PropTypes.object,
    existingTabPadding: PropTypes.number
}