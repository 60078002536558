import {Build, ErrorOutline} from "@mui/icons-material";
import React from "react";
import LoadingCircle from "./loadingCircle";
import {IconButton, Tooltip} from "@mui/material";
import {errorColor} from "../../styles/getMuiTheme";
import {RefreshCw} from "react-feather";

export default function RefreshCircle(props) {

    return <Tooltip title={props.label} disableFocusListener>
        <IconButton onClick={props.onClick} aria-label={props.label}>
            {props.active ? <LoadingCircle active={props.active} size={24}/> : !props.error ? props.build ?
                <Build/> :
                <RefreshCw/> :
                <ErrorOutline style={{color: errorColor.hexa()}}/>}
        </IconButton>
    </Tooltip>;
}