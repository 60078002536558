import React, {useContext} from "react";
import PropTypes from "prop-types";
import Button from "@mui/material/Button";
import {FreeformToolContext, FreeformToolDispatchContext} from "../../JsonTool";
import {MyDataContext} from "../../ToolsComponent";

export default function FormButton(props: { meta: any, data: any }) {
    const dispatch                                    = useContext(FreeformToolDispatchContext);
    const [state]                                     = useContext(MyDataContext);
    const [data, datasources, detailID, idColumnName] = useContext(FreeformToolContext);

    return <Button color={"primary"}
                   style={{margin: "5px"}}
                   type="button"
                   key={JSON.stringify(props.meta)}
                   variant={"contained"}
                   onClick={() => state.handleFormButtonClick(props.meta.view, detailID, idColumnName)}>{props.meta.label}</Button>

}

FormButton.propTypes = {
    meta: PropTypes.any.isRequired,
    data: PropTypes.any
}