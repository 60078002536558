import React from "react";
import BooleanColumn from "./components/datatable/columns/BooleanColumn";
import PercentageColumn from "./components/datatable/columns/PercentageColumn";
import NumberColumn from "./components/datatable/columns/NumberColumn";
import DateColumn from "./components/datatable/columns/DateColumn";
import TimestampColumn from "./components/datatable/columns/TimestampColumn";
import MonthColumn from "./components/datatable/columns/MonthColumn";
import MoneyColumn from "./components/datatable/columns/MoneyColumn";
import JsonColumn from "./components/datatable/columns/JsonColumn";
import YearColumn from "./components/datatable/columns/YearColumn";
import TextColumn from "./components/datatable/columns/TextColumn";
import LimitedTextColumn from "./components/datatable/columns/LimitedTextColumn";
import ColumnComponent from "./components/datatable/columns/ColumnComponent";
import {MuiTableColumn, ServerColumn, Tool} from "./classes";
import ColorColumn from "./components/datatable/columns/ColorColumn";
import getColumnLabelString from "./util/TextUtils";
import {DESCRIPTIONS} from "./ToolsComponent";
import {styled} from "@mui/styles";
import {Tooltip, tooltipClasses, TooltipProps} from "@mui/material";
import {toolTipColor} from "./styles/getMuiTheme";

export const StyledToolTip = styled(({className, ...props}: TooltipProps) => (
    <Tooltip {...props} arrow placement="bottom" classes={{popper: className}}/>
))(({theme}) => ({
    [`& .${tooltipClasses.arrow}`]  : {
        color: toolTipColor.hexa(),
    },
    [`& .${tooltipClasses.tooltip}`]: {
        backgroundColor: toolTipColor.hexa(),
        fontSize       : "14px",
        maxWidth       : "250px"
    },
}));

const columnTypes = [
    'PERCENTAGE',
    'NUMBER',
    'DATE',
    'TIMESTAMP',
    'MONTH',
    'BOOLEAN',
    'MONEY',
    'LIMITED_TEXT',
    'HIDDEN',
    'JSON',
    "YEAR",
    "COLOR"
]

function getColumnSelectOptions(columnName: string, columns: [{
    name: string,
    order: number,
    type: string
}]) {

    let typeFromView = columns.find((c: {
        name: string,
        order: number,
        type: string
    }) => c.name.startsWith(columnName + "|TYPE"))

    let selectOptions = []

    if (typeFromView) {
        let columnDefStrs = typeFromView.name.split("(")[0].split("|")
        let foundOptions  = columnDefStrs.find((s: any) => s.startsWith("VALUES="))
        if (foundOptions) {
            selectOptions = JSON.parse(foundOptions.split("=")[1])
        }
    }
    return selectOptions
}

export function getColumnTypeDefinition(columnName: string, columns: any) {
    let typeFromView = columns.find((c: {
        name: string,
        order: number,
        type: string
    }) => c.name.startsWith(columnName + "|TYPE"))


    if (typeFromView) {
        let foundType = typeFromView.name.split("|").find((s: string) => s.startsWith("TYPE=")).split("(")[0].split("=")[1]
        if (foundType) {
            if (columnTypes.includes(foundType)) {
                return foundType
            }
            if (foundType.includes(",")) {
                return foundType
            }
        }
    }
    return null
}

function getDbColumnType(colString: string, columnName: string) {
    switch (colString) {
        case 'varchar':
            return 'TEXT'
        case 'char':
            return 'TEXT'
        case 'decimal':
            if (columnName.toLowerCase().includes(" revenue") ||
                columnName.toLowerCase().includes(" expense") ||
                columnName.toLowerCase().includes(" amount") ||
                columnName.toLowerCase().includes(" amt")
            ) {
                return 'MONEY'
            }
            if (columnName.toLowerCase().includes("percent")) {
                return 'PERCENTAGE'
            }
            return 'NUMBER'
        case 'int':
            if (columnName.toLowerCase().includes("count")) {
                return 'NUMBER'
            } else if (columnName.toLowerCase().endsWith("year")) {
                return 'YEAR'
            } else if (columnName.toLowerCase().endsWith("month")) {
                return 'MONTH'
            }
            return 'NUMBER'
        case 'bigint':
            return 'NUMBER'
        case 'date':
            return 'DATE'
        case 'datetime':
            return 'TIMESTAMP'
        case 'datetime2':
            return 'TIMESTAMP'
        case 'tinyint':
            return 'BOOLEAN'
        case 'bit':
            return 'BOOLEAN'
        default:
            return 'TEXT'
    }
}

function getColumnType(columnName: string, dbColumnType: string, columns: any = [], includeHiddenTypes: boolean = false) {

    let colStrings = dbColumnType.split("(")

    let foundType = getColumnTypeDefinition(columnName, columns)

    if (foundType) {
        if (foundType === 'HIDDEN' && includeHiddenTypes) {
            return getDbColumnType(colStrings[0], columnName) + ",HIDDEN"
        }
        return foundType
    }
    return getDbColumnType(colStrings[0], columnName);

}

function getMuiTableColumns(tool: Tool, tableRowData: any): MuiTableColumn[] {

    let serverColumns     = tool?.columns || [] as ServerColumn[];
    const muiTableColumns = [] as MuiTableColumn[];
    serverColumns.sort((a, b) => a.order - b.order)

    let actualIndex = 0

    for (let i = 0; i < serverColumns.length; i++) {
        if (!skipColumn(serverColumns[i].attr, serverColumns[i].type.name)) {
            muiTableColumns.push(getMuiTableColumn(serverColumns[i], tableRowData, tool, actualIndex, tool.dbName))
            actualIndex++
        }
    }

    muiTableColumns.sort((a, b) => a.actualOrder - b.actualOrder)
    return muiTableColumns;
}

export function skipColumn(colAttr: string, colType: string | null) {
    if (colAttr.toLowerCase().includes('objectid')) {
        return true
    }
    if (colAttr.endsWith("ID") && !colAttr.endsWith(" ID")) {
        return true
    }
    if (colAttr === 'id') {
        return true
    }
    if (colAttr.startsWith("Tool")) {
        return true
    }
    if (colAttr.endsWith("|TOOLTIP")) {
        return true
    }
    if (colAttr.includes("|TYPE=")) {
        return true
    }
    if (['CreatedUser', 'CreatedDateTime', 'LastUpdatedUser', 'LastUpdatedDateTime'].includes(colAttr)) {
        return true
    }
    if (colType && colType.includes('HIDDEN')) {
        return true
    }

    return colType === 'HIDDEN';


}

function getMuiTableColumn(serverColumn: ServerColumn,
                           tableRowData: any,
                           tool: Tool,
                           columnIndex: number,
                           tableName: string): MuiTableColumn {

    const descriptionDef   = DESCRIPTIONS[tool.dbName];
    let columnDescriptions = null
    let description        = null
    if (descriptionDef) {
        columnDescriptions = descriptionDef.columns
    }

    if (columnDescriptions) {
        description = columnDescriptions[serverColumn.title]
    }

    return {
        label      : getColumnLabelString(serverColumn.title),
        name       : serverColumn.attr,
        visible    : serverColumn.visible,
        order      : columnIndex,
        actualOrder: columnIndex,
        hint       : description ? description.replaceAll(/\n/g, '  \n') : "",
        options: getMuiTableColumnOptions(tableRowData, serverColumn, tool, columnIndex, tableName),
        testId : tool.title?.replaceAll(" ", "_") + "_" + serverColumn.attr.replaceAll(" ", "_") + "_column"
    }
}

function getMuiTableColumnOptions(tableRowData: any, serverColumn: ServerColumn, tool: Tool, columnIndex: number, tableName: string) {

    const columnComponent = getColumnComponent(serverColumn)

    return {
        customBodyRender       : null,
        customBodyRenderLite   : getCustomBodyRenderLite(tableRowData, columnComponent, serverColumn),
        customFilterListOptions: columnComponent.getCustomFilterListOptions(serverColumn),
        customFilterListRender : null,
        customHeadLabelRender  : null,
        customHeadRender       : null,
        display                : serverColumn.visible,
        download               : true,
        empty                  : false,
        filter                 : true,
        filterList             : tool.filterList ? tool.filterList[columnIndex] : [],
        filterOptions          : columnComponent.getFilterOptions(tool.uuid, tableName ? tableName : tool.key, serverColumn),
        filterType             : columnComponent.getFilterType(serverColumn),
        print                  : true,
        searchable             : true,
        selectOptions          : serverColumn.selectOptions,
        setCellHeaderProps     : null,
        setCellProps           : null,
        sort                   : true,
        sortCompare            : columnComponent.getSortCompare(serverColumn),
        sortDescFirst          : false,
        sortThirdClickReset    : true,
        viewColumns            : true,
    }
}

function getColumnComponentByType(type: string): ColumnComponent {
    switch (type.trim()) {
        case 'PERCENTAGE':
            return new PercentageColumn();
        case 'NUMBER':
            return new NumberColumn();
        case 'DATE':
            return new DateColumn();
        case 'TIMESTAMP':
            return new TimestampColumn();
        case 'MONTH':
            return new MonthColumn();
        case 'BOOLEAN':
            return new BooleanColumn();
        case 'MONEY':
            return new MoneyColumn();
        case 'LIMITED_TEXT':
            return new LimitedTextColumn();
        case 'JSON':
            return new JsonColumn();
        case "YEAR":
            return new YearColumn();
        case "COLOR":
            return new ColorColumn();
        default:
            return new TextColumn();
    }
}

function getColumnComponent(serverColumn: ServerColumn): ColumnComponent {
    return getColumnComponentByType(serverColumn.type.name)
}

function getCustomBodyRenderLite(tableRowData: any, columnComponent: ColumnComponent, serverColumn: ServerColumn) {
    return (dataIndex: number) => {
        const datum = tableRowData[dataIndex];

        if (!datum) {
            return null;
        }

        let columnValue = datum[serverColumn.attr];

        let attr = serverColumn.attr.split("|")[0]

        const valueDescription = datum[`${attr}|TOOLTIP`];

        let value = columnComponent.getDisplayValue(columnValue);
        const columnType = serverColumn.type.name.trim();

        if (['NUMBER', 'PERCENTAGE'].includes(columnType)) {
            value = <div style={{textAlign: "right"}}>{value}</div>;
        }

        if ('MONEY' === columnType) {
            value = <div style={{textAlign: "right"}}>{value}</div>;
        }

        if (valueDescription) {
            return <StyledToolTip title={valueDescription}>
                <div style={{textDecoration: "underline"}}>{value}</div>
            </StyledToolTip>
        }

        return value
    };
}

function mapCsvData(serverColumns: ServerColumn[], muiTableColumns: MuiTableColumn[], rows: any) {

    for (let rowIndex = 0; rowIndex < rows.length; rowIndex++) {
        for (let dataIndex = 0; dataIndex < rows[rowIndex].data.length; dataIndex++) {
            const muiTableColumn           = muiTableColumns[dataIndex]
            const serverColumn             = serverColumns.find(t => t.attr === muiTableColumn.name);
            const columnComponent          = getColumnComponent(serverColumn!)
            rows[rowIndex].data[dataIndex] = columnComponent.getDisplayValue(rows[rowIndex].data[dataIndex], true);
        }
    }

    return rows;
}

function getServerColumnsFromToolMetadata(metadata: any, columnStates: any[] = []): ServerColumn[] {
    let toolColumns = [] as ServerColumn[]

    for (let i = 0; i < metadata.columns.length; i++) {
        let c = metadata.columns[i]

        let columnState

        if (columnStates) {
            columnState = columnStates.find((s: any) => s[0] === i)
        }

        toolColumns.push(
            {
                title        : c.name.split("|")[0],
                attr         : c.name,
                order        : columnState ? columnState[0] : i,
                type         : {name: getColumnType(c.name, c.type, metadata.columns)},
                actualOrder  : columnState ? columnState[1] : i,
                description  : null,
                options      : [],
                selectOptions: getColumnSelectOptions(c.name, metadata.columns),
                visible: columnState ? columnState[2] === 1 : true,
                default: null
            }
        )
    }
    return toolColumns;
}


function getDataSourceColumnNamesInOrder(datasource: any) {
    return datasource?.metadata?.columns?.filter((colDef: {
        name: string,
        source: string,
        metadata: any
    }) => {
        const mdTableColumn = datasource?.metadata?.columns?.find((c: any) => c.name.split("|")[0] === colDef.name);
        const columnType    = getColumnType(colDef.name, mdTableColumn?.type || "", datasource.metadata.columns);
        return !skipColumn(colDef.name, columnType);
    }).map((c: any) => c.name)

}

export {
    getMuiTableColumns,
    getMuiTableColumn,
    mapCsvData,
    getColumnComponent,
    getColumnComponentByType,
    getColumnSelectOptions,
    getColumnType,
    getServerColumnsFromToolMetadata,
    getDataSourceColumnNamesInOrder
}
