import React, {useState} from 'react'
import {Button, Dialog, DialogActions, DialogContent} from '@material-ui/core'
import {createPromise} from "./createPromise";


const useConfirm = () => {
    const [open, setOpen] = useState(false);
    const [resolver, setResolver] = useState({resolver: null})
    const [label, setLabel] = useState('')

    const getConfirmation = async (text) => {
        setLabel(text);
        setOpen(true);
        const [promise, resolve] = await createPromise()
        setResolver({resolve})
        return promise;
    }

    const onClick = async (status) => {
        setOpen(false);
        resolver.resolve(status)
    }

    const Confirmation = () => {
        return <Dialog open={open}>
            <DialogContent>
                <div style={{whiteSpace: "pre"}}>{label}</div>
            </DialogContent>
            <DialogActions>
                <Button onClick={() => onClick(false)}> Cancel </Button>
                <Button onClick={() => onClick(true)}> OK </Button>
            </DialogActions>
        </Dialog>;
    }

    return [getConfirmation, Confirmation]

}

export default useConfirm;
