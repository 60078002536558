import {Theme} from "@mui/material";
import React from "react";
import PropTypes from "prop-types";
import {makeStyles} from "@mui/styles";
import {mapLayout} from "./FreeformUtils";

export default function DivLayout(props: { meta: any, data: any }) {
    const useStyles = makeStyles(
        // @ts-ignore
        (theme: Theme) => {
            return {
                cssCzz5zq: {
                    padding        : '32px',
                    backgroundColor: 'hotpink',
                    fontSize       : '24px',
                    borderRadius   : '4px'
                }
            }
        }
    )

    const classes = useStyles() as any

    return <div key={JSON.stringify(props.meta)} className={classes[props.meta.className]}>
        {props.meta.fields?.map((f: any) => mapLayout(f, props.data ? props.data : null))}
    </div>
}

DivLayout.propTypes = {
    meta: PropTypes.any.isRequired,
    data: PropTypes.any
}