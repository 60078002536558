import {CircularProgress} from "@mui/material";
import {progressSpinnerColor} from "../styles/getMuiTheme";
import Mermaid from "../Mermaid";
import ToolGridComponent from "./grid/ToolGridComponent";
import ChartComponent from "./charts/ChartComponent";
import DrillDownDetail from "./drilldown/DrillDownDetail";
import React, {useContext, useEffect} from "react";
import PropTypes from "prop-types";
import PdfToolComponent from "./pdf/PdfToolComponent";
import {MyDataContext} from "../ToolsComponent";
import JsonTool from "../JsonTool";
import ErrorComponent from "./ErrorComponent";
import DataTableBetaComponent from "./datatable/DataTableBeta";

export default function BaseToolComponent(props) {
    const [state, setState] = useContext(MyDataContext);

    useEffect(() => {

        if (props.tool?.refreshInterval) {
            const interval = setInterval(async () => {
                try {
                    state.handleToolRefresh(props.tool).then(result => console.log(result));
                } catch (e) {
                    console.error(e);
                }
            }, props.tool?.refreshInterval * 1000);
            return () => clearInterval(interval); // This represents the unmount function, in which you need to clear your interval to prevent memory leaks.
        } else {
            return () => null
        }

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [props.tool?.refreshInterval])

    if (!props.tool) {
        return <div style={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            width: '100%',
            height: '100%',
        }}>
            <CircularProgress style={{color: progressSpinnerColor.hexa()}}/>
        </div>;
    } else if (props.tool.error) {
        return <ErrorComponent errorResponse={props.tool.toolData}/>
    } else {
        switch (props.tool.toolType) {
            case "FREEFORM":
                return <JsonTool tool={props.tool}/>;
            case "MERMAID":
                return <Mermaid tool={props.tool}/>;
            case "GROUP":
                return <ToolGridComponent tool={props.tool}/>
            case "CHART":
                if (state.currentFrame?.type === 'TOOL') {
                    return <div style={{position: "relative", height: "calc(100vh - 100px)"}}>
                        <ChartComponent tool={props.tool}
                                        onTypeChange={(e) => state.handleChartTypeChange(props.tool, e)}
                                        fakeControls={[]}/>
                    </div>
                }

                return <ChartComponent tool={props.tool}
                                       onTypeChange={(e) => state.handleChartTypeChange(props.tool, e)}
                                       fakeControls={[]}/>
            case "JSON":

                const jsonData = {...props.tool.toolData}

                if (props.tool.displayTextColumnName && jsonData && jsonData[props.tool.displayTextColumnName]) {
                    delete jsonData[props.tool.displayTextColumnName]
                }

                return <DrillDownDetail object={jsonData} depth={0}/>
            case "PDF":
                return <PdfToolComponent tool={props.tool}/>
            case "URL":
                return <iframe src={props.tool.options.url}
                               width={"100%"}
                               height={"100%"}
                               style={{borderStyle: "none"}}/>
            default:
                return <DataTableBetaComponent tool={props.tool} showTitle={props.showTitle}/>;
        }
    }
}

BaseToolComponent.propTypes = {
    tool: PropTypes.object.isRequired,
    showTitle: PropTypes.bool
}