import PropTypes from "prop-types";
import {Tooltip} from "@mui/material";
import React from "react";
import {blackColor, errorColor, whiteColor} from "../styles/getMuiTheme";
import {CopyAll} from "@mui/icons-material";

export default function ErrorComponent(props: any) {
    const errorObj = props.errorResponse;

    if (!errorObj) {
        return null
    }

    if (!errorObj.stack && !errorObj.config) {
        return <div style={{padding: "10px", color: errorColor.hexa()}}>{errorObj.message}</div>
    }

    return <Tooltip sx={{maxWidth: "100%"}}
                    arrow={true}
                    TransitionProps={{style: {color: blackColor.hexa(), backgroundColor: whiteColor.hexa()}}}
                    title={<div style={{
                        fontSize     : "14px", display: "flex",
                        flexDirection: "column", width: "100%"
                    }}>

                        {errorObj.stack && <fieldset key={'Stacktrace'}>
                            <legend>Stacktrace</legend>
                            <div>
                                {errorObj.stack?.split("\n").map((s: string) => {
                                    return <div>{s}</div>
                                })}
                            </div>
                        </fieldset>}

                        {errorObj.config && <fieldset>
                            <legend>Request</legend>
                            <div style={{display: "flex"}}>
                                <div>Method: <span style={{textTransform: "uppercase"}}>{errorObj.config.method}</span>
                                    <div>URL: {errorObj.config.url}</div>
                                </div>
                            </div>

                            {errorObj.config.params && <fieldset>
                                <legend>Params</legend>
                                <pre>{JSON.stringify(errorObj.config.params, null, 2)}</pre>
                            </fieldset>}

                            {errorObj.config.data && <fieldset>
                                <legend>Body</legend>
                                {/* <pre>{JSON.stringify(JSON.parse(errorObj.config.data), null, 2)}</pre> */}
                                {/*<DrillDownDetail object={JSON.parse(errorObj.config.data)}/>*/}
                            </fieldset>}

                        </fieldset>}

                        {errorObj.response && <fieldset>
                            <legend>Response</legend>
                            <div style={{display: "flex"}}>
                                <div>Method: <span
                                    style={{textTransform: "uppercase"}}>{errorObj.response.config?.method}</span></div>
                                <div>Status: {errorObj.response.status}: {errorObj.response.statusText}</div>
                                <div>URL: {errorObj.response.config?.url}</div>
                            </div>

                            {errorObj.response.data && <fieldset>
                                <legend>Body</legend>
                                <pre>{errorObj.response.data}</pre>
                            </fieldset>}

                        </fieldset>}

                        <CopyAll onClick={() => {
                            navigator.clipboard.writeText(JSON.stringify(props.errorResponse, null, 2))
                        }}/>

                        Copy error JSON

                    </div>}>

        <div style={{padding: "10px", color: errorColor.hexa()}}>
            {JSON.stringify(errorObj.message, null, 2)} (hover for details)
        </div>

    </Tooltip>
}

ErrorComponent.propTypes = {
    errorResponse: PropTypes.object.isRequired
}