import PropTypes from 'prop-types'
import {Button, Checkbox, FormControl, Grid, InputLabel, ListItemText, MenuItem, Select} from "@mui/material";
import React, {useEffect, useState} from "react";
import {convertToMonthName, convertToMonthNames, getMonthNames} from "../../../util/DateUtil";
import {Filter} from "../../../classes";


export default function MonthFilter(props: any) {

    const renderValue                 = (values: number[]) => values.length > 0 ? convertToMonthNames(values).join(", ") : "--";
    const [selectOptions]             = useState(getMonthNames()) as any[];
    const [showSelect, setShowSelect] = React.useState(false);

    const [value, setValue] = useState(selectOptions ? props.filterValue.filter((val: any) => selectOptions.includes(val)) : props.filterValue)

    const multiselect = props.multiselect !== null && props.multiselect !== undefined ? props.multiselect : true

    const displayAsList = props.displayAsList !== null && props.displayAsList !== undefined ? props.displayAsList : false

    useEffect(() => {
        const newVar = selectOptions ? props.filterValue.filter((val: any) => selectOptions.includes(convertToMonthName(val))) : props.filterValue;
        setValue(newVar)
    }, [props.filterValue]);

    function handleSelection(e: any) {
        const val = e

        let filter
        if (value.includes(val)) {
            filter = [...value].filter((v: any) => v !== val);
        } else {
            filter = [...value, val];
        }
        setValue(filter)
        props.onChange({target: {value: filter}})

    }

    return <Grid item key={props.testId}>
        <FormControl key={props.testId} style={{margin: "22px 0 5px 0"}}>
            <InputLabel htmlFor={props.columnName}>{props.label}</InputLabel>
            {displayAsList ? <div>

                {
                    selectOptions.map((v: any) => {
                        const option = v === null ? "(blank)" : v
                        return <MenuItem value={selectOptions.indexOf(option) + 1}
                                         key={option}
                                         onClick={() => handleSelection(selectOptions.indexOf(option) + 1)}
                                         id={props.testId + "_option_" + option}>
                            {multiselect && <Checkbox color="primary"
                                                      checked={props.filterValue?.indexOf(selectOptions.indexOf(option) + 1) > -1}/>}
                            <ListItemText primary={option}/>
                        </MenuItem>;
                    })
                }


            </div> : <Select
                id={props.testId + "_filter"}
                open={showSelect}
                multiple={multiselect}
                displayEmpty={true}
                value={value}
                renderValue={renderValue}
                name={props.columnName}
                onOpen={() => setShowSelect(true)}
                onClose={() => setShowSelect(false)}
                onChange={props.onChange}>
                {
                    selectOptions.map((option: any) =>
                        <MenuItem value={selectOptions.indexOf(option) + 1}
                                  key={option}
                                  id={props.testId + "_option_" + option}>
                            {multiselect && <Checkbox color='primary'
                                                      checked={props.filterValue?.indexOf(selectOptions.indexOf(option) + 1) > -1}
                            />}
                            <ListItemText primary={option}/>
                        </MenuItem>)
                }
                {multiselect &&
                    <Button id={props.columnName.replaceAll(" ", "_") + "_filter_close_button"} variant={'text'}
                            onClick={() => setShowSelect(false)}>
                        Close
                    </Button>}
            </Select>}
        </FormControl>
    </Grid>
}

MonthFilter.propTypes = {
    filterValue    : PropTypes.array,
    multiselect    : PropTypes.bool,
    columnName     : PropTypes.string.isRequired,
    testId         : PropTypes.string.isRequired,
    label          : PropTypes.string.isRequired,
    tableName      : PropTypes.string.isRequired,
    onChange       : PropTypes.func.isRequired,
    existingFilters: PropTypes.arrayOf(PropTypes.instanceOf(Filter)),
    displayAsList  : PropTypes.bool
}