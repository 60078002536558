import Button from '@mui/material/Button';
import clsx from 'clsx';
import HelpIcon from '@mui/icons-material/Help';
import MuiTooltip from '@mui/material/Tooltip';
import PropTypes from 'prop-types';
import React, {useState} from 'react';
import TableCell from '@mui/material/TableCell';
import TableSortLabel from '@mui/material/TableSortLabel';
import useColumnDrop from '../hooks/useColumnDrop.js';
import {makeStyles} from 'tss-react/mui';
import {useDrag} from 'react-dnd';
import {FilterList, InfoOutlined} from "@mui/icons-material";
import ReactMarkdown from "react-markdown";
import gfm from "remark-gfm";
import {IconToolTip} from "../../../login/IconToolTip";
import Popover from "./Popover";
import IconButton from "@mui/material/IconButton";
import {tableHeaderBGColor, tableHeaderBorderColor, themeColor1} from "../../../../styles/getMuiTheme";

export const useStyles = makeStyles({name: 'MUIDataTableHeadCell'})(theme => ({
    root: {},
    fixedHeader: {
        position: 'sticky',
        top: '0',
        zIndex: 100,
        backgroundColor: tableHeaderBGColor.hexa(),
        borderRight: "1px solid " + tableHeaderBorderColor.hexa(),
        borderTop: "1px solid " + tableHeaderBorderColor.hexa(),
        borderBottom: "1px solid " + tableHeaderBorderColor.hexa(),
        padding: "0",
        width: "1px",
        "&:last-child": {
            width: "100% !important",
            backgroundColor: tableHeaderBGColor.hexa(),
        }

    },
    tooltip: {
        cursor: 'pointer',
    },
    filterPaper: {
        maxWidth: '50%',
        minWidth: '200px'
    },
    filterCloseIcon: {
        position: 'absolute',
        right: 0,
        top: 0,
        zIndex: 100,
    },
    mypopper: {
        '&[data-x-out-of-boundaries]': {
            display: 'none',
        },
    },
    data: {
        display: 'inline-block',
    },
    sortAction: {
        display: 'flex',
        cursor: 'pointer',
    },
    dragCursor: {
        cursor: 'grab',
    },
    sortLabelRoot: {
        height: '20px',
    },
    sortActive: {
        color: theme.palette.text.primary,
    },
    toolButton: {
        borderRadius: "0",
        width: "100%",
        height: "100%",
        justifyContent: "left",
        textTransform: 'none',
        margin: 0,
        '&:hover': {
            backgroundColor: themeColor1.fade(0.9).hexa(),
            color: themeColor1.hexa()
        },
        minWidth: 0,
        paddingLeft: '0',
        paddingRight: '8px',
    },
    filterIcon: {
        borderRadius: "0",
        '&:hover': {
            backgroundColor: themeColor1.fade(0.9).hexa(),
            color: themeColor1.hexa()
        },
    },
    contentWrapper: {
        display: 'flex',
        alignItems: 'center',
        '&:hover': {
            backgroundColor: themeColor1.fade(0.9).hexa(),
            color: themeColor1.hexa()
        },
    },
    contentWrapperHover: {
        display: 'flex',
        alignItems: 'center',
        backgroundColor: themeColor1.fade(0.9).hexa(),
        color: themeColor1.hexa()
    },
    hintIconAlone: {
        marginTop: '-3px',
        marginLeft: '3px',
    },
    hintIconWithSortIcon: {
        marginTop: '-3px',
    },
}));

const TableHeadCell = ({
                           cellHeaderProps = {},
                           children,
                           colPosition,
                           column,
                           columns,
                           columnOrder = [],
                           components = {},
                           draggableHeadCellRefs,
                           draggingHook,
                           applyFilters,
                           filterList,
                           hint,
                           index,
                           resetFilters,
                           options,
                           onFilterChange,
                           print,
                           setCellRef,
                           sort,
                           sortDirection,
                           tableRef,
                           tableId,
                           tableName,
                           timers,
                           toggleSort,
                           updateColumnOrder,
                       }) => {


    const [sortTooltipOpen, setSortTooltipOpen] = useState(false);
    const [filterTooltipOpen, setFilterTooltipOpen] = useState(false);
    const [hideFilterPopover, setHideFilterPopover] = useState(true);
    const [hintTooltipOpen, setHintTooltipOpen] = useState(false);
    const {classes} = useStyles();
    const [dragging, setDragging] = draggingHook ? draggingHook : [];
    const {className, ...otherProps} = cellHeaderProps;
    const Tooltip = components.Tooltip || MuiTooltip;
    const sortActive = sortDirection !== 'none' && sortDirection !== undefined;
    const ariaSortDirection = sortDirection === 'none' ? false : sortDirection;

    const handleSortClick = () => toggleSort(index);

    const handleKeyboardSortInput = e => {
        if (e.key === 'Enter') {
            handleSortClick();
        } else {
            return false;
        }
    };

    const isDraggingEnabled = () => !draggingHook ? false : options.draggableColumns && options.draggableColumns.enabled && column.draggable !== false;

    const sortLabelProps = {
        classes: {root: classes.sortLabelRoot},
        tabIndex: -1,
        active: sortActive,
        hideSortIcon: true,
        ...(ariaSortDirection ? {direction: sortDirection} : {}),
    };

    const [{opacity}, dragRef, preview] = useDrag({
        item: {
            type: 'HEADER',
            colIndex: index,
            headCellRefs: draggableHeadCellRefs,
        },
        begin: monitor => {
            setTimeout(() => {
                setHintTooltipOpen(false);
                setSortTooltipOpen(false);
                setDragging(true);
            }, 0);
            return null;
        },
        end: (item, monitor) => setDragging(false),
        collect: monitor => ({opacity: monitor.isDragging() ? 1 : 0}),
    });

    const [drop] = useColumnDrop({
        drop: (item, mon) => {
            setSortTooltipOpen(false);
            setHintTooltipOpen(false);
            setDragging(false);
        },
        index,
        headCellRefs: draggableHeadCellRefs,
        updateColumnOrder,
        columnOrder,
        columns,
        transitionTime: options.draggableColumns ? options.draggableColumns.transitionTime : 300,
        tableRef: tableRef ? tableRef() : null,
        tableId: tableId || 'none',
        timers,
    });

    const cellClass = clsx({
        [classes.root]: true,
        [classes.fixedHeader]: options.fixedHeader,
        'datatables-noprint': !print,
        [className]: className,
    });

    const showHintTooltip = () => {
        setSortTooltipOpen(false);
        setHintTooltipOpen(true);
    };

    const getTooltipTitle = () => {
        if (dragging) return '';
        if (!options.textLabels) return '';
        return options.textLabels.body.columnHeaderTooltip
            ? options.textLabels.body.columnHeaderTooltip(column)
            : options.textLabels.body.toolTip;
    };

    const closeTooltip = () => setSortTooltipOpen(false);
    const hideHintTooltip = () => setHintTooltipOpen(false);
    const showFilterTooltip = () => setFilterTooltipOpen(true);
    const hideFilterTooltip = () => setFilterTooltipOpen(false);

    let filterTable = 'Filter'

    function renderCustomField(column, index) {
        const width = (column.filterOptions && column.filterOptions.fullWidth) === true ? 12 : 6;
        const display =
            (column.filterOptions && column.filterOptions.display) ||
            (options.filterOptions && options.filterOptions.display);

        if (!display) {
            console.error('Property "display" is required when using custom filter type.');
            return;
        }
        if (column.filterListOptions && column.filterListOptions.renderValue) {
            console.warning('"renderValue" is ignored for custom filter fields');
        }

        return <div style={{padding: "20px"}}>

            {display(filterList, onFilterChange, index, column, [], null, true)}
            <Button color="primary"
                    variant={""}
                    onClick={() => {
                        setHideFilterPopover(true)
                        return applyFilters();
                    }}>Apply Filters</Button>
            <Button color="primary"
                    className={classes.resetLink}
                    tabIndex={0}
                    aria-label={options.textLabels.filter.reset}
                    data-testid={'filterReset-button'}
                    onClick={resetFilters}>
                {options.textLabels.filter.reset}
            </Button>


        </div>
    }

    return <TableCell id={column?.testId + "_header"}
                      ref={ref => {
                          drop && drop(ref);
                          setCellRef && setCellRef(index + 1, colPosition + 1, ref);
                      }}
                      className={cellClass}
                      scope={'col'}
                      sortDirection={ariaSortDirection}
                      data-colindex={index}
                      data-tableid={tableId}
                      onMouseDown={closeTooltip}
                      {...otherProps}>

        {
            options.sort && sort ?
                <div className={classes.contentWrapper}>
                    <Tooltip title={getTooltipTitle()}
                             placement="bottom"
                             open={sortTooltipOpen}
                             onOpen={() => (dragging ? setSortTooltipOpen(false) : setSortTooltipOpen(true))}
                             onClose={() => setSortTooltipOpen(false)}
                             classes={{
                                 tooltip: classes.tooltip,
                                 popper: classes.mypopper,
                             }}>

                        <Button id={column?.testId + "_sort"}
                                variant=""
                                onKeyUp={handleKeyboardSortInput}
                                onClick={handleSortClick}
                                className={classes.toolButton}
                                data-testid={`headcol-${index}`}
                                ref={isDraggingEnabled() ? dragRef : null}>

                            <div className={classes.sortAction}>
                                <div className={clsx({
                                    [classes.data]: true,
                                    [classes.sortActive]: sortActive,
                                    [classes.dragCursor]: isDraggingEnabled(),
                                })}>
                                    {children}
                                </div>
                                <div className={classes.sortAction}>
                                    <TableSortLabel {...sortLabelProps} />
                                </div>
                            </div>
                        </Button>
                    </Tooltip>
                    <Popover
                        refExit={console.log}
                        hide={hideFilterPopover || options.filter === 'disabled'}
                        classes={{paper: classes.filterPaper, closeIcon: classes.filterCloseIcon}}
                        trigger={
                            <Tooltip title={filterTable}
                                     onOpen={showFilterTooltip}
                                     onClose={hideFilterTooltip}
                                     open={filterTooltipOpen}
                                     disableFocusListener>
                                <IconButton
                                    className={classes.filterIcon}
                                    data-testid={tableName.replaceAll(' ', '_') + '-' + filterTable + '-iconButton'}
                                    id={tableName.replaceAll(' ', '_') + '-' + filterTable + '-iconButton'}
                                    aria-label={filterTable}
                                    onClick={() => setHideFilterPopover(false)}>
                                    <FilterList
                                        style={{color: filterList[index].length > 0 ? themeColor1.hexa() : null}}/>
                                </IconButton>
                            </Tooltip>
                        }
                        content={renderCustomField(column, index)}
                    />

                    {hint && <IconToolTip classes={{IconButton: {padding: "5px 5px 10px 5px"}}}
                                          iconButton={<InfoOutlined style={{width: "18px"}}/>}
                                          title={<ReactMarkdown remarkPlugins={[gfm]}>{hint}</ReactMarkdown>}>
                        <ReactMarkdown remarkPlugins={[gfm]}>
                            {hint}
                        </ReactMarkdown>
                    </IconToolTip>}
                </div> : <div className={hint ? classes.sortAction : null} ref={isDraggingEnabled() ? dragRef : null}>
                    {children}
                    {hint && (
                        <Tooltip
                            title={hint}
                            placement={'bottom-end'}
                            open={hintTooltipOpen}
                            onOpen={showHintTooltip}
                            onClose={hideHintTooltip}
                            classes={{
                                tooltip: classes.tooltip,
                                popper: classes.mypopper,
                            }}
                            enterDelay={300}>
                            <HelpIcon className={classes.hintIconAlone} fontSize="small"/>
                        </Tooltip>
                    )}
                </div>}
    </TableCell>;
};

TableHeadCell.propTypes = {
    /** Options used to describe table */
    options: PropTypes.object.isRequired,
    /** Current sort direction */
    sortDirection: PropTypes.oneOf(['asc', 'desc', 'none']),
    /** Callback to trigger column sort */
    toggleSort: PropTypes.func.isRequired,
    /** Sort enabled / disabled for this column **/
    sort: PropTypes.bool.isRequired,
    /** Hint tooltip text */
    hint: PropTypes.string,
    /** Column displayed in print */
    print: PropTypes.bool.isRequired,
    applyFilters: PropTypes.func.isRequired,
    resetFilters: PropTypes.func.isRequired,
    /** Optional to be used with `textLabels.body.columnHeaderTooltip` */
    column: PropTypes.object,
    /** Injectable component structure **/
    components: PropTypes.object,
    /** Optional to be used with `textLabels.body.columnHeaderTooltip` */
    tableName: PropTypes.string.isRequired,
    /** Optional to be used with `textLabels.body.columnHeaderTooltip` */
    filterList: PropTypes.array.isRequired,
};

export default TableHeadCell;
