import {FormControl, InputLabel, MenuItem, Select} from "@mui/material";
import React from "react";
import {StyledToolTip} from "../../../ColumnsUtil";
import PropTypes from "prop-types";


export default function SelectCustom2(props: any) {

    const readOnlyDisplay = <div>{props.label}: <span
        style={{fontWeight: "bold"}}>{props.options.find((o: any) => o.value === props.value)?.description}</span>
    </div>;

    const label = <>
        {props.isInTable ? null : props.label} {props.isRequired && !props.valid &&
        <span style={{color: "#f00", fontSize: "0.75rem"}}>
            (Required)
            </span>}
    </>;

    const styles = {margin: "22px 0 5px 0"};

    return props.isReadOnly ? readOnlyDisplay :
        <FormControl fullWidth sx={styles}>
            {!props.isInTable && <InputLabel id="demo-simple-select-label">{label}</InputLabel>}
            <Select value={props.value}
                    notched={false}
                    name={props.name}
                    label={label}
                    error={props.isError}
                    required={props.isRequired}
                    onChange={props.onChange}>
                <MenuItem value="" id={props.name + "_emptyselect"}>
                    -- Select {props.label} --
                </MenuItem>

                {
                    props.options ? props.options.map((o: { label: any, value: any, description: any }) =>

                            <MenuItem
                                id={props.name + "_" + o.label.replaceAll(" ", "_")}
                                key={props.name + "_" + o.label.replaceAll(" ", "_")}
                                value={o.value}>
                                <StyledToolTip key={props.name + o.label + "tooltip"} title={o.description}>
                                    <div style={{width: "100%", height: "100%"}}>{o.label}</div>
                                </StyledToolTip></MenuItem>) :
                        <MenuItem value="" disabled>
                            Loading
                        </MenuItem>
                }
            </Select>
        </FormControl>;
}

SelectCustom2.propTypes = {
    name      : PropTypes.string.isRequired,
    label     : PropTypes.string,
    value     : PropTypes.any,
    options   : PropTypes.array.isRequired,
    onChange  : PropTypes.func.isRequired,
    isReadOnly: PropTypes.bool,
    valid: PropTypes.bool,
    isInTable : PropTypes.bool,
    isError   : PropTypes.bool,
    isRequired: PropTypes.bool
}
