export const AUTOCOMPLETER_URL   = 'autocompleter'
export const CUSTOMERS_URL       = 'customers'
export const DB_STATUS_URL       = 'db_status'
export const FILE_UPLOAD_URL     = 'file_upload'
export const FORMS_URL           = 'forms'
export const NAV_URL             = 'nav'
export const QUESTIONNAIRES_URL  = 'questionnaires'
export const QUEUE_ACTIONS_URL   = 'queue_actions'
export const QUEUE_DASHBOARD_URL = 'queue_dashboard'
export const QUEUE_LOCATIONS_URL = 'queue_locations'
export const TABLE_ENUM_URL      = 'table_enum'
export const TOOL_STATE_URL      = 'tool_state'
export const TOOLS_URL           = 'tools'