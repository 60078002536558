import React, {useState} from 'react';
import Typography from '@mui/material/Typography';
import Toolbar from '@mui/material/Toolbar';
import IconButton from '@mui/material/IconButton';
import TableViewCol from './TableViewCol';
import {withStyles} from 'tss-react/mui';
import {createCSVDownload, downloadCSV} from '../utils';
import MuiTooltip from '@mui/material/Tooltip';
import {Download} from "react-feather";

export const defaultToolbarStyles = theme => ({
    root: {
        '@media print': {
            display: 'none',
        },
    },
    fullWidthRoot: {},
    left: {
        flex: '1 1 auto',
    },
    fullWidthLeft: {
        flex: '1 1 auto',
    },
    actions: {
        flex: '1 1 auto',
        textAlign: 'right',
    },
    fullWidthActions: {
        flex: '1 1 auto',
        textAlign: 'right',
    },
    titleRoot: {},
    titleText: {},
    fullWidthTitleText: {
        textAlign: 'left',
    },
    icon: {
        '&:hover': {
            color: theme.palette.primary.main,
        },
    },
    iconActive: {
        color: theme.palette.primary.main,
    },
    filterPaper: {
        maxWidth: '50%',
    },
    filterCloseIcon: {
        position: 'absolute',
        right: 0,
        top: 0,
        zIndex: 100,
    },
    [theme.breakpoints.down('md')]: {
        titleRoot: {},
        titleText: {
            fontSize: '16px',
        },
        spacer: {
            display: 'none',
        },
        left: {
            // flex: "1 1 40%",
            padding: '8px 0px',
        },
        actions: {
            // flex: "1 1 60%",
            textAlign: 'right',
        },
    },
    [theme.breakpoints.down('sm')]: {
        root: {
            display: 'block',
            '@media print': {
                display: 'none !important',
            },
        },
        left: {
            padding: '8px 0px 0px 0px',
        },
        titleText: {
            textAlign: 'center',
        },
        actions: {
            textAlign: 'center',
        },
    },
    '@media screen and (max-width: 480px)': {},
});

function TableToolbar({
                          data,
                          options,
                          classes,
                          columns,
                          toggleViewColumn,
                          updateColumns,
                          title,
                          setTableAction,
                          components = {}
                      }) {
    const [state, setState] = useState({
        iconActive: null
    })

    const handleCSVDownload = () => {

        let newData = data.map((d, i) => {
            return {index: i, data: Object.values(d)}
        })

        let columnOrder = columns.map(c => c.actualOrder)

        let dataToDownload = []; //cloneDeep(data);
        let columnsToDownload = [];
        let columnOrderCopy = Array.isArray(columnOrder) ? columnOrder.slice(0) : [];

        if (columnOrderCopy.length === 0) {
            columnOrderCopy = columns.map((item, idx) => idx);
        }

        newData.forEach(row => {
            let newRow = {index: row.index, data: []};
            columnOrderCopy.forEach(idx => {
                newRow.data.push(row.data[idx]);
            });
            dataToDownload.push(newRow);
        });

        columnOrderCopy.forEach(idx => {
            columnsToDownload.push(columns[idx]);
        });

        createCSVDownload(columnsToDownload, dataToDownload, options, downloadCSV);
    };

    const setActiveIcon = iconName => {
        setState(
            prevState => ({
                iconActive: iconName,
                prevIconActive: prevState.iconActive,
            }),
            () => {
                const {iconActive, prevIconActive} = state;

                if (iconActive === 'filter') {
                    setTableAction('onFilterDialogOpen');
                    if (options.onFilterDialogOpen) {
                        options.onFilterDialogOpen();
                    }
                }
                if (iconActive === undefined && prevIconActive === 'filter') {
                    setTableAction('onFilterDialogClose');
                    if (options.onFilterDialogClose) {
                        options.onFilterDialogClose();
                    }
                }
            },
        );
    };


    const getActiveIcon = (styles, iconName) => {
        let isActive = state.iconActive === iconName;
        return isActive ? styles.iconActive : styles.icon;
    };

    const Tooltip = MuiTooltip;
    const TableViewColComponent = TableViewCol;
    const {downloadCsv, viewColumns} = options.textLabels.toolbar;
    const testIDStr = title.props.tool.title.replaceAll(' ', '_');

    return <Toolbar
        className={classes.root}
        role={'toolbar'}
        aria-label={'Table Toolbar'}>
        <div
            className={classes.left}>
            {typeof title !== 'string' ? title : <div className={classes.titleRoot} aria-hidden={'true'}>
                <Typography
                    variant="h6"
                    className={classes.titleText}>
                    {title}
                </Typography>
            </div>}
        </div>
        <div className={classes.actions}>
            <Tooltip title={downloadCsv}>
                <IconButton
                    data-testid={`${testIDStr}-${downloadCsv.replace(/\s/g, '')}-iconButton`}
                    id={`${testIDStr}-${downloadCsv.replace(/\s/g, '')}-iconButton`}
                    aria-label={downloadCsv}
                    classes={{root: classes.icon}}
                    disabled={options.download === 'disabled'}
                    onClick={handleCSVDownload}>
                    <Download/>
                </IconButton>
            </Tooltip>
            {/*<Popover*/}
            {/*    refExit={setActiveIcon.bind(null)}*/}
            {/*    classes={{closeIcon: classes.filterCloseIcon}}*/}
            {/*    hide={options.viewColumns === 'disabled'}*/}
            {/*    trigger={*/}
            {/*        <Tooltip title={viewColumns} disableFocusListener>*/}
            {/*            <IconButton*/}
            {/*                data-testid={`${testIDStr}-${viewColumns}-iconButton`}*/}
            {/*                aria-label={viewColumns}*/}
            {/*                classes={{root: getActiveIcon(classes, 'viewcolumns')}}*/}
            {/*                disabled={options.viewColumns === 'disabled'}*/}
            {/*                onClick={setActiveIcon.bind(null, 'viewcolumns')}>*/}
            {/*                <Columns/>*/}
            {/*            </IconButton>*/}
            {/*        </Tooltip>*/}
            {/*    }*/}
            {/*    content={*/}
            {/*        <TableViewColComponent*/}
            {/*            data={data}*/}
            {/*            columns={columns}*/}
            {/*            options={options}*/}
            {/*            onColumnUpdate={toggleViewColumn}*/}
            {/*            updateColumns={updateColumns}*/}
            {/*            components={components}*/}
            {/*        />*/}
            {/*    }*/}
            {/*/>*/}
        </div>
    </Toolbar>
}

export default withStyles(TableToolbar, defaultToolbarStyles, {name: 'MUIDataTableToolbar'});
