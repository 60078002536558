import {StackFrame, Tool, ToolGroup, ToolStack, ToolTab} from "../classes";
import {v3} from "uuid";

export default function getAnyByUUID(uuid: any, toolStacks: any): ToolStack | StackFrame | ToolGroup | ToolTab | Tool {

    let found = null as ToolStack | StackFrame | ToolGroup | ToolTab | Tool | null

    toolStacks.forEach((toolStack: ToolStack) => {
        if (!found) {
            if (toolStack.uuid === uuid) {
                found = toolStack.uuid;
            } else {
                found = searchFramesByUUID(toolStack.stackFrames, uuid);
            }
        }
    })

    if (found === null) {
        throw Error("Unable to find UUID " + uuid)
    }

    return found
}

function searchFramesByUUID(stackFrames: StackFrame[], uuid: any): StackFrame | ToolGroup | ToolTab | Tool | null {
    let found = null as StackFrame | ToolGroup | ToolTab | Tool | null

    stackFrames.forEach((stackFrame: StackFrame) => {
        if (!found) {
            if (stackFrame.uuid === uuid) {
                found = stackFrame
            } else if (stackFrame.toolOrGroup.uuid === uuid) {
                found = stackFrame.toolOrGroup
            } else {
                const group = stackFrame.toolOrGroup as ToolGroup;
                if (group.tabs && group.tabs.length > 0) {
                    found = searchTabsByUUID(group.tabs, uuid);
                } else if (group.parsedTools) {
                    found = searchToolsByUUID(group.parsedTools, uuid);
                }
            }
        }
    });

    return found;
}


function searchTabsByUUID(tabs: ToolTab[], uuid: any): ToolTab | Tool | null {
    let found = null as ToolTab | Tool | null
    tabs?.forEach(tab => {
        if (!found) {
            if (tab.uuid === uuid) {
                found = tab
            } else if (tab.tabs && tab.tabs.length > 0) {
                found = searchTabsByUUID(tab.tabs, uuid)
            } else if (tab.parsedTools) {
                found = searchToolsByUUID(tab.parsedTools, uuid)
            }
        }
    })
    return found
}

function searchToolsByUUID(tools: Tool[], uuid: any): Tool | null {
    return tools.find(t => t.uuid === uuid) || null
}

export function getUUID(obj: any) {
    return v3(JSON.stringify(obj), v3.URL)
}
