import {Document, Page} from "react-pdf";
import React, {useState} from "react";
import PropTypes from "prop-types";
import {CircularProgress} from "@mui/material";
import {makeStyles} from "@mui/styles";
import {progressSpinnerColor} from "../../styles/getMuiTheme";

export default function PdfToolComponent(props) {
    const options = {
        cMapUrl: 'cmaps/',
        cMapPacked: true,
        standardFontDataUrl: 'standard_fonts/',
    };
    const useStyles = makeStyles((theme) => {
        return ({
            progressContainer: {
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
                width: '100%',
                height: '100%',
            },
            progressSpinner: {
                color: progressSpinnerColor.hexa()
            }
        });
    });
    const classes = useStyles()

    const [numPages, setNumPages] = useState(null);
    const [pageNumber, setPageNumber] = useState(1);

    function onDocumentLoadSuccess({numPages}) {
        setNumPages(numPages);
    }

    return <>            {(props.tool.isLoading || !props.tool.initialized) &&
        <div className={classes.progressContainer}>
        <CircularProgress className={classes.progressSpinner}/>
    </div>}

        {props.tool.toolData && <Document file={{data: props.tool.toolData}}
                                          options={options}
                                          onLoadSuccess={onDocumentLoadSuccess}>
            {Array.apply(null, Array(numPages))
                .map((x, i) => i + 1)
                .map(page => <Page key={props.tool.name + page}
                                   pageNumber={page}
                                   renderAnnotationLayer={true}
                                   renderForms={true}
                                   renderMode={"canvas"}
                                   width={1200}/>)}
        </Document>}
    </>

}

PdfToolComponent.propTypes = {
    tool: PropTypes.object,
}