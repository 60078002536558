import PropTypes from 'prop-types'
import {Button, Checkbox, FormControl, Grid, InputLabel, ListItemText, MenuItem, Select} from "@mui/material";
import React, {useEffect, useState} from "react";
import {Filter} from "../../../classes";

export default function BooleanFilter(props: any) {

    const renderValue                 = (values: any[]) => values && values.length > 0 ? values.map(v => v).join(', ') : "--";
    const [selectOptions]             = useState(props.selectOptions || null) as any[];
    const [showSelect, setShowSelect] = React.useState(false);

    const [value, setValue] = useState(selectOptions ? props.filterValue.filter((val: any) => selectOptions.includes(val)) : props.filterValue)

    const multiselect = props.multiselect !== null && props.multiselect !== undefined ? props.multiselect : false

    const displayAsList = props.displayAsList !== null && props.displayAsList !== undefined ? props.displayAsList : false

    useEffect(() => {
        setValue(selectOptions ? props.filterValue.filter((val: any) => selectOptions.includes(val)) : props.filterValue)
    }, [props.filterValue]);

    function handleSelection(e: any) {
        const val = e

        let filter
        if (value.includes(val)) {
            filter = [...value].filter((v: any) => v !== val);
        } else {
            filter = [...value, val];
        }
        setValue(filter)
        props.onChange({target: {value: filter}})

    }

    return <Grid item key={props.testId}>
        <FormControl key={props.testId} style={{margin: "22px 0 5px 0"}}>
            <InputLabel htmlFor={props.columnName}>{props.label}</InputLabel>
            {displayAsList ? <div>

                {
                    selectOptions.map((v: any) => {
                        const option  = v === null ? "(blank)" : v
                        const checked = props.filterValue.indexOf(option.value) >= 0;
                        return <MenuItem value={option.value}
                                         selected={checked && !multiselect}
                                         key={option.key}
                                         id={props.testId + "_option_" + option.key}
                                         onClick={() => handleSelection(option.value)}>
                            {multiselect && <Checkbox color="primary"
                                                      checked={checked}
                                                      value={option}/>}
                            <ListItemText primary={option.key}/>
                        </MenuItem>;
                    })
                }


            </div> : <Select
                id={props.testId + "_filter"}
                open={showSelect}
                multiple={multiselect}
                displayEmpty={true}
                value={value}
                renderValue={renderValue}
                name={props.columnName}
                onOpen={() => setShowSelect(true)}
                onClose={() => setShowSelect(false)}
                onChange={props.onChange}>
                {
                    selectOptions.map((option: any) => {

                        const checked = multiselect && props.filterValue.indexOf(option.value) >= 0;

                        return <MenuItem value={option.value}
                                         selected={checked && !multiselect}
                                         key={option.key}
                                         id={props.testId + "_option_" + option.key}>
                            {multiselect && <Checkbox color="primary"
                                                      checked={checked}
                                                      value={option}/>}
                            <ListItemText primary={option.key}/>
                        </MenuItem>;
                    })
                }
                {multiselect &&
                    <Button id={props.columnName.replaceAll(" ", "_") + "_filter_close_button"} variant={'text'}
                            onClick={() => setShowSelect(false)}>
                        Close
                    </Button>}
            </Select>}
        </FormControl>
    </Grid>
}

BooleanFilter.propTypes = {
    filterValue    : PropTypes.array,
    multiselect    : PropTypes.bool,
    selectOptions  : PropTypes.array,
    columnName     : PropTypes.string.isRequired,
    testId         : PropTypes.string.isRequired,
    label          : PropTypes.string.isRequired,
    tableName      : PropTypes.string.isRequired,
    onChange       : PropTypes.func.isRequired,
    existingFilters: PropTypes.arrayOf(PropTypes.instanceOf(Filter)),
    displayAsList  : PropTypes.bool
}