import React, {useContext, useEffect} from "react";
import mermaid from "mermaid";
import {CircularProgress, Slider} from "@mui/material";
import {progressSpinnerColor} from "./styles/getMuiTheme";
import PropTypes from "prop-types";
import moment from "moment";
import {MyDataContext} from "./ToolsComponent";

mermaid.initialize({
    mermaid: {
        startOnLoad: true,
    },
    securityLevel: "loose",
});

export default function Mermaid(props) {
    const [state] = useContext(MyDataContext);

    window.callback = async function (e) {
        state.handleMermaidNodeClick(e)
    }

    useEffect(() => {
        document.getElementsByClassName('mermaid')[0]?.removeAttribute('data-processed')
        mermaid.contentLoaded();
    }, [props.tool?.toolData]);

    return (props.tool?.toolData ? <>
        <div style={{padding: "10px"}}>Last Updated {moment(props.tool.lastUpdated).format("hh:mm:ss a")}</div>
        <div style={{display: "flex", alignItems: "center"}}>

            <div style={{display: "flex", alignItems: "center"}}>
                <span>1 sec.</span>
                <Slider aria-label="Mermaid Refresh Interval"
                        style={{width: "100px", marginLeft: "10px", marginRight: "10px"}}
                        value={props.tool?.refreshInterval}
                        min={1}
                        max={60}
                        valueLabelDisplay="auto"
                        onChange={(event) => state.handleMermaidRefreshIntervalChange(props.tool, event)}/>
                <span>60 sec.</span>
            </div>
            <div style={{display: "flex", alignItems: "center", marginLeft: "20px"}}>
                <span>100px</span>
                <Slider aria-label="Mermaid Width"
                        style={{width: "100px", marginLeft: "10px", marginRight: "10px"}}
                        value={props.tool?.mermaidWidth || 1000}
                        min={100}
                        max={1000}
                        valueLabelDisplay="auto"
                        onChange={(event) => state.handleMermaidWidthChange(props.tool, event)}/>
                <span>1000px</span>
            </div>
        </div>
        <div style={{width: props.tool?.mermaidWidth + "px", overflow: "auto"}}>
            <div className="mermaid">{props.tool.toolData}</div>
        </div>
        {props.tool.isLoading || !props.tool.initialized ? null :
            <span id={props.tool?.title.replaceAll(" ", "_") + "_loaded"}/>}
    </> : <div style={{
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        height: '100vh',
    }}>
        <CircularProgress style={{color: progressSpinnerColor.hexa()}}/>
    </div>);
}
Mermaid.propTypes = {
    tool: PropTypes.object
}