import DrillDownLabeledArray from "./DrillDownLabeledArray";
import DrillDownTable from "./DrillDownTable";
import React from "react";
import DrillDownList from "./DrillDownList";

export default function DrillDownArray(props) {

    let arr = props.arr
    let key = props.name
    let depth = props.depth

    let firstItem = arr[0]

    if (!arr.find(i => typeof i === "object")) {
        return <DrillDownList tableRows={arr} name={key} depth={depth}/>
    }

    if (Object.keys(firstItem).indexOf("FormLabel") >= 0) {
        return <DrillDownLabeledArray arr={arr} name={key} depth={depth}/>;
    }


    return <DrillDownTable tableRows={arr} name={key} depth={depth}/>;
}
