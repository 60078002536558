import {SignInButton} from "./SignInButton";
import React from "react";
import LogoComponent from "../icons/logoComponent";
import {makeStyles, ThemeProvider} from "@mui/styles";
import {Theme} from "@mui/material";
import getMuiTheme, {loginPageBgColor, loginPanelBgColor} from "../../styles/getMuiTheme";

const useStyles = makeStyles((theme: Theme) => {
    const top  = 50;
    const left = 50;

    return ({
        paper : {
            position       : 'absolute',
            width          : "50vw",
            maxWidth       : "400px",
            height         : "50vh",
            display        : 'flex',
            flexDirection  : "column",
            justifyContent : 'center',
            alignItems     : 'center',
            backgroundColor: loginPanelBgColor.hexa() + " !important",
            boxShadow      : "0px 0px 5px #aaa",
            padding        : "2px 4px 3px 4px"
        },
        button: {
            position : 'absolute',
            top      : `${top}%`,
            left     : `${left}%`,
            transform: `translate(-${top}%, -${left}%)`,
        }
    });
});

export default function LoginPage(props: any) {
    const classes = useStyles();

    return (
        <ThemeProvider theme={getMuiTheme({})}>
            <div style={{
                backgroundColor: loginPageBgColor.hexa(),
                height         : "100vh",
                display        : 'flex',
                flexDirection  : "column",
                justifyContent : 'center',
                alignItems     : 'center',

            }}>
                <div style={{
                    width         : "400px",
                    justifyContent: 'center',
                    alignItems    : 'center',
                    display       : 'flex',
                    marginBottom  : "50px"
                }}><LogoComponent handleClick={props.onLogo}
                                  height={"100px"}
                                  handleCancelClick={props.onCancel}
                                  open={props.open}
                /></div>
                <div style={{
                    backgroundColor: loginPanelBgColor.hexa(),
                    display        : 'flex',
                    justifyContent : 'center',
                    alignItems     : 'center',
                    borderRadius   : "8px",
                    width          : "400px",
                    height         : "300px"
                }}>
                    <SignInButton/>
                </div>

            </div>

        </ThemeProvider>
    );
}