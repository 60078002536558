import React, {useContext, useEffect, useState} from 'react';
import {FILE_UPLOAD_URL, FORMS_URL, QUESTIONNAIRES_URL} from "../../util/RestRoutes";
import PropTypes from "prop-types";
import {MyDataContext} from "../../ToolsComponent";
import {mapFormFieldsRecursively, mapJsonToSchema, mapLayoutItem, myComponentMapper} from "../../util/FormUtils";
import {doGet, doPost} from "../../util/RestUtil";
import {makeStyles} from "@mui/styles";
import {CircularProgress, Dialog, DialogContent, DialogTitle, IconButton, Theme, Typography} from "@mui/material";
import {blackColor, errorColor, jsonColor1, progressSpinnerColor} from "../../styles/getMuiTheme";
import {FormRenderer, FormSpy, useFormApi} from "@data-driven-forms/react-form-renderer";
import Button from "@mui/material/Button";
import {IconToolTip} from "../login/IconToolTip";
import {HelpOutline} from "@mui/icons-material";
import ReactMarkdown from "react-markdown";
import gfm from "remark-gfm";
import CloseIcon from "@mui/icons-material/Close";
import DrillDownDetail from "../drilldown/DrillDownDetail";
import {LocalizationProvider} from "@mui/x-date-pickers/LocalizationProvider";
import {AdapterMoment} from "@mui/x-date-pickers/AdapterMoment";
import useConfirm from "../useConfirm";


export default function FormComponent(props: any) {
    const [state] = useContext(MyDataContext);

    let getUrl: string
    let postUrl: string

    if (props.dbName.startsWith('FormQuestionnaireView')) {
        const questionnaireID = props.dbName.split("|").find((s: string) => s.startsWith("ID="))?.split("=")[1];

        getUrl  = `${QUESTIONNAIRES_URL}/${questionnaireID}`
        postUrl = `${QUESTIONNAIRES_URL}/${questionnaireID}`
    } else {
        getUrl  = `${FORMS_URL}/${props.dbName}`
        postUrl = `${FORMS_URL}/${props.dbName}`
    }

    const [subtitle, setSubtitle]                       = useState(null as any)
    const [layout, setLayout]                           = useState(null as any)
    const [formData, setFormData]                       = useState({})
    const [formName, setFormName]                       = useState("Loading...")
    const [schema, setSchema]                           = useState(null as any)
    const [error, setError]                             = useState(null as any)
    const [metadata, setMetadata]                       = useState(null as any)
    const [file, setFile]                               = useState(null);
    const [readOnly, setReadOnly]                       = useState(true);
    const [submitButtonClicked, setSubmitButtonClicked] = useState(false)
    const [responseMessage, setResponseMessage]         = useState(null as any)
    const [getConfirmation, Confirmation]               = useConfirm()

    function mapSubmittedForm(submittedForm: any) {
        let newForm = {...submittedForm}
        mapFormFieldsRecursively(newForm)
        return newForm
    }

    function addFieldRestRoute(formField: any, restRoutes: any[]) {
        if (formField.restUrl) {
            if (formField.type === 'CHECKBOX') {
                restRoutes.push(formField.restUrl + "|PAYLOAD=RESTCALL|FIELD=" + formField.formField)
            } else {
                restRoutes.push(formField.restUrl + "|FIELD=" + formField.formField)
            }
        }

        if (formField.fields) {
            formField.fields.forEach((f: any) => addFieldRestRoute(f, restRoutes))
        }
    }

    function getFieldValue(fieldName: string, data: any, detailID: any) {

        if (fieldName === 'DETAIL_ID') {
            return detailID
        }

        // TODO: accommodate multiple fields matching and nested data. This makes an assumption that the data wanted is always at the top level of the form object.
        return data[fieldName];
    }

    async function onSubmit(submittedForm: any) {
        try {

            // @ts-ignore
            const actionName   = document.activeElement.name;
            const submitAction = metadata.actions?.find((a: any) => a.name === actionName)


            setError(null)
            let mappedForm = mapSubmittedForm(submittedForm)

            if (mappedForm.file) {
                const fd = new FormData()

                fd.append('file', mappedForm.file.inputFiles[0])

                let params = {}

                // @ts-ignore
                Object.entries(mappedForm).filter(([k, v]) => k !== "file").forEach(([k, v]) => params[k] = v)

                let response: any = await doPost(`${FILE_UPLOAD_URL}`, fd, {params}, props.customerObjectID)

                setResponseMessage(response.data)
            } else {

                let restRoutes = [] as any

                if (metadata.restUrl) {
                    restRoutes.push(metadata.restUrl)
                }

                metadata.fields.forEach((f: any) => addFieldRestRoute(f, restRoutes))

                if (restRoutes.length > 0) {
                    let mappedRestRoutes = restRoutes.map((r: string) => {
                        let fieldVariables = r.match(/\{(.*?)}/g);

                        if (fieldVariables) {
                            fieldVariables.forEach((m: string) => {
                                const noBrackets = m.replaceAll("{", "").replaceAll("}", "");
                                r = r.replace(m, getFieldValue(noBrackets, mappedForm, props.detailID))
                            })
                        }
                        return r
                    })

                    let restCallPromises = mappedRestRoutes.map((rr: string) => {

                        let url     = rr.split("|")[0]
                        // url         = "api/" + url
                        let payload = rr.split("|").find((s: string) => s.startsWith("PAYLOAD="))?.split("=")[1] || "NONE" as "FORM" | "FIELD" | "RESTCALL" | "NONE"
                        let field   = rr.split("|").find((s: string) => s.startsWith("FIELD="))?.split("=")[1] as string
                        switch (payload) {
                            case "FORM":
                                return doPost(url, mappedForm, null, props.customerObjectID)
                            case "FIELD":
                                return doPost(url, mappedForm[field], null, props.customerObjectID)
                            case "RESTCALL":
                                return mappedForm[field] ? doPost(url, null, null, props.customerObjectID) : new Promise<any>((resolve, reject) => resolve("Skipping rest call to " + url));
                            case "NONE":
                                return doPost(url, null, null, props.customerObjectID)
                        }

                    }).filter((p: any) => p !== null)

                    let responses = await Promise.all(restCallPromises)

                    console.log(responses)

                } else {
                    if (submitAction) {
                        let formResponse = await doPost(postUrl, {
                            form  : mappedForm,
                            action: submitAction
                        }, null, props.customerObjectID)
                        setResponseMessage(formResponse)
                    } else {
                        let formResponse = await doPost(postUrl, mappedForm, null, props.customerObjectID)
                        setResponseMessage(formResponse)
                    }
                }


            }

            setFormData(submittedForm)
            await state.handleFormSubmit()

            if (metadata.submitBehavior !== 'MESSAGE') {
                state.handleFormClose()
            }

        } catch (e) {
            // @ts-ignore
            setError(e)
            console.log(e)
        }
    }

    function onCancel(submittedForm: any) {
        onClose(null, 'Cancelled')
    }

    function onClose(event: any, reason: any) {
        // @ts-ignore
        setError(null)
        setSubmitButtonClicked(false)
        if (reason !== 'backdropClick') {
            state.handleFormClose()
        }
    }


    useEffect(() => {
        const fetchData = async () => {
            try {

                setFormData({})
                setFormName("Loading...")

                let config = {}

                if (props.detailID) {
                    config = {params: {detailID: props.detailID}}
                }

                const resp: any        = await doGet(getUrl, config, props.customerObjectID)
                const formResponseData = resp.data
                const newMetadata      = formResponseData.metadata;
                const formData         = formResponseData.data;
                const newReadOnly      = formResponseData.readOnly !== undefined && formResponseData.readOnly !== null ? formResponseData.readOnly : true;
                const newSchema = mapJsonToSchema(newMetadata, newMetadata.layout, props.customerObjectID, newReadOnly, props.detailID, formData, getConfirmation);

                setMetadata(newMetadata)
                setFormData(formData)
                setFormName(newMetadata.name)
                setSubtitle(newMetadata.subtitle)
                setReadOnly(newReadOnly)
                setLayout(newMetadata.layout)
                setSchema(newSchema)

            } catch (e) {
                console.error(e)
            }
        };

        fetchData()
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);


    const useStyles = makeStyles(
        (theme: Theme) => {
            return ({
                errorMessage               : {
                    color: errorColor.hexa()
                },
                submitButton               : {
                    marginRight: 8
                },
                submitProgressSpinner      : {
                    marginLeft: "10px"
                },
                titleBar                   : {
                    display   : "flex",
                    alignItems: "center"
                },
                markdownTable              : {
                    border: "1px black solid"
                },
                closeButton                : {
                    position: 'absolute',
                    right   : 8,
                    top     : 11
                },
                subtitle                   : {
                    fontSize  : "1rem",
                    fontWeight: "normal"
                },
                responseMessage            : {
                    color: props.responseMessage?.success ? blackColor.hexa() : errorColor.hexa()
                },
                formLoadProgressSpinner    : {
                    width         : "300px",
                    height        : "200px",
                    display       : 'flex',
                    alignItems    : 'center',
                    justifyContent: "center",
                },
                formLoadProgressSpinnerIcon: {
                    color: progressSpinnerColor.hexa()
                }
            })
        })

    const validatorMapper = {
        'same-email': () => (
            value: any, allValues: any
        ) => (
            value !== allValues.email ?
                'Email does not match' :
                undefined
        )
    }

    const classes = useStyles()

    const getSubmitButton = (action: any, submitting: boolean) => {
        return <Button key={"action_submit_" + action.name}
                       disabled={submitting}
                       className={classes.submitButton}
                       style={{margin: "0 0 0 10px"}}
                       type="submit"
                       name={action.name}
                       color="secondary"
                       variant="contained">
            {submitting ? 'Submitting' : action.name}
            {submitting &&
                <CircularProgress className={classes.submitProgressSpinner} size={20}/>}
        </Button>
    }

    const getCancelButton = (action: any) => {
        return <Button variant="contained" onClick={onCancel} style={{margin: "0 0 0 10px"}}>
            {action.name}
        </Button>
    }

    function getButton(action: any, submitting: boolean) {
        switch (action.type) {
            case 'Submit':
                return getSubmitButton(action, submitting)
            case 'Cancel':
                return getCancelButton(action)
            default:
                return getSubmitButton(action, submitting)
        }
    }


    const MyFormTemplate = ({schema, formFields}: any) => {
        const {handleSubmit, onReset, onCancel, getState} = useFormApi();
        const {submitting, pristine}                      = getState();

        return (
            <form onSubmit={handleSubmit}>
                <Typography variant="h4" component="h2">
                    {schema.title}
                </Typography>

                {layout.map((item: any) => mapLayoutItem(item, formFields))}
                {formFields.filter((ff: any) => ff.props.hideField).map((ff: any) => ff)}

                <FormSpy>
                    {
                        () => <>
                            {error ? <div className={classes.errorMessage}>{error.toString()}</div> : null}
                            {readOnly ? <Button variant={"contained"} onClick={onCancel}>
                                Close
                            </Button> : <div style={{display: "flex", margin: "10px 0 0 0"}}>
                                {!metadata.actions || metadata.actions.length === 0 ?
                                    <>
                                        <Button disabled={submitting}
                                                className={classes.submitButton}
                                                type="submit"
                                                color="secondary"
                                                variant="contained">
                                            {submitting ? 'Submitting' : 'Submit'}
                                            {submitting &&
                                                <CircularProgress className={classes.submitProgressSpinner} size={20}/>}
                                        </Button>
                                        <Button variant="contained" onClick={onCancel} style={{margin: "0 0 0 10px"}}>
                                            Cancel
                                        </Button>
                                    </> : <>
                                        {metadata.actions.filter((a: any) => a.type === 'Submit').map((a: any) => getButton(a, submitting))}
                                        {metadata.actions.filter((a: any) => a.type === 'Cancel').map((a: any) => getButton(a, submitting))}
                                    </>
                                }
                            </div>}
                        </>
                    }
                </FormSpy>
            </form>
        )
    }

    const titleBar = <>
        <div className={classes.titleBar}>
            <span>{formName}</span>
            {
                metadata?.helpText &&

                <IconToolTip iconButton={<HelpOutline/>} title={"Help"}>
                    <ReactMarkdown
                        components={{
                            table: ({node, ...props}) => <table className={classes.markdownTable} {...props}/>,
                            a    : ({node, ...props}) => <a rel="noopener noreferrer"
                                                            target="_blank" {...props}>content</a>
                        }}
                        remarkPlugins={[gfm]}>{metadata?.helpText}</ReactMarkdown>
                </IconToolTip>
            }
        </div>

        {props.modal && <IconButton className={classes.closeButton} aria-label="close" onClick={state.handleFormClose}>
            <CloseIcon style={{color: jsonColor1.hexa()}}/>
        </IconButton>}

        <div className={classes.subtitle}>
            {subtitle}
        </div>
    </>;

    // @ts-ignore
    const content = <>
        {responseMessage &&
            <div className={classes.responseMessage}>
                {responseMessage.title}
                <DrillDownDetail object={{title: responseMessage.title, messages: responseMessage.messages}}
                                 depth={0}/>

                <Button color={"primary"} type="button" onClick={state.handleFormClose}>
                    Close
                </Button>
            </div>}
        {!responseMessage ? schema ?
            <LocalizationProvider dateAdapter={AdapterMoment}>
                <FormRenderer
                    initialValues={formData}
                    schema={schema}
                    componentMapper={myComponentMapper}
                    validatorMapper={validatorMapper}
                    FormTemplate={MyFormTemplate}
                    onSubmit={onSubmit}
                    onCancel={onCancel}
                />
            </LocalizationProvider>
            : <div className={classes.formLoadProgressSpinner}>
                <CircularProgress className={classes.formLoadProgressSpinnerIcon}/>
            </div> : null}

    </>;

    if (props.modal === undefined || props.modal === null || props.modal === true) {
        return <Dialog open={props.open} maxWidth={false} onClose={props.onClose}>
            <DialogTitle>{titleBar}</DialogTitle>
            <DialogContent>{content}</DialogContent>
            {/* @ts-expect-error Server Component */}
            <Confirmation/>

        </Dialog>;
    } else {
        return <div>
            <DialogTitle>{titleBar}</DialogTitle>
            <DialogContent>{content}</DialogContent>
        </div>;
    }
}

FormComponent.propTypes = {
    modal       : PropTypes.bool,
    dbName          : PropTypes.string,
    idColumnName: PropTypes.string,
    customerObjectID: PropTypes.string,
    open            : PropTypes.bool,
    detailID        : PropTypes.any
}

