import Color from "color";

export function relativeLuminanceW3C(R8bit: number, G8bit: number, B8bit: number) {

    let RsRGB = R8bit / 255;
    let GsRGB = G8bit / 255;
    let BsRGB = B8bit / 255;

    let R = (RsRGB <= 0.03928) ? RsRGB / 12.92 : Math.pow((RsRGB + 0.055) / 1.055, 2.4);
    let G = (GsRGB <= 0.03928) ? GsRGB / 12.92 : Math.pow((GsRGB + 0.055) / 1.055, 2.4);
    let B = (BsRGB <= 0.03928) ? BsRGB / 12.92 : Math.pow((BsRGB + 0.055) / 1.055, 2.4);

    // For the sRGB colorspace, the relative luminance of a color is defined as:
    return (0.2126 * R) + (0.7152 * G) + (0.0722 * B);
}

export function getTextColorAdvanced(bcColorHexa: string) {

    const backgroundColor = Color(bcColorHexa)

    // @ts-ignore
    let luminance = relativeLuminanceW3C(backgroundColor.color[0], backgroundColor.color[1], backgroundColor.color[2]);

    return luminance > 0.3 ? Color("#000000") : Color("#ffffff");
}
