import React from "react";
import useFieldApi from "@data-driven-forms/react-form-renderer/use-field-api";
import {ColorPicker} from "material-ui-color";


export default function ColorPickerFormComponent(props: any) {
    const {
              label,
              input,
              isRequired,
              meta
          }                            = useFieldApi(props);
    const {dirty, submitFailed, error} = meta;
    const isError                      = (dirty || submitFailed) && error && typeof error === 'string';
    const [localColor, setLocalColor]  = React.useState(input.value)
    return <ColorPicker value={localColor} onChange={(e) => {
        setLocalColor(e)
        input.onChange(e.hex || e);
    }}/>;
}
