function buildMap(rows) {
    return rows.reduce((accum, {dataIndex}) => {
        accum[dataIndex] = true;
        return accum;
    }, {});
}

function groupBy(items, key) { // `items` is an array of objects, `key` is the key (or property accessor) to group by
    // reduce runs this anonymous function on each element of `items` (the `item` parameter,
    // returning the `storage` parameter at the end
    return items.reduce(function (storage, item) {
        // get the first instance of the key by which we're grouping
        let group = item[key];

        // set `storage` for this instance of group to the outer scope (if not empty) or initialize it
        storage[group] = storage[group] || [];

        // add this item to its group within `storage`
        storage[group].push(item);

        // return the updated storage to the reduce function, which will then loop through the next
        return storage;
    }, {}); // {} is the initial value of the storage
}


function escapeDangerousCSVCharacters(data) {
    if (typeof data === 'string') {
        // Places single quote before the appearance of dangerous characters if they
        // are the first in the data string.
        return data.replace(/^\+|^-|^=|^@/g, "'$&");
    }

    return data;
}

function warnDeprecated(warning, consoleWarnings = true) {
    let consoleWarn = typeof consoleWarnings === 'function' ? consoleWarnings : console.warn;
    if (consoleWarnings) {
        consoleWarn(`Deprecation Notice:  ${warning}`);
    }
}

function warnInfo(warning, consoleWarnings = true) {
    let consoleWarn = typeof consoleWarnings === 'function' ? consoleWarnings : console.warn;
    if (consoleWarnings) {
        consoleWarn(`${warning}`);
    }
}

function getPageValue(count, rowsPerPage, page) {
    const totalPages = getTotalPages(count, rowsPerPage);

    // `page` is 0-indexed
    return page >= totalPages ? totalPages - 1 : page;
}

function getTotalPages(count, rowsPerPage) {
    return count <= rowsPerPage ? 1 : Math.ceil(count / rowsPerPage);
}

function getCollatorComparator() {
    if (!!Intl) {
        const collator = new Intl.Collator(undefined, {numeric: true, sensitivity: 'base'});
        return collator.compare;
    }

    return (a, b) => a.localeCompare(b);
}

function sortCompare(order) {
    return (a, b) => {
        var aData = a.data === null || typeof a.data === 'undefined' ? '' : a.data;
        var bData = b.data === null || typeof b.data === 'undefined' ? '' : b.data;
        return (
            (typeof aData.localeCompare === 'function' ? aData.localeCompare(bData) : aData - bData) *
            (order === 'asc' ? 1 : -1)
        );
    };
}

function buildCSV(columns, data, options) {
    const replaceDoubleQuoteInString = columnData =>
        typeof columnData === 'string' ? columnData.replace(/"/g, '""') : columnData;

    const buildHead = columns => {
        return (
            columns
                .reduce(
                    (soFar, column) =>
                        column.download
                            ? soFar +
                            '"' +
                            escapeDangerousCSVCharacters(replaceDoubleQuoteInString(column.label || column.name)) +
                            '"' +
                            options.downloadOptions.separator
                            : soFar,
                    '',
                )
                .slice(0, -1) + '\r\n'
        );
    };
    const CSVHead = buildHead(columns);

    const buildBody = data => {
        if (!data.length) return '';
        return data
            .reduce(
                (soFar, row) =>
                    soFar +
                    '"' +
                    row.data
                        .filter((_, index) => columns[index].download)
                        .map(columnData => escapeDangerousCSVCharacters(replaceDoubleQuoteInString(columnData)))
                        .join('"' + options.downloadOptions.separator + '"') +
                    '"\r\n',
                '',
            )
            .trim();
    };
    const CSVBody = buildBody(data);

    return options.onDownload
        ? options.onDownload(buildHead, buildBody, columns, data)
        : `${CSVHead}${CSVBody}`.trim();
}

function downloadCSV(csv, filename) {
    const blob = new Blob([csv], {type: 'text/csv'});

    /* taken from react-csv */
    if (navigator && navigator.msSaveOrOpenBlob) {
        navigator.msSaveOrOpenBlob(blob, filename);
    } else {
        const dataURI = `data:text/csv;charset=utf-8,${csv}`;

        const URL = window.URL || window.webkitURL;
        const downloadURI = typeof URL.createObjectURL === 'undefined' ? dataURI : URL.createObjectURL(blob);

        let link = document.createElement('a');
        link.setAttribute('href', downloadURI);
        link.setAttribute('download', filename);
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
    }
}

function createCSVDownload(columns, data, options, downloadCSV) {
    const csv = buildCSV(columns, data, options);

    if (options.onDownload && csv === false) {
        return;
    }

    downloadCSV(csv, options.downloadOptions.filename);
}

function ccToSC(cc) {
    if (!cc) {
        return cc;
    }
    let sc = cc.replace(/([A-Z])/g, " $1");
    return sc.charAt(0).toUpperCase() + sc.slice(1);
}

function transformData(columns, data, options) {
    const { enableNestedDataAccess } = options.enableNestedDataAccess;
    const leaf = (obj, path) =>
        (enableNestedDataAccess ? path.split(enableNestedDataAccess) : path.split()).reduce(
            (value, el) => (value ? value[el] : undefined),
            obj,
        );

    return Array.isArray(data[0])
        ? data.map(row => {
            let i = -1;

            return columns.map(col => {
                if (!col.empty) i++;
                return col.empty ? undefined : row[i];
            });
        })
        : data.map(row => columns.map(col => leaf(row, col.name)));
}

export {
    buildMap,
    getPageValue,
    getTotalPages,
    getCollatorComparator,
    sortCompare,
    createCSVDownload,
    buildCSV,
    groupBy,
    downloadCSV,
    warnDeprecated,
    warnInfo,
    ccToSC,
    escapeDangerousCSVCharacters,
    transformData,
};