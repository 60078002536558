export class Sort {
    attr: any
    direction: any
}

export class SortOrder {
    name: any;
    direction: any
}

export type RestParams = {
    filters: Filter[]
    sorts: Sort[]
    paging: Paging
    entityType: any
    toolType: any
    idColumnName: any
}

export class Filter {
    attr: string;
    criteria: FilterCriterion[] = []

    constructor(attr: string) {
        this.attr = attr
    }

    addCriteria(comparison: string, value: any) {
        this.criteria.push(new FilterCriterion(comparison, value))
    }
}

export class FilterCriterion {
    comparison: string;
    value: any;

    constructor(comparison: string, value: any) {
        this.comparison = comparison
        this.value      = value
    }


}

export type Drilldown = {
    displayTextColumnName: string | null;
    idColumnName: any;
    group: ToolGroup | string;
}

export type ToolForm = {
    id: any;
    idColumnName: any;
}

export class ColumnOption {
    Name: string
    Value: string
    nullDisplay: string

    constructor(Name: string, Value: any, nullDisplay: string) {
        this.Name        = Name
        this.Value       = Value
        this.nullDisplay = nullDisplay
    }
}

export type ServerColumn = {
    title: string;
    attr: string;
    order: number;
    type: { name: string };
    actualOrder: number;
    description: string | null;
    options: ColumnOption[];
    selectOptions: any[];
    visible: boolean;
    default: any;
}

export type MuiTableColumn = {
    label: string;
    name: string;
    order: number;
    actualOrder: number;
    hint: string;
    testId: string;
    options: MuiTableColumnOptions
    visible: boolean;
}

export type MuiTableColumnOptions = {
    customBodyRender: any
    customBodyRenderLite: any
    customFilterListOptions: any
    customFilterListRender: any
    customHeadLabelRender: any
    customHeadRender: any
    display: boolean | string
    download: boolean
    empty: boolean
    filter: boolean
    filterList: any
    filterOptions: any
    filterType: string
    print: boolean
    searchable: boolean
    selectOptions: any
    setCellHeaderProps: any
    setCellProps: any
    sort: boolean
    sortCompare: any
    sortDescFirst: boolean
    sortThirdClickReset: boolean
    viewColumns: boolean
}


export class Customer {
    name: any;
    id: any;
}

export type Paging = {
    page: number
    rows: number
}

export type PagingInfo = {
    currentPage: number | null
    recordCount: number | null
    totalPages: number | null

}

export type ToolCategory = {
    name: string;
    description: string;
    iconName: string;
    groups: ToolGroup[]
}

export type ToolGroup = {
    name: string
    key: string
    description: any;
    tabs: ToolTab[];
    forms: any,
    customTitle: any;
    gridLayout: any
    parsedGridLayout: any[]
    idColumnName: any;
    detailID: any;
    displayTextColumnName: any;
    error: any;
    isLoading: boolean
    lastUpdated: Date
    searchStr: any;
    selected: boolean
    toolData: any;
    tools: string[]
    parsedTools: Tool[]
    uuid: string
    selectedTabIndex: number;
}

export type ToolTab = {
    customTitle: any;
    gridLayout: any
    parsedGridLayout: any[]
    idColumnName: any;
    detailID: any;
    description: any;
    displayTextColumnName: any;
    error: any;
    isLoading: boolean
    lastUpdated: Date
    name: string
    order: number
    searchStr: any;
    selected: boolean
    toolData: any;
    tools: string[]
    parsedTools: Tool[]
    tabs: ToolTab[] | null
    selectedTabIndex: number
    uuid: string
}

export type Tool = {
    serverSideFiltering: boolean; // Chart server side filtering
    chartType: string | null;                          // Type of chart in chart tools only
    chartTypes: string[]                               // Available chart types in chart tools only
    columns: ServerColumn[] | null                     // Columns for tools
    columnStates: any;                                 // States of the columns in form [[definedOrder, actualOrder, isVisible],[2,3,1],[3,2,0]]
    customTitle: any;                                  // Title to display when the tool is used in a dropdown and the actual displayed title differs from the tool's default title
    datasources: DataSource[] | null;                  // FREEFORM - sources for data
    dbName: any;                                       // Name of the database table/view/procedure that provides data to this tool
    defaultSorts: Sort[] | null                        // Default sorting for a tool (TABLE)
    description: any;                                  // Description of the tool
    detailID: any;                                     // ID for drilldown context
    displayTextColumnName: any;                        // Column containing the custom titling (tool.customTitle = tool.toolData[tool.displayTextColumnName])
    entityType: string | null                          // V, P, or T depending on view/procedure/table. Not needed on UI, but passed to tool route to avoid having to look up metadata
    error: boolean                                     // Whether the last load of the tool resulted in an error
    filterList: any[]                                  // Array of filter arrays in column order used by the MuiDatatables framework [["2019-01-01", "2019-12-31"], ["Jones]]
    forms: ToolForm[] | null                           // Forms for a given tool
    idColumnName: any;                                 // Column that contains a row id in the tool's data
    isLoading: boolean                                 // Whether the tool is loading
    initialized: boolean                               // Whether the tool has loaded at least once
    key: any;                                          // Unique key for the tool. Commonly the tool's dbName
    lastUpdated: Date                                  // Last time the tool's data was reloaded
    layouts: any;                                      // FREEFORM - layouts for displayed elements
    mappedColumns: MuiTableColumn[] | null             // Columns mapped for the MuiDatatable framework
    mermaidWidth: number | null                        // MERMAID - width of view window
    name: string | null                                // Name of the tool - Disambiguate with key/dbName/title/name
    options: { chartOptions: any, url: any } | null    // Options for charts and url for url-only tools
    pagingInfo: PagingInfo | null                      // Current page, record count, total pages
    params: RestParams                                 // Filters, sorts, pagination entityType toolType idColumnName
    refreshInterval: any;                              // How often Tool should refresh on its own
    searchStr: any;                                    // For chart drilldowns I think
    silentReload: boolean                              // When there is an automatic refresh, this tells the system to not show loading circles, unload data, etc.
    sortOrder: SortOrder | null                        // Needed for tool to load sort into table
    title: string | null                               // Title of the tool - Disambiguate with key/dbName/title/name
    toolData: any;                                     // Current dataset from the server
    toolType: string | null                            // Type of the tool
    url: any;                                          // Optional URL for the tool to get data. Overrides default tools path
    uuid: any;                                         // Uniquely generated UUID for the tool
}

export type DataSource = {
    name: string // Unique name for the datasource, used for JSON targeting
    source: string // Tool view specification e.g. RafComparisonToolTableView|SORTS=Factor Type~ASC
    entityType: string | null                          // V, P, or T depending on view/procedure/table. Not needed on UI, but passed to tool route to avoid having to look up metadata
    columns: ServerColumn[] | null                     // Columns for tools
    params: RestParams | null                                // Filters, sorts, pagination entityType toolType idColumnName
    isLoading: boolean | null                                // Whether the tool is loading
    initialized: boolean | null                             // Whether the tool has loaded at least once
    metadata: any | null                               // Metadata from server
    lastUpdated: Date | null                               // Last time the tool's data was reloaded
}


export type StackState = {
    key: any;
    title: any;
    selected: boolean;
    frameStates: FrameState[];
    timestamp: Date;
}

export type FrameState = {
    key: any;
    selected: boolean;
    state: ToolState | GroupState;
    type: 'TOOL' | 'GROUP'
    idColumnName: any;
    detailID: any;
    searchStr: any;
    timestamp: Date;
}

export type GroupState = {
    name: any;
    key: any;
    selected: boolean;
    selectedTabIndex: number;
    customTitle: string | null;
    idColumnName: any;
    detailID: any;
    searchStr: string | null;
    parsedGridLayout: any[]
    toolStates: ToolState[] | null | undefined;
    tabStates: TabState[] | null | undefined;
    timestamp: Date;
    forms: any;
}

export type TabState = {
    name: any;
    selected: boolean;
    customTitle: string | null;
    idColumnName: any;
    detailID: any;
    searchStr: string | null;
    selectedTabIndex: number;
    parsedGridLayout: any[]
    toolStates: ToolState[] | null;
    tabStates: TabState[] | null;
    timestamp: Date;
}

export type ToolState = {
    datasources: any;
    layouts: any;
    key: any;
    toolType: any;
    entityType: any;
    customTitle: string
    columnStates: number[][] | null
    mermaidWidth: number | null
    refreshInterval: number
    filterList: any
    filters: Filter[]
    sorts: Sort[]
    paging: Paging
    idColumnName: any;
    detailID: any;
    searchStr: string
    timestamp: Date;
}

// @ts-ignore
export type StackFrame = {
    uuid: string;
    toolOrGroup: Tool | ToolGroup;
    idColumnName: any;
    detailID: any;
    searchStr: any;
    type: 'TOOL' | 'GROUP';
    selected: boolean;
    lastUpdated: Date;
}

export type ToolStack = {
    key: any;
    uuid: any;
    title: string;
    toolCategoryName: any;
    stackFrames: StackFrame[];
    selected: boolean;
    lastUpdated: Date;
}