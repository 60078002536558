import {startCase} from "lodash";

function getClosestWsIndex(s: string, index: number) {
    let whitespaceIndex = -1

    for (let i = 0; i < s.length; i++) {
        let char = s.charAt(i)
        if (char === ' ') {
            let distance    = Math.abs(index - i)
            let currentDist = Math.abs(index - whitespaceIndex)

            if (distance < currentDist) {
                whitespaceIndex = i
            }
        }

    }
    return whitespaceIndex;
}

export default function getColumnLabelString(s: string, maxWidth: number = 20) {

    if (!s.includes(" ") && !s.includes("-") && !s.includes("/")) {
        s = startCase(s)
    }

    if (s.length > maxWidth) {
        const halfWidth     = s.length / 2;
        const oneThirdWidth = s.length / 3;
        const twoThirdWidth = oneThirdWidth * 2;

        const count = (s.match(/ /g) || []).length;


        if (halfWidth > maxWidth && count > 1) {
            // three lines
            let whitespaceIndex1 = getClosestWsIndex(s, oneThirdWidth);
            let whitespaceIndex2 = getClosestWsIndex(s, twoThirdWidth);

            let line1 = s.substring(0, whitespaceIndex1)
            let line2 = s.substring(whitespaceIndex1 + 1, whitespaceIndex2)
            let line3 = s.substring(whitespaceIndex2 + 1)
            return line1 + '\n' + line2 + '\n' + line3

        } else if (count > 0) {
            // two lines
            let whitespaceIndex = getClosestWsIndex(s, halfWidth);

            let line1 = s.substring(0, whitespaceIndex)
            let line2 = s.substring(whitespaceIndex + 1)
            return line1 + "\n" + line2
        }
    }

    return s
}