import {createTheme, ThemeOptions} from "@mui/material/styles";
import Color from "color";
import {KeyboardArrowDown} from "@mui/icons-material";


// /* CSS HEX */
// --teal-blue: #2d728fff;
// --platinum: #ebe9e9ff;
// --dark-sienna: #220c10ff;
// --cadmium-orange: #f28f3bff;
// --alice-blue: #e8f0ffff;
// --alice-blue: #5065a8;
// --alice-blue: #cdb000;
// export const themeColor1    = Color("#183b5a") TODO: different dark blue?
export const themeColor3     = Color("#dfa840")
export const themeColor4     = Color("#dc6427")
export const themeColor5     = Color("#92c771")
export const whiteColor      = Color("#fdfdfd")
export const trueWhite       = Color("#fff")
export const purple          = Color("#9900ff")
export const trueBlack       = Color("#000")
export const blackColor      = Color("#0e0e0e")
export const darkGray        = Color("#555555")
export const medGray         = Color("#BBBBBB")
export const lightGray       = Color("#CACCD0")
export const reallylightGray = Color("#fafafa")
export const redColor        = Color("#FA1111")

export let themeColor1: Color
export let themeColor2: Color
export let sidebarBgColor: Color
export let sidebarTxColor: Color
export let sidebarBackgroundHoverColor: Color
export let sidebarSelectedBackgroundColor: Color
export let sidebarSelectedBackgroundHoverColor: Color
export let sidebarTextHoverColor: Color
export let sidebarSelectedTextColor: Color
export let sidebarSelectedTextHoverColor: Color
export let loginPageBgColor: Color
export let loginPanelBgColor: Color
export let signInButtonBgColor: Color
export let signInButtonTxColor: Color
export let dbLoadingModalBgColor: Color
export let dbLoadingModalTxColor: Color
export let dbLoadingModalSpinnerColor: Color
export let tabTxColor: Color
export let tabBgColor: Color
export let tabSelectedTxColor: Color
export let tabSelectedBgColor: Color
export let tabHoverTxColor: Color
export let tabHoverBgColor: Color
export let tabSelectedHoverTxColor: Color
export let tabSelectedHoverBgColor: Color
export let tabIndicatorColor: Color


switch (window.location.host) {
    case 'localhost:3000':
        themeColor1                         = Color("#ad0000")
        themeColor2                         = Color("#358400")
        sidebarBgColor                      = trueWhite
        sidebarTxColor                      = Color("#ad0000")
        sidebarBackgroundHoverColor         = sidebarTxColor
        sidebarSelectedBackgroundColor      = sidebarTxColor.lighten(0.3).whiten(0.5)
        sidebarSelectedBackgroundHoverColor = sidebarTxColor
        sidebarTextHoverColor               = whiteColor
        sidebarSelectedTextColor            = blackColor
        sidebarSelectedTextHoverColor       = whiteColor
        loginPageBgColor                    = trueWhite
        loginPanelBgColor                   = trueWhite
        signInButtonBgColor                 = themeColor1
        signInButtonTxColor                 = whiteColor
        dbLoadingModalBgColor               = reallylightGray
        dbLoadingModalTxColor               = themeColor1
        dbLoadingModalSpinnerColor          = themeColor2
        tabTxColor                          = blackColor.fade(0.4)
        tabBgColor                          = whiteColor
        tabHoverTxColor                     = themeColor2
        tabHoverBgColor                     = Color("#d0ffb4")
        tabSelectedTxColor                  = whiteColor
        tabSelectedBgColor                  = themeColor2
        tabSelectedHoverTxColor             = themeColor2
        tabSelectedHoverBgColor             = Color("#d0ffb4")
        tabIndicatorColor                   = themeColor2
        break
    case 'demo.lionhillsusa.com':
        themeColor1                         = Color("#223a56") // lh
        themeColor2                         = Color("#175CD3") // lh
        sidebarBgColor                      = themeColor1 // lh
        sidebarTxColor                      = trueWhite // lh
        sidebarBackgroundHoverColor         = sidebarBgColor.lighten(0.3).whiten(0.5) // lh
        sidebarSelectedBackgroundColor      = whiteColor // lh
        sidebarSelectedBackgroundHoverColor = whiteColor // lh
        sidebarTextHoverColor               = trueWhite // lh
        sidebarSelectedTextColor            = trueBlack // lh
        sidebarSelectedTextHoverColor       = trueBlack // lh
        loginPageBgColor                    = themeColor1 // lh
        loginPanelBgColor                   = whiteColor // lh
        signInButtonBgColor                 = themeColor1 // lh
        signInButtonTxColor                 = whiteColor // lh
        dbLoadingModalBgColor               = themeColor1 // lh
        dbLoadingModalTxColor               = trueWhite // lh
        dbLoadingModalSpinnerColor          = trueWhite // lh
        tabTxColor                          = blackColor.fade(0.4)
        tabSelectedBgColor                  = themeColor2
        tabHoverBgColor                     = Color("#EFF8FF")
        tabSelectedHoverTxColor             = themeColor2
        tabIndicatorColor                   = themeColor2
        tabBgColor                          = trueWhite
        tabSelectedTxColor                  = Color("#EFF8FF")
        tabHoverTxColor                     = themeColor2
        tabSelectedHoverBgColor             = Color("#EFF8FF")
        break
    case 'iris.intus.care':
        themeColor1                         = Color("#133793") // intus
        themeColor2                         = Color("#3869e8") // intus
        sidebarBgColor                      = reallylightGray // intus
        sidebarTxColor                      = blackColor // intus
        sidebarBackgroundHoverColor         = themeColor2 // intus
        sidebarSelectedBackgroundColor      = themeColor2.lighten(0.3).whiten(0.5) // intus
        sidebarSelectedBackgroundHoverColor = themeColor2 // intus
        sidebarTextHoverColor               = whiteColor // intus
        sidebarSelectedTextColor            = blackColor // intus
        sidebarSelectedTextHoverColor       = whiteColor // intus
        loginPageBgColor                    = trueWhite
        loginPanelBgColor                   = trueWhite
        signInButtonBgColor                 = themeColor1 // intus
        signInButtonTxColor                 = whiteColor // intus
        dbLoadingModalBgColor               = reallylightGray // intus
        dbLoadingModalTxColor               = themeColor1 // intus
        dbLoadingModalSpinnerColor          = themeColor2 // intus
        tabTxColor                          = blackColor.fade(0.4)
        tabSelectedBgColor                  = themeColor2
        tabHoverBgColor                     = Color("#EFF8FF")
        tabSelectedHoverTxColor             = themeColor2
        tabIndicatorColor                   = themeColor2
        tabBgColor                          = trueWhite
        tabSelectedTxColor                  = Color("#EFF8FF")
        tabHoverTxColor                     = themeColor2
        tabSelectedHoverBgColor             = Color("#EFF8FF")
        break
    default:
        themeColor1                         = Color("#223a56") // lh
        themeColor2                         = Color("#2e9692") // lh
        sidebarBgColor                      = themeColor1 // lh
        sidebarTxColor                      = trueWhite // lh
        sidebarBackgroundHoverColor         = sidebarBgColor.lighten(0.3).whiten(0.5) // lh
        sidebarSelectedBackgroundColor      = whiteColor // lh
        sidebarSelectedBackgroundHoverColor = whiteColor // lh
        sidebarTextHoverColor               = trueWhite // lh
        sidebarSelectedTextColor            = trueBlack // lh
        sidebarSelectedTextHoverColor       = trueBlack // lh
        loginPageBgColor                    = themeColor1 // lh
        loginPanelBgColor                   = whiteColor // lh
        signInButtonBgColor                 = themeColor1 // lh
        signInButtonTxColor                 = whiteColor // lh
        dbLoadingModalBgColor               = themeColor1 // lh
        dbLoadingModalTxColor               = trueWhite // lh
        dbLoadingModalSpinnerColor          = trueWhite // lh
        tabTxColor                          = blackColor.fade(0.4)
        tabSelectedBgColor                  = themeColor2
        tabHoverBgColor                     = Color("#EFF8FF")
        tabSelectedHoverTxColor             = themeColor2
        tabIndicatorColor                   = themeColor2
        tabBgColor                          = trueWhite
        tabSelectedTxColor                  = Color("#EFF8FF")
        tabHoverTxColor                     = themeColor2
        tabSelectedHoverBgColor             = Color("#EFF8FF")
}

export function getColorFromText(text: string) {
    switch (text) {
        case 'themeColor1':
            return themeColor1.hexa();
        case 'themeColor2':
            return themeColor2.hexa();
        case 'sidebarBgColor':
            return sidebarBgColor.hexa();
        case 'sidebarTxColor':
            return sidebarTxColor.hexa();
        case 'sidebarBackgroundHoverColor':
            return sidebarBackgroundHoverColor.hexa();
        case 'sidebarSelectedBackgroundColor':
            return sidebarSelectedBackgroundColor.hexa();
        case 'sidebarSelectedBackgroundHoverColor':
            return sidebarSelectedBackgroundHoverColor.hexa();
        case 'sidebarTextHoverColor':
            return sidebarTextHoverColor.hexa();
        case 'sidebarSelectedTextColor':
            return sidebarSelectedTextColor.hexa();
        case 'sidebarSelectedTextHoverColor':
            return sidebarSelectedTextHoverColor.hexa();
        case 'loginPageBgColor':
            return loginPageBgColor.hexa();
        case 'loginPanelBgColor':
            return loginPanelBgColor.hexa();
        case 'signInButtonBgColor':
            return signInButtonBgColor.hexa();
        case 'signInButtonTxColor':
            return signInButtonTxColor.hexa();
        case 'dbLoadingModalBgColor':
            return dbLoadingModalBgColor.hexa();
        case 'dbLoadingModalTxColor':
            return dbLoadingModalTxColor.hexa();
        case 'dbLoadingModalSpinnerColor':
            return dbLoadingModalSpinnerColor.hexa();
        case 'tabTxColor':
            return tabTxColor.hexa();
        case 'tabSelectedBgColor':
            return tabSelectedBgColor.hexa();
        case 'tabHoverBgColor':
            return tabHoverBgColor.hexa();
        case 'tabSelectedHoverTxColor':
            return tabSelectedHoverTxColor.hexa();
        case 'tabIndicatorColor':
            return tabIndicatorColor.hexa();
        case 'tabBgColor':
            return tabBgColor.hexa();
        case 'tabSelectedTxColor':
            return tabSelectedTxColor.hexa();
        case 'tabHoverTxColor':
            return tabHoverTxColor.hexa();
        case 'tabSelectedHoverBgColor':
            return tabSelectedHoverBgColor.hexa();
        default:
            return text
    }


}

export const toolTipColor          = darkGray
export const stateRefreshIconColor = medGray

export const secondaryColor = themeColor1

export const formFieldsetLegendTextColor = themeColor2.darken(0.5)
export const secondaryLightColor         = secondaryColor.lighten(0.5)
export const secondaryDarkColor          = secondaryColor.darken(0.5)
export const errorColor                  = redColor

export const dragHandleColor         = lightGray
export const dragHandleColorHover    = Color('#8c00ff')
export const progressSpinnerColor    = themeColor1
export const formFieldsetBorderColor = themeColor1
export const infoButtonColor         = themeColor2
export const signOutButtonColor      = themeColor2
export const refreshButtonColor      = lightGray

export const closeButtonTextColor = themeColor3

export const jsonColor1 = themeColor1
export const jsonColor2 = themeColor2
export const jsonColor3 = themeColor3
export const jsonColor4 = themeColor4
export const jsonColor5 = themeColor5

export const accordionDetailBgColor = themeColor1

export const chipBGColor             = themeColor1.fade(0.75)
export const chipBorderColor         = themeColor1
export const chipCloseIconColor      = themeColor1
export const chipCloseIconHoverColor = themeColor1.darken(0.5)

export const textFont    = `"Inter", "Times New Roman", "Arial", sans-serif`
export const displayFont = `"Inter", "Times New Roman", "Arial", sans-serif`


export const sidebarFontSize   = "15px"
export const sidebarFontWeight = 400
export const iconColor         = themeColor2
export const logoColor         = themeColor2
export const appBarTextColor   = blackColor.hexa()
export const appBarBGColor     = whiteColor.hexa()

export const tableHeaderBGColor     = Color("#F8F8F8")
export const tableHeaderBorderColor = Color("#ddd")
export const inputUnderlineColor    = themeColor2.hexa()

export const chartFilterBgColor    = trueWhite
export const chartContainerColor   = trueWhite
export const chartChartTypeBgColor = trueWhite
export const chartBgColor          = trueWhite

export const DEFAULT_CHART_COLORS = [
    "#3366CC",
    "#DC3912",
    "#FF9900",
    "#109618",
    "#990099",
    "#3B3EAC",
    "#0099C6",
    "#DD4477",
    "#66AA00",
    "#B82E2E",
    "#316395",
    "#994499",
    "#22AA99",
    "#AAAA11",
    "#6633CC",
    "#E67300",
    "#8B0707",
    "#329262",
    "#5574A6",
    "#3B3EAC"
];


export default function getMuiTheme(props: { sideBarWidth?: number; }) {
    const themeOptions = {
        typography: {
            "fontFamily"       : textFont,
            "fontSize"         : 13,
            "lineHeight"       : 1.43,
            "fontWeightLight"  : 300,
            "fontWeightRegular": 400,
            "fontWeightMedium" : 500,
            "fontWeightBold"   : 500,
        },
        palette   : {
            primary  : {
                main : themeColor1.hexa(),
                light: themeColor1.lighten(0.5).hexa(),
                dark : themeColor1.darken(0.5).hexa(),
                contrastText: whiteColor.hexa()
            },
            secondary: {
                main        : secondaryColor.hexa(),
                light       : secondaryLightColor.hexa(),
                dark        : secondaryDarkColor.hexa(),
                contrastText: whiteColor.hexa()
            }
        },
        components: {
            MuiCssBaseline        : {
                styleOverrides: (themeParam: any) => ({
                    body: {
                        lineHeight   : 1.43,
                        letterSpacing: 0,
                        fontWeight   : 500,
                        fontSize     : "14px"
                    }
                }),
            },
            MuiAccordionSummary   : {
                styleOverrides: {
                    root   : {
                        '&.Mui-expanded': {
                            minHeight: 'inherit'
                        }
                    },
                    content: {
                        '&.Mui-expanded': {
                            margin : '12px 0',
                            padding: "1px 0 0 0 "
                        }
                    }
                }
            },
            MuiAppBar             : {
                styleOverrides: {
                    colorPrimary: {
                        backgroundColor: appBarBGColor,
                        color          : appBarTextColor
                    }
                }
            },
            MuiTextField          : {
                styleOverrides: {
                    root: {
                        width  : "100%",
                        padding: "0 !important",
                        input  : {
                            padding : "10px",
                            fontSize: "14px"
                        }
                    },
                }
            },
            MuiOutlinedInput      : {
                styleOverrides: {
                    notchedOutline: {
                        top       : 0,
                        '& legend': {
                            display: "none"
                        }
                    }
                }
            },
            MuiBadge              : {
                styleOverrides: {
                    colorPrimary: {
                        backgroundColor: "#fff",
                        color          : blackColor.hexa()
                    }
                }
            },
            MuiChip               : {
                styleOverrides: {
                    root      : {
                        backgroundColor: chipBGColor.hexa(),
                        border         : '2px solid ' + chipBorderColor.hexa(),
                        marginBottom   : '5px'
                    },
                    deleteIcon: {
                        color    : chipCloseIconColor.hexa(),
                        '&:hover': {
                            color: chipCloseIconHoverColor.hexa()
                        },

                    }
                }
            },
            MUIDataTableFilter    : {
                styleOverrides: {
                    title : {
                        color: blackColor.hexa()
                    },
                    root  : {
                        backgroundColor       : whiteColor.darken(0.03).hexa(),
                        "& .MuiGrid-container": {
                            maxHeight   : "500px",
                            overflowY   : "scroll",
                            overflowX   : "hidden",
                            paddingRight: "32px"
                        },
                    },
                    header: {
                        height: "48px"
                    }
                }
            },
            MuiCheckbox           : {
                styleOverrides: {
                    root: {
                        padding: "5px 9px"
                    }
                }
            },
            MUIDataTableFilterList: {
                styleOverrides: {
                    root: {
                        marginBottom: '5px'
                    }
                }
            },
            MUIDataTableBodyCell  : {
                styleOverrides: {
                    root: {
                        padding   : "6px 8px",
                        margin    : '0 !important',
                        whiteSpace: 'pre'
                    }
                }
            },
            MUIDataTableHeadCell  : {
                styleOverrides: {
                    dragCursor: {
                        padding: "0 0 0 10px"
                    },
                    data      : {
                        whiteSpace: 'pre',
                        color     : blackColor.hexa(),
                        lineHeight: "16px",
                        padding   : "0 10px 0 10px",

                    }
                },
            },
            MuiTooltip            : {
                styleOverrides: {
                    tooltip: {
                        maxWidth: "600px"
                    }
                }
            },
            MUIDataTableSelectCell: {
                styleOverrides: {
                    headerCell: {
                        backgroundColor: tableHeaderBGColor,
                    }
                }
            },
            MUIDataTableToolbar   : {
                styleOverrides: {
                    root: {
                        minHeight: "32px",
                        padding  : "0 16px"
                    },
                    icon: {
                        color    : lightGray.hexa(),
                        '&:hover': {
                            color          : purple.hexa(),
                            backgroundColor: "inherit"
                        },
                    },
                },
            },
            MuiDivider            : {
                styleOverrides: {
                    root: {
                        backgroundColor: themeColor2.fade(0.5).hexa(),
                        height         : "2px"
                    }
                }
            },
            MuiDrawer             : {
                styleOverrides: {
                    paper: {
                        zIndex: "1099"
                    }
                }
            },
            MuiFormControl        : {
                defaultProps: {
                    fullWidth: true,
                    size     : "small"
                },
            },
            MuiFormLabel          : {
                defaultProps  : {
                    shrink: 'false'
                },
                styleOverrides: {
                    root: {
                        fontSize  : "14px",
                        marginLeft: "-5px",
                        fontWeight: "500",
                        color     : blackColor.fade(0.1).hexa(),
                        // "&.MuiInputLabel-root": {
                        //     fontSize: "120px !important"
                        //     }
                    }
                }
            },
            MuiIcon               : {
                styleOverrides: {
                    root: {
                        color: iconColor.hexa()
                    }
                }
            },
            MuiIconButton         : {
                styleOverrides: {
                    root: {
                        padding  : "12px",
                        color    : lightGray.hexa(),
                        '&:hover': {
                            color          : purple.hexa(),
                            backgroundColor: "inherit"
                        },


                    },
                }
            },
            MuiInputLabel         : {
                defaultProps  : {
                    shrink: false,
                },
                styleOverrides: {
                    root: {
                        fontSize  : "14px",
                        marginTop : "-35px",
                        marginLeft: "-14px"
                    }
                }
            },
            MuiInput              : {
                styleOverrides: {
                    underline: {
                        '&:before': {
                            // borderBottom: '2px solid ' + inputUnderlineColor,
                        }
                    }
                }
            },
            MuiRadio              : {
                styleOverrides: {
                    root: {
                        padding: "2px"
                    },
                },
            },
            MuiSelect             : {
                defaultProps  : {
                    IconComponent: KeyboardArrowDown
                },
                styleOverrides: {
                    root: {
                        borderRadius: "8px",
                        fontSize    : "13px",
                    }
                }
            },
            MuiList               : {
                styleOverrides: {
                    root   : {
                        width: "100%",
                    },
                    padding: {
                        // paddingTop: 0,
                        // paddingBottom: 0
                    }
                }
            },
            MuiTableCell          : {
                styleOverrides: {
                    root: {
                        padding   : "5px 10px",
                        whiteSpace: "nowrap",
                        // "& .MuiFormLabel-root"  : {
                        //     display: "none"
                        // },
                        "& .MuiFormControl-root": {
                            margin: "0"
                        }
                    },
                    body: {
                        color: blackColor.hexa(),
                    }
                },
            },
            MuiTableHead          : {
                styleOverrides: {
                    root: {
                        borderBottom: '1.5px solid ' + tableHeaderBorderColor,
                    }
                }
            },
            MuiTablePagination    : {
                styleOverrides: {
                    root         : {
                        minHeight   : "20px",
                        borderBottom: "0 !important",
                        marginLeft  : "10px",
                    },
                    toolbar      : {
                        minHeight  : "20px !important",
                        paddingLeft: "0 !important",
                        margin     : "0 !important"
                    },
                    spacer       : {
                        flex: "0 0 0"
                    },
                    actions      : {
                        "& button": {
                            padding: "0",
                        }
                    },
                    selectLabel  : {
                        fontSize  : "14px !important",
                        fontWeight: "500 !important",
                        margin    : "0"
                    },
                    displayedRows: {
                        fontSize  : "14px",
                        fontWeight: "400",
                        margin    : "0"
                    }
                }
            },
            MuiTableRow           : {
                styleOverrides: {
                    hover: {
                        '&$hover:hover': {
                            backgroundColor: themeColor2.fade(0.75).hexa(),
                        },
                    }
                }
            },
            MuiToolbar            : {
                root: {}
            },
            MuiTouchRipple        : {
                styleOverrides: {

                    rippleVisible: {
                        color            : themeColor2.hexa(),
                        animationDuration: "300ms"
                    }
                }
            },
        }
    };
    return createTheme(themeOptions as ThemeOptions)
}