import {makeStyles} from 'tss-react/mui';
import clsx from 'clsx';
import MuiTableHead from '@mui/material/TableHead';
import React, {useEffect, useState} from 'react';
import TableHeadCell from './TableHeadCell';
import TableHeadRow from './TableHeadRow';
import TableSelectCell from './TableSelectCell';
import cloneDeep from "lodash.clonedeep";
import _ from "lodash";
import PropTypes from "prop-types";

const useStyles = makeStyles({name: 'MUIDataTableHead'})(theme => ({
    main                   : {},
    responsiveStacked      : {
        [theme.breakpoints.down('md')]: {
            display: 'none',
        },
    },
    responsiveStackedAlways: {
        display: 'none',
    },
    responsiveSimple       : {
        [theme.breakpoints.down('sm')]: {
            display: 'none',
        },
    },
}));

export default function TableHead(props: any) {

    const {classes} = useStyles();

    const components = props.components === undefined ? {} : props.components;
    const sortOrder  = props.sortOrder === undefined ? {} : props.sortOrder;

    let columnOrder = props.columnOrder === undefined ? null : props.columnOrder;

    if (columnOrder === null) {
        columnOrder = props.columns ? props.columns.map((item: any, idx: number) => idx) : [];
    }

    const [dragging, setDragging] = useState(false);
    const [state, setState]       = useState({filterList: cloneDeep(props.filterList)})

    useEffect(() => {
        setState({filterList: props.filterList})
    }, [props.filterList])

    const handleToggleColumn = (index: number) => props.toggleSort(index);
    const handleRowSelect    = () => props.selectRowUpdate('head', null);
    const numSelected        = (props.selectedRows && props.selectedRows.data.length) || 0;

    let isIndeterminate = numSelected > 0 && numSelected < props.count;
    let isChecked       = numSelected > 0 && numSelected >= props.count;

    // When the disableToolbarSelect option is true, there can be
    // selected items that aren't visible, so we need to be more
    // precise when determining if the head checkbox should be checked.
    if (
        props.options.disableToolbarSelect === true ||
        props.options.selectToolbarPlacement === 'none' ||
        props.options.selectToolbarPlacement === 'above'
    ) {
        if (isChecked) {
            for (let ii = 0; ii < props.data.length; ii++) {
                if (!props.selectedRows.lookup[(props.data)[ii].dataIndex]) {
                    isChecked       = false;
                    isIndeterminate = true;
                    break;
                }
            }
        } else {
            if (numSelected > props.count) {
                isIndeterminate = true;
            }
        }
    }

    let orderedColumns = columnOrder.map((colIdx: number, idx: number) => ({
        column: (props.columns)[colIdx],
        index : colIdx,
        colPos: idx
    }));

    const headClassName = clsx({
        [classes.responsiveStacked]      : props.options.responsive === 'vertical' || props.options.responsive === 'stacked' || props.options.responsive === 'stackedFullWidth',
        [classes.responsiveStackedAlways]: props.options.responsive === 'verticalAlways',
        [classes.responsiveSimple]       : props.options.responsive === 'simple',
        [classes.main]                   : true,
    });

    function handleFilterChange(value: any, index: number, column: any) {
        filterUpdate(index, value, column.name, column.filterType);
        // props.filterUpdate(index, value, column.name, column.filterType);
    }

    function filterUpdate(index: number, value: any, column: any, type: any, customUpdate: any = null) {
        let newFilterList = state.filterList.slice(0);

        updateFilterByType(newFilterList, index, value, type, customUpdate);
        setState({
            filterList: newFilterList,
        });
    }

    function applyFilters() {
        state.filterList.forEach((filter: any, index: number) => {
            props.filterUpdate(index, filter, props.columns[index], 'custom');
        });

        // props.handleClose(); // close filter dialog popover

        // if (props.options.onFilterConfirm) {
        //     props.options.onFilterConfirm(state.filterList);
        // }

        return state.filterList;
    }

    function updateFilterByType(filterList: any[], index: number, value: any, type: any, customUpdate: any) {
        const filterPos = filterList[index].findIndex((filter: any) => _.isEqual(filter, value));

        if (customUpdate) {
            filterList = customUpdate(filterList, filterPos, index);
        } else {
            filterList[index] = value;
        }
    }

    function resetFilters() {
        setState({filterList: props.columns.map(() => []),});
        props.filterReset();
    }

    return <MuiTableHead className={headClassName}>

        <TableHeadRow>
            {/* Row select */}

            <TableSelectCell
                setHeadCellRef={props.setCellRef}
                onChange={handleRowSelect.bind(null)}
                indeterminate={isIndeterminate}
                checked={isChecked}
                isHeaderCell={true}
                expandedRows={props.expandedRows}
                expandableRowsHeader={props.options.expandableRowsHeader}
                expandableOn={props.options.expandableRows}
                selectableOn={props.options.selectableRows}
                fixedHeader={props.options.fixedHeader}
                fixedSelectColumn={props.options.fixedSelectColumn}
                selectableRowsHeader={props.options.selectableRowsHeader}
                selectableRowsHideCheckboxes={props.options.selectableRowsHideCheckboxes}
                onExpand={props.toggleAllExpandableRows}
                isRowSelectable={true}
                components={components}
                hideExpandButton={true}
                dataIndex={undefined}/>

            {/* Column Headers */}

            {orderedColumns.map(({column, index, colPos}: any) =>
                column.display === 'true' &&
                (column.customHeadRender ? column.customHeadRender({index, ...column}, handleToggleColumn, sortOrder) :
                    <TableHeadCell
                        cellHeaderProps={
                            (props.columns)[index].setCellHeaderProps ? (props.columns)[index].setCellHeaderProps({index, ...column}) || {} : {}
                        }
                        key={index}
                        index={index}
                        colPosition={colPos}
                        setCellRef={props.setCellRef}
                        sort={column.sort}
                        sortDirection={column.name === sortOrder.name ? sortOrder.direction : 'none'}
                        toggleSort={handleToggleColumn}
                        hint={column.hint}
                        resetFilters={resetFilters}
                        print={column.print}
                        options={props.options}
                        column={column}
                        columns={props.columns}
                        onFilterChange={handleFilterChange}
                        updateColumnOrder={props.updateColumnOrder}
                        columnOrder={columnOrder}
                        timers={props.timers}
                        applyFilters={applyFilters}
                        filterList={props.filterList}
                        tableName={props.tableName}
                        draggingHook={[dragging, setDragging]}
                        draggableHeadCellRefs={props.draggableHeadCellRefs}
                        tableRef={props.tableRef}
                        tableId={props.tableId}
                        components={components}>
                        {column.customHeadLabelRender
                            ? column.customHeadLabelRender({index, colPos, ...column})
                            : column.label}
                    </TableHeadCell>),
            )}
            {/* Blank final cell */}
            <TableHeadCell options={props.options}
                           toggleSort={() => null}
                           sort={false}
                           filterList={props.filterList}
                           resetFilters={resetFilters}
                           tableName={props.tableName}
                           onFilterChange={handleFilterChange}
                           print={false}
                           columns={undefined}
                           children={undefined}
                           applyFilters={applyFilters}
                           index={undefined}
                           colPosition={undefined}
                           draggableHeadCellRefs={undefined}
                           draggingHook={undefined}
                           setCellRef={undefined}
                           tableRef={undefined}
                           tableId={undefined}
                           timers={undefined}
                           updateColumnOrder={undefined}/>
        </TableHeadRow>
    </MuiTableHead>;
}
TableHead.propTypes = {
    columnOrder            : PropTypes.any,
    columns                : PropTypes.any,
    components             : PropTypes.any,
    count                  : PropTypes.any,
    data                   : PropTypes.any,
    draggableHeadCellRefs  : PropTypes.any,
    expandedRows           : PropTypes.any,
    filterList             : PropTypes.any,
    filterReset            : PropTypes.func.isRequired,
    filterUpdate           : PropTypes.func.isRequired,
    options                : PropTypes.any,
    selectRowUpdate        : PropTypes.any,
    selectedRows           : PropTypes.any,
    setCellRef             : PropTypes.any,
    sortOrder              : PropTypes.any,
    tableId                : PropTypes.any,
    tableName              : PropTypes.any,
    tableRef               : PropTypes.any,
    timers                 : PropTypes.any,
    toggleAllExpandableRows: PropTypes.any,
    toggleSort             : PropTypes.any,
    updateColumnOrder      : PropTypes.any

}