import * as React from 'react';
import {useState} from 'react';

import {Accordion, AccordionDetails, AccordionSummary, Theme, Typography} from '@mui/material';
import {ExpandMore} from '@mui/icons-material';
import {makeStyles} from "@mui/styles";
import {Trash} from "react-feather";

const useStyles = makeStyles(
    // @ts-ignore
    (theme: Theme) => ({
        heading: {
            fontSize: theme.typography.pxToRem(15)
        }
    }));

export default function Expansion({
                                      title = '',
                                      description = '',
                                      className = '',
                                      columnsOverride,
                                      isBaselineAlign,
                                      sx,
                                      onDelete,
                                      containerStyles = {},
                                      headerStyles = {},
                                      bodyStyles = {},
                                      style,
                                      children,
                                      expansionOpened,
                                  }: any) {
    const classes                 = useStyles();
    const [expanded, setExpanded] = useState(expansionOpened);


    return (
        <Accordion sx={{...sx, ...containerStyles}}
                   expanded={expanded}
                   onChange={(e) => {
                       console.log("Expansion", e)
                       setExpanded(!expanded);
                   }}
                   className={className}
                   style={style}>
            <AccordionSummary sx={{...headerStyles, pointerEvents: 'all'}} expandIcon={<ExpandMore/>}>
                {title && <Typography className={classes.heading}>{title}</Typography>}
                {onDelete && <Trash onClick={(e: any) => {
                    e.stopPropagation()
                    console.log(e);
                    onDelete()
                }}/>}
            </AccordionSummary>
            <AccordionDetails sx={bodyStyles}>
                {children}
            </AccordionDetails>
        </Accordion>
    );
};