import React from "react";
import DrillDownAccordion from "./DrillDownAccordion";
import {getFormattedKey} from "./DrillDownFormattingUtils";

export default function DrillDownList(props) {

    let tableRows = props.tableRows
    let key = props.name
    let depth = props.depth

    return <DrillDownAccordion key={getFormattedKey(key)}
                               defaultExpanded={true}
                               summarytext={`${getFormattedKey(key)} (${tableRows.length})`}
                               depth={depth}>
        {tableRows.map((row, i) => <div key={getFormattedKey(key) + i}>{i + 1}: "{row}"</div>)}
    </DrillDownAccordion>


}