import React from "react";
import {IconButton, Tooltip} from "@mui/material";
import {withStyles} from "@mui/styles";
import {Close} from "@mui/icons-material";
import {infoButtonColor} from "../../styles/getMuiTheme";
import PropTypes from "prop-types";

const HtmlTooltip = withStyles((theme) => ({
    tooltip: {
        backgroundColor: '#f5f5f9 !important',
        color          : 'rgba(0, 0, 0, 0.87) !important',
        maxWidth       : 600,
        maxHeight      : "95vh",
        overflow       : "auto",
        fontSize       : "14px !important",
        border         : '1px solid #dadde9 !important',
    },
}))(Tooltip);

export const IconToolTip = (props: any) => {

    const [open, setOpen]    = React.useState(false);
    const handleTooltipClose = () => setOpen(false);
    const handleTooltipOpen  = () => setOpen(true);
    const toggleHintTooltip  = () => setOpen(!open)

    return (
        <HtmlTooltip
            onClose={handleTooltipClose}
            open={open}
            disableFocusListener
            disableHoverListener
            disableTouchListener
            title={<>
                <IconButton style={{float: "right"}}
                            onClick={handleTooltipClose}
                            title={"Click to close"}>
                    <Close/>
                </IconButton>
                {props.children}
            </>}>
            <div>
                <Tooltip title={props.title} arrow={props.arrow} placement={props.placement} PopperProps={props.PopperProps}>
                    <IconButton style={{color: (open ? infoButtonColor.hexa() : "#aaa"), ...props.classes?.IconButton}}
                                onClick={toggleHintTooltip}>
                        {props.iconButton}
                    </IconButton>
                </Tooltip>
            </div>
        </HtmlTooltip>
    );
}

IconToolTip.propTypes = {
    classes   : PropTypes.object,
    children  : PropTypes.any,
    title: PropTypes.any,
    iconButton: PropTypes.any,
    arrow: PropTypes.bool,
    placement: PropTypes.string,
    PopperProps: PropTypes.object,
}