import {makeStyles} from 'tss-react/mui';
import PropTypes from 'prop-types';
import React from 'react';
import TableFilterListItem from './TableFilterListItem';

const useStyles = makeStyles({name: 'MUIDataTableFilterList'})(() => ({
    root: {
        display       : 'flex',
        justifyContent: 'left',
        flexWrap      : 'wrap',
        margin        : '0px 16px 0px 16px',
    },
    chip: {
        margin: '8px 8px 0px 0px',
    },
}));

export default function TableFilterList(props: any) {

    const {classes} = useStyles();

    function removeFilter(index: number, filterValue: any, columnName: string, filterType: string, customFilterListUpdate = null) {
        let removedFilter = filterValue;
        if (Array.isArray(removedFilter) && removedFilter.length === 0) {
            removedFilter = (props.filterList)[index];
        }

        props.filterUpdate(index, filterValue, columnName, filterType, customFilterListUpdate, () => {
        });
    }

    const customFilterChip = (label: string, index: number, customFilterItemIndex: any, item: any, isArray: boolean) => {
        let type: string;
        // If our custom filter list is an array, we need to check for custom update functions to determine
        // default type. Otherwise, we use the supplied type in options.
        if (isArray) {
            type = props.customFilterListUpdate[index] ? 'custom' : 'chip';
        } else {
            type = props.columnNames[index].filterType;
        }

        return (
            <TableFilterListItem
                label={label}
                key={customFilterItemIndex}
                onDelete={() => removeFilter(index, item[customFilterItemIndex] || [], props.columnNames[index].name, type, props.customFilterListUpdate[index])}
                className={classes.chip}
            />
        );
    };

    const filterChip = (index: number, data: any, colIndex: number) => (
        <TableFilterListItem
            label={(props.filterListRenderers)[index](data)}
            key={colIndex}
            onDelete={() => removeFilter(index, data, (props.columnNames)[index].name, 'chip')}
            className={classes.chip}
        />
    );

    return <div className={classes.root}>
        {
            props.filterList.map((item: any[], index: number) => {

                if ((props.columnNames)[index].filterType === 'custom' && (props.filterList)[index].length) {
                    const filterListRenderersValue = (props.filterListRenderers)[index](item);
                    if (Array.isArray(filterListRenderersValue)) {
                        return filterListRenderersValue.map((customFilterItem, customFilterItemIndex) =>
                            customFilterChip(customFilterItem, index, customFilterItemIndex, item, true),
                        );
                    }
                    if (typeof filterListRenderersValue === 'string' && filterListRenderersValue.includes("fullvalue")) {
                        return customFilterChip(filterListRenderersValue.slice(0, -11), index, index, item, false)
                    }
                    return customFilterChip(filterListRenderersValue, index, index, item, false)
                }

                return item.map((data, colIndex) => filterChip(index, data, colIndex));

            })
        }
    </div>;
}
TableFilterList.propTypes = {
    /** Data used to filter table against */
    filterList: PropTypes.array.isRequired,
    /** Filter List value renderers */
    filterListRenderers: PropTypes.array.isRequired,
    /** Columns used to describe table */
    columnNames: PropTypes.arrayOf(
        PropTypes.oneOfType([
            PropTypes.string,
            PropTypes.shape({name: PropTypes.string.isRequired, filterType: PropTypes.string}),
        ]),
    ).isRequired,
    /** Callback to trigger filter update */
    onFilterUpdate: PropTypes.func
};