import React, {useContext, useRef, useState} from 'react';
import Tooltip from "@mui/material/Tooltip";
import Drawer from '@mui/material/Drawer';
import Popover from "@mui/material/Popover";
import {
    Accordion,
    AccordionDetails,
    AccordionSummary,
    Button,
    List,
    ListItemButton,
    ListItemText,
    MenuItem,
    Select,
    Theme
} from "@mui/material";
import {makeStyles} from "@mui/styles";
// @ts-ignore
import useMouseEvents from "beautiful-react-hooks/useMouseEvents";
import LogoComponent from "../icons/logoComponent";
import {CUSTOMERS, MyDataContext, NAVIGATION} from "../../ToolsComponent";
import {
    sidebarBackgroundHoverColor,
    sidebarBgColor,
    sidebarSelectedBackgroundColor,
    sidebarSelectedBackgroundHoverColor,
    sidebarSelectedTextColor,
    sidebarSelectedTextHoverColor,
    sidebarTextHoverColor,
    sidebarTxColor,
    textFont,
    themeColor2,
    whiteColor
} from "../../styles/getMuiTheme";
import {ToolCategory, ToolStack} from "../../classes";
import * as Icon from "react-feather";
import {MoreVertical, User} from "react-feather";
import ReactMarkdown from "react-markdown";
import gfm from "remark-gfm";
import {IconToolTip} from "../login/IconToolTip";
import {SignOutButton} from "../login/SignOutButton";

export default function Sidebar(props: any) {

    const [state, setState] = useContext(MyDataContext);
    const [isCollapsed, setIsCollapsed] = useState(false);
    const COLLAPSED_WIDTH   = 60;
    const EXPANDED_WIDTH    = 275;

    const toggleSidebar = () => {
        setIsCollapsed(!isCollapsed);
        isCollapsed ? state.handleSidebarResize(EXPANDED_WIDTH) : state.handleSidebarResize(COLLAPSED_WIDTH);
    };

    const useStyles = makeStyles(
        // @ts-ignore
        (theme: Theme) => {
            return ({
                dragger          : {
                    width          : '5px',
                    height         : '100%',
                    cursor         : 'ew-resize',
                    position       : 'absolute',
                    top            : 0,
                    right          : 0,
                    bottom         : 0,
                    zIndex         : '100',
                    backgroundColor: "rgba(0,0,0,0)",
                },
                userButton       : {
                    height   : "40px",
                    width    : "40px",
                    padding  : "8px",
                    "&:hover": {
                        borderRadius   : "50%",
                        backgroundColor:
                            sidebarBackgroundHoverColor.hexa() + " !important",
                        color          : sidebarTextHoverColor.hexa() + " !important",
                    },
                },
                buttons          : {
                    cursor        : "pointer",
                    color: sidebarTxColor.hexa(),
                    display       : "flex",
                    justifyContent: "center",
                    justifySelf   : "center",
                    height        : "40px",
                    width         : "59px",
                    padding       : "8px 18px",
                    "&:hover"     : {
                        backgroundColor:
                            sidebarBackgroundHoverColor.hexa() + " !important",
                        color          : sidebarTextHoverColor.hexa() + " !important",
                    },
                },
                buttonsActive    : {
                    cursor         : "pointer",
                    display        : "flex",
                    justifyContent : "center",
                    justifySelf    : "center",
                    height         : "40px",
                    width          : "59px",
                    padding        : "8px 18px",
                    backgroundColor:
                        sidebarBackgroundHoverColor.hexa() + " !important",
                    color          : sidebarTextHoverColor.hexa() + " !important",
                },
                accordionListItem: {
                    fontSize    : "14px",
                    overflow    : "hidden",
                    textOverflow: "ellipsis",
                    marginLeft  : "0"
                },
                categoryName     : {
                    marginLeft  : "12px",
                    textOverflow: "ellipsis",
                    whiteSpace  : "nowrap",
                    display     : "flex"
                },
                MuiAccordion     : {
                    backgroundColor: sidebarBgColor.hex() + " !important",
                    color          : sidebarTxColor.hex() + " !important",
                    boxShadow      : "0 0 0 0 !important",
                    "&:before"     : {
                        backgroundColor: "rgba(0,0,0,0) !important",
                    }
                },
                accordionSummary : {
                    backgroundColor : sidebarBgColor.hexa(),
                    color           : sidebarTxColor.hex(),
                    borderRadius    : "6px",
                    fontFamily      : textFont,
                    padding         : "0 12px !important",
                    fontWeight      : theme.typography.fontWeightBold,
                    fontSize        : "15px",
                    '& .selected'   : {
                        borderRadius   : "6px",
                        backgroundColor: themeColor2.fade(0.8).hexa() + " !important",
                        '&:hover'      : {
                            backgroundColor: themeColor2.fade(0.5).hexa() + " !important"
                        },
                    },
                    '&.Mui-expanded': {
                        borderRadius   : "6px",
                        fontWeight     : '700',
                        backgroundColor: sidebarBackgroundHoverColor.hexa() + " !important",
                        color          : sidebarTextHoverColor.hexa() + " !important"
                    },
                    '&:hover'       : {
                        borderRadius   : "6px",
                        backgroundColor: sidebarBackgroundHoverColor.hexa() + " !important",
                        color          : sidebarTextHoverColor.hexa() + " !important"
                    },

                },
                accordionDetails : {
                    backgroundColor  : sidebarBgColor.hexa(),
                    color            : sidebarTxColor.hexa(),
                    padding          : "0",
                    '& .MuiList-root': {
                        padding                    : "0",
                        '& .MuiListItemButton-root': {
                            padding         : "8px 12px 8px 10px",
                            margin          : "4px 0px 4px 38px",
                            color           : sidebarTxColor.hexa(),
                            backgroundColor : sidebarBgColor.hexa(),
                            borderRadius    : "6px",
                            '&:hover'       : {
                                fontWeight     : "bold",
                                color          : sidebarTextHoverColor.hexa(),
                                backgroundColor: sidebarBackgroundHoverColor.hexa()
                            },
                            "&.Mui-selected": {
                                backgroundColor: sidebarSelectedBackgroundColor.hexa(),
                                color          : sidebarSelectedTextColor.hexa(),
                                fontWeight     : "bold",
                                "&:hover"      : {
                                    color          : sidebarSelectedTextHoverColor.hexa(),
                                    backgroundColor: sidebarSelectedBackgroundHoverColor.hexa(),
                                    fontWeight     : "800",
                                }
                            }
                        }
                    }
                },
                accordionMenuItem: {
                    backgroundColor: "#aaa"
                },
            });
        }
    );

    const useStyles2 = makeStyles(
        // @ts-ignore
        (theme) => {

            let selectMargin = 10

            return {
                drawer             : {
                    backgroundColor: sidebarBgColor.hexa() + " !important",
                    width          : state.sideBarWidth,
                },
                parent             : {
                    width     : state.sideBarWidth,
                    height    : "100vh",
                    display   : "flex",
                    transition: "width 0.5s ease-in-out",
                },
                customerSelectInput: {
                    width              : "calc(" + state.sideBarWidth + "px - " + (selectMargin * 2) + "px)",
                    color              : sidebarTxColor.hexa() + " !important",
                    backgroundColor    : sidebarBgColor.darken(0.2).hexa(),
                    border             : "0",
                    borderRadius       : "6px !important",
                    margin             : `5px ${selectMargin}px`,
                    height             : "44px",
                    fontSize           : "14px",
                    "& .MuiSelect-icon": {
                        color: sidebarTxColor.hexa()
                    }
                },
                loggedInUser       : {
                    display        : "flex",
                    alignItems     : "center",
                    fontSize       : "14px",
                    justifyContent : "space-between",
                    color          : sidebarTxColor.hexa(),
                    backgroundColor: sidebarBgColor.darken(0.2).hexa(),
                    margin         : "5px 10px",
                    padding        : "0px 10px",
                    borderRadius   : "6px"
                },
                bottomMatter       : {
                    position       : "fixed",
                    width          : state.sideBarWidth,
                    backgroundColor: sidebarBgColor.hexa(),
                    bottom         : 0,
                    paddingBottom  : "5px"
                },
                sidebarTop         : {
                    padding : "24px 12px 12px 12px",
                    height  : "calc(100% - 120px)",
                    overflow: "auto"
                },
                sidebarTopCollapsed: {
                    padding : "0px",
                    height  : "calc(100% - 120px)",
                    overflow: "auto",
                },
                logoutButton       : {
                    cursor   : "pointer",
                    "&:hover": {
                        color     : sidebarBgColor.hexa(),
                        fontWeight: "bold"
                    },
                },
                plainButton        : {
                    cursor   : "pointer",
                    color: sidebarTxColor.hexa(),
                    width    : "100%",
                    "&:hover": {
                        color     : whiteColor.hexa(),
                        fontWeight: "bold"
                    }
                }
            };
        }
    );

    const [expanded, setExpanded] = React.useState<string | false>(false);

    const [anchorEl, setAnchorEl] = useState<HTMLElement | null>(null);

    const handleClick = (
        event: React.MouseEvent<HTMLElement>,
        categoryName: string
    ) => {
        setAnchorEl(event.currentTarget);
        setExpanded(categoryName);
    };

    const handleClose = () => {
        setAnchorEl(null);
        setExpanded(false);
    };


    const handleChange =
              (panel: string) => (event: React.SyntheticEvent, isExpanded: boolean) => {
                  setExpanded(isExpanded ? panel : false);
              };

    const [resizing, setResizing]  = React.useState(false);
    const preventGlobalMouseEvents = () => {
        document.body.style["pointer-events" as any] = 'none';
    };
    const restoreGlobalMouseEvents = () => {
        document.body.style["pointer-events" as any] = 'auto';
    };
    const classes                  = useStyles() as any;
    const classes2                 = useStyles2() as any;
    const ref                      = useRef() as any;
    const {onMouseDown}            = useMouseEvents(ref);
    const {onMouseUp, onMouseMove} = useMouseEvents();

    onMouseDown((e: any) => {
        preventGlobalMouseEvents();
        setResizing(true);
    });

    onMouseMove((e: any) => {
        // we don't want to do anything if we aren't resizing.
        if (!resizing) {
            return;
        }

        let minWidth = 50;
        let maxWidth = 600;
        if (e.clientX > minWidth && e.clientX < maxWidth) {
            state.handleSidebarResize(e.clientX)
        }

    });

    onMouseUp((e: any) => {
        if (!resizing) {
            return;
        }
        restoreGlobalMouseEvents();
        setResizing(false);
    })

    // Object.values(Icon).forEach(v => console.log(v.displayName))

    const text = "## Information\n" +
        "---\n" +
        "Copyright © 2023-2024\n" +
        "Lion Hills, LLC.\n" +
        "All Rights Reserved.\n" +
        "### Privacy Policy\n" +
        "TBD"

    return (
        <div className={classes2.parent}>

            <Drawer variant="permanent" PaperProps={{className: classes2.drawer}}>

                {/*Dragger*/}
                {/*<div id="dragger" ref={ref} className={classes.dragger}/>*/}
                {/*Accordion*/}
                <div className={isCollapsed ? classes2.sidebarTopCollapsed : classes2.sidebarTop}
                     id={"sidebar_navigation_root"}>
                    {/*Logo*/}
                    <LogoComponent isCollapsed={isCollapsed}/>
                    <div
                        style={{
                            display       : "flex",
                            justifyContent: "center",
                            margin        : "10px 0"
                        }}
                    >
                        <Tooltip
                            title={'Collapse'}
                            placement="right"
                            arrow={true}
                            PopperProps={{
                                modifiers: [
                                    {
                                        name   : "offset",
                                        options: {
                                            offset: [0, -5],
                                        },
                                    },
                                ],
                            }}
                        >

                            {isCollapsed ? (

                                <Icon.AlignJustify
                                    id={"collapse_sidebar_button"}
                                    onClick={toggleSidebar}
                                    className={classes.buttons}
                                    style={{
                                        color       : sidebarTxColor.hexa(),
                                        cursor      : "pointer",
                                        marginBottom: "10px",
                                    }}
                                />
                            ) : (
                                <Icon.AlignJustify
                                    id={"collapse_sidebar_button"}
                                    onClick={toggleSidebar}
                                    className={classes.buttons}
                                    style={{color: sidebarTxColor.hexa(), cursor: "pointer"}}
                                />
                            )}</Tooltip>
                    </div>
                    {NAVIGATION ? NAVIGATION.map((category: ToolCategory) => {
                        // @ts-ignore
                        const IconTag = Icon[category.iconName ? category.iconName : 'HelpCircle'] as any || Icon['HelpCircle'];

                        return isCollapsed ? (
                            <div key={category.name}>
                                <div onClick={(e) => handleClick(e, category.name)}>
                                    <Tooltip
                                        title={category.name}
                                        placement="right"
                                        arrow={true}
                                        PopperProps={{
                                            modifiers: [
                                                {
                                                    name   : "offset",
                                                    options: {
                                                        offset: [0, -5],
                                                    },
                                                },
                                            ],
                                        }}
                                    >
                                        <IconTag
                                            className={
                                                expanded !== category.name
                                                    ? classes.buttons
                                                    : classes.buttonsActive
                                            }
                                        />
                                    </Tooltip>
                                    {state.sideBarWidth > 200 && (
                                        <div className={classes.categoryName}>
                                            {category.name}
                                        </div>
                                    )}
                                </div>
                                <Popover
                                    id={
                                        expanded === category.name
                                            ? "simple-popover"
                                            : undefined
                                    }
                                    open={expanded === category.name}
                                    anchorEl={anchorEl}
                                    onClose={handleClose}
                                    anchorOrigin={{
                                        vertical  : "bottom",
                                        horizontal: "right",
                                    }}
                                    transformOrigin={{
                                        vertical  : "top",
                                        horizontal: "left",
                                    }}
                                >
                                    <List>
                                        {state.toolStacks
                                            .filter(
                                                (toolStack: ToolStack) =>
                                                    toolStack.toolCategoryName === category.name
                                            )
                                            .map((toolStack: ToolStack, index: number) => (
                                                <ListItemButton
                                                    key={toolStack.key + "_" + index}
                                                    selected={toolStack.selected}
                                                    onClick={() => {
                                                        state.handleSidebarToolClick(toolStack);
                                                        handleClose();
                                                    }}
                                                >
                                                    <ListItemText
                                                        primary={toolStack.title}
                                                        disableTypography={true}
                                                    />
                                                </ListItemButton>
                                            ))}
                                    </List>
                                </Popover>
                            </div>
                        ) : (
                            <Accordion key={category.name}
                                       id={category.name.replaceAll(" ", "_") + "_accordion"}
                                       classes={{root: classes.MuiAccordion}}
                                       expanded={expanded === category.name}
                                       onChange={handleChange(category.name)}>
                                <AccordionSummary key={category.name}
                                                  expandIcon={<Icon.ChevronDown size={20}
                                                                                color={whiteColor.fade(0.5).hexa()}/>}
                                                  classes={{root: classes.accordionSummary}}>
                                    <IconTag/>
                                    {state.sideBarWidth > 200 ?
                                        <div id={category.name.replaceAll(" ", "_") + "_label"}
                                             className={classes.categoryName}>{category.name}</div> : null}
                                </AccordionSummary>
                                <AccordionDetails key={category.name} className={classes.accordionDetails}>
                                    <List>
                                        {
                                            state.toolStacks
                                                .filter((toolStack: ToolStack) => toolStack.toolCategoryName === category.name)
                                                .map((toolStack: ToolStack, toolIndex: number) =>
                                                    <ListItemButton key={toolStack.key + "_" + toolIndex}
                                                                    id={toolStack.toolCategoryName.replaceAll(" ", "_") + "__" + toolStack.title.replaceAll(" ", "_") + "_sidebarButton"}
                                                                    selected={toolStack.selected}
                                                        // className={toolStack.selected ? classes.accordionMenuItem : null}
                                                                    onClick={() => state.handleSidebarToolClick(toolStack)}>
                                                        <ListItemText
                                                            primary={toolStack.title}
                                                            disableTypography={true}
                                                            className={classes.accordionListItem}/>
                                                    </ListItemButton>
                                                )
                                        }
                                    </List>
                                </AccordionDetails>
                            </Accordion>
                        );
                    }) : ""}
                </div>
                {/*Customers*/}
                <div className={classes2.bottomMatter}>
                    <div className={isCollapsed ? classes2.plainButton : classes2.loggedInUser}>
                        <div style={{display: "flex", alignItems: "center"}}>
                            {isCollapsed ? (
                                <IconToolTip
                                    arrow={true}
                                    placement="right"
                                    PopperProps={{
                                        modifiers: [
                                            {
                                                name   : "offset",
                                                options: {
                                                    offset: [0, -5],
                                                },
                                            },
                                        ],
                                    }}
                                    iconButton={
                                        <User color={sidebarTxColor.hexa()} className={classes.userButton}/>
                                    }
                                    title={state.account.name}
                                >
                    <span
                        className={classes2.logoutButton}
                        onClick={() => state.handleLogout()}
                    >
                      <SignOutButton/>
                      <span>Sign Out</span>
                    </span>
                                    <ReactMarkdown remarkPlugins={[gfm]}>{text}</ReactMarkdown>
                                    <div>
                                        <Button onClick={state.handleToolStateClear}>
                                            Clear Cache
                                        </Button>
                                    </div>
                                </IconToolTip>
                            ) : (
                                <User style={{marginRight: "5px"}}/>
                            )}
                            <div style={{flexDirection: "column"}}>
                                {isCollapsed ? null : <div>{state.account.name}</div>}
                                <div style={{
                                    maxWidth    : state.sideBarWidth - 100,
                                    fontWeight  : "normal",
                                    fontSize    : "12px",
                                    overflow    : "hidden",
                                    textOverflow: "ellipsis"
                                }}>
                                    {/*({state.account.username})*/}
                                </div>
                            </div>
                        </div>
                        {isCollapsed ? null :
                            <IconToolTip iconButton={<MoreVertical/>} title={"More"}>
                                <span className={classes2.logoutButton}
                                      onClick={() => state.handleLogout()}><SignOutButton/>
                                    <span>Sign Out</span>
                                </span>
                                <ReactMarkdown remarkPlugins={[gfm]}>
                                    {text}
                                </ReactMarkdown>
                                <div><Button onClick={state.handleToolStateClear}>Clear Cache</Button></div>
                            </IconToolTip>
                        }
                    </div>
                    {CUSTOMERS && CUSTOMERS.length > 1 ?
                        isCollapsed ? (
                            <Tooltip
                                title={"Customer"}
                                placement="right"
                                arrow={true}
                                PopperProps={{
                                    modifiers: [
                                        {
                                            name   : "offset",
                                            options: {
                                                offset: [0, 0],
                                            },
                                        },
                                    ],
                                }}
                            >
                                <Select
                                    id={"customer_select_dropdown"}
                                    className={classes2.customerSelectInput}
                                    value={isCollapsed ? "" : state.currentCustomer?.name || ""}
                                    onChange={state.handleCustomerChange}
                                >
                                    {CUSTOMERS?.map((c: any) => (
                                        <MenuItem
                                            id={
                                                "customer_select_option_" +
                                                c.name.replaceAll(" ", "_").replaceAll(/[,']/g, "")
                                            }
                                            key={c.name}
                                            value={c.name}
                                        >
                                            {c.name}
                                        </MenuItem>
                                    ))}
                                </Select>
                            </Tooltip>
                        ) : <Select id={"customer_select_dropdown"}
                                    className={classes2.customerSelectInput}
                                    value={state.currentCustomer?.name || ''}
                                    onChange={state.handleCustomerChange}>
                            {CUSTOMERS?.map((c: any) => <MenuItem
                                id={"customer_select_option_" + c.name.replaceAll(" ", "_").replaceAll(/[,']/g, "")}
                                key={c.name} value={c.name}>{c.name}</MenuItem>)}
                        </Select> : null}
                </div>
            </Drawer>
        </div>
    );
}