import {Grid} from "@mui/material";
import React from "react";
import {FieldArray, useFormApi} from "@data-driven-forms/react-form-renderer";
import useFieldApi from "@data-driven-forms/react-form-renderer/use-field-api";
import Button from "@mui/material/Button";
import {mapLayoutItem} from "../../../util/FormUtils";


const FieldArrayItem = ({fields, fieldIndex, layout, name, remove}: any) => {
    const editedFields = fields.map((field: any) => ({...field, name: `${name}.${field.name}`}));
    const {renderForm} = useFormApi();
    const renderedFields = renderForm(editedFields)

    return <Grid item width={"100%"}>
        {layout?.map((item: any) => mapLayoutItem(item, renderedFields, {onDelete: remove}))}
    </Grid>
};

export default function FieldArrayCustom(props: any) {
    const {
              fieldKey,
              label,
              arrayValidator,
              title,
              layout,
              description,
              addButtonText,
              fields,
              itemDefault,
              meta,
              ...rest
          }                            = useFieldApi(props);
    const {dirty, submitFailed, error} = meta;
    const isError                      = (dirty || submitFailed) && error && typeof error === 'string';

    return <FieldArray key={fieldKey} name={rest.input.name} validate={arrayValidator}>
        {(cosi) =>
            <>
                {label && <h3>{label}</h3>}
                {description && <p>{description}</p>}
                <Grid container spacing={2}>
                    {cosi.fields.map((name: string, index: number) => (
                        <FieldArrayItem
                            key={`${name || fieldKey}-${index}`}
                            fields={fields}
                            name={name}
                            layout={layout}
                            fieldKey={fieldKey}
                            fieldIndex={index}
                            remove={() => {
                                // cosi.fields.forEach((name: string, index: number) =>)["delete"] = true
                                cosi.fields.remove(index);
                            }}
                        />
                    ))}
                </Grid>
                <div style={{margin: "15px 0 20px 0"}}>
                    <Button variant={"text"} onClick={() => cosi.fields.push(itemDefault)}>
                        {addButtonText || "Add"}
                    </Button>
                </div>
            </>}
    </FieldArray>
}