import {Grid} from "@mui/material";
import React from "react";
import PropTypes from "prop-types";
import {mapLayout} from "./FreeformUtils";

export default function GridItem(props: { meta: any, data: any }) {
    let styles = {} as any

    if (props.meta.padding) {
        styles["padding"] = props.meta.padding
    }
    if (props.meta.margin) {
        styles["margin"] = props.meta.margin
    }

    return <Grid item
                 style={styles}
                 key={JSON.stringify(props.meta)}
                 sx={props.meta.sx}
                 xs={parseInt(props.meta.columns)}
                 sm={parseInt(props.meta.columns)}
                 md={parseInt(props.meta.columns)}
                 lg={parseInt(props.meta.columns)}
                 xl={parseInt(props.meta.columns)}>
        {props.meta.fields?.map((f: any) => mapLayout(f, props.data ? props.data : null))}
    </Grid>
}


GridItem.propTypes = {
    meta: PropTypes.any.isRequired,
    data: PropTypes.any
}