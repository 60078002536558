import PropTypes, {array} from 'prop-types'
import {FormGroup, FormLabel, InputAdornment} from "@mui/material";
import React from "react";
import {CustomTextField} from "../../forms/customMappers/CustomTextField";

export default function PercentageFilter(props) {
    const filterArray = props.filterList[props.index];
    return filterArray ? <div>
        <FormLabel>{props.label}</FormLabel>
        <FormGroup row>
            <CustomTextField
                id={props.column.testId + "_filter_min"}
                label='min'
                value={filterArray[0] || ''}
                onChange={event => {
                    filterArray[0] = event.target.value;
                    props.onChange(filterArray, props.index, props.column);
                }}
                InputProps={{
                    endAdornment: <InputAdornment position="end">%</InputAdornment>,
                }}
                style={{width: '45%', marginRight: '5%'}}
            />
            <CustomTextField
                id={props.column.testId + "_filter_max"}
                label='max'
                value={filterArray[1] || ''}
                onChange={event => {
                    filterArray[1] = event.target.value;
                    props.onChange(filterArray, props.index, props.column);
                }}
                InputProps={{
                    endAdornment: <InputAdornment position="end">%</InputAdornment>,
                }}
                style={{width: '45%'}}
            />
        </FormGroup>
    </div> : null
}

PercentageFilter.propTypes = {
    column: PropTypes.object.isRequired,
    filterList: PropTypes.arrayOf(array),
    index: PropTypes.number.isRequired,
    label: PropTypes.string.isRequired,
    onChange: PropTypes.func.isRequired
}