import {Accordion, AccordionDetails, AccordionSummary} from "@mui/material";
import ArrowForwardIosSharpIcon from "@mui/icons-material/ArrowForwardIosSharp";
import React from "react";
import {useDrilldownStyles} from "./DrillDownModal";
import {accordionDetailBgColor} from "../../styles/getMuiTheme";

export default function DrillDownAccordion(props) {
    let classes = useDrilldownStyles({depth: props.depth})()

    return <Accordion {...props} classes={{root: classes.accordion}}>
        <AccordionSummary
            expandIcon={<ArrowForwardIosSharpIcon classes={{root: classes.arrow}} sx={{fontSize: '0.9rem'}}/>}
            classes={{root: classes.root}}
            depth={props.depth}>
            {props.summarytext}
        </AccordionSummary>
        <AccordionDetails style={{backgroundColor: accordionDetailBgColor.hexa(), flexDirection: "column"}}>
            {props.children}
        </AccordionDetails>
    </Accordion>
}
