import React, {useContext} from 'react';
import {Button, Card, Dialog, DialogContent, DialogTitle, IconButton, Skeleton} from "@mui/material";
import {MyDataContext} from "../../ToolsComponent";
import Color from "color";
import {makeStyles} from "@mui/styles";
import CloseIcon from '@mui/icons-material/Close';
import DrillDownDetail from "./DrillDownDetail";
import {pdfjs} from "react-pdf";
import {
    blackColor,
    jsonColor1,
    jsonColor2,
    jsonColor3,
    jsonColor4,
    jsonColor5,
    whiteColor
} from "../../styles/getMuiTheme";

pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.js`;

let colors = [
    // Color("#005C8A"),
    // Color("#00acdf"),
    // Color("#55d0ff"),
    // Color("#7ce8ff"),
    // Color("#ccf9ff"),
    jsonColor1,
    jsonColor2,
    jsonColor3,
    jsonColor4,
    jsonColor5
]

function getBgColor(depth) {
    let number = ((depth - 1) % colors.length);
    return number < 0 ? colors[0] : colors[number]
}

export function useDrilldownStyles(props) {

    function relativeLuminanceW3C(R8bit, G8bit, B8bit) {

        let RsRGB = R8bit / 255;
        let GsRGB = G8bit / 255;
        let BsRGB = B8bit / 255;

        let R = (RsRGB <= 0.03928) ? RsRGB / 12.92 : Math.pow((RsRGB + 0.055) / 1.055, 2.4);
        let G = (GsRGB <= 0.03928) ? GsRGB / 12.92 : Math.pow((GsRGB + 0.055) / 1.055, 2.4);
        let B = (BsRGB <= 0.03928) ? BsRGB / 12.92 : Math.pow((BsRGB + 0.055) / 1.055, 2.4);

        // For the sRGB colorspace, the relative luminance of a color is defined as:
        return (0.2126 * R) + (0.7152 * G) + (0.0722 * B);
    }

    function getTextColorAdvanced(bcColorHexa) {

        const backgroundColor = Color(bcColorHexa)

        let luminance = relativeLuminanceW3C(backgroundColor.color[0], backgroundColor.color[1], backgroundColor.color[2]);

        return luminance > 0.179 ? Color("#000000") : Color("#ffffff");
    }

    return makeStyles(
        theme => {
            let depth = props.depth
            let bgColor = getBgColor(depth || 0);
            let dialogTitleBg = colors[0].lighten(2).hexa();
            return ({
                root: {
                    '& .MuiAccordionSummary-expandIconWrapper.Mui-expanded': {
                        transform: 'rotate(90deg)',
                    },
                    flexDirection: 'row-reverse',
                    height: "22px !important",
                    minHeight: "22px !important",
                    '& .MuiAccordionSummary-content': {
                        marginLeft: theme.spacing(1),
                        "&.Mui-expanded": {
                            marginLeft: theme.spacing(1),
                        }
                    },
                    backgroundColor: bgColor.hexa() + " !important",
                    color: getTextColorAdvanced(bgColor.hexa()).hexa() + " !important",
                    '& .MuiSvgIcon-root': {
                        color: getTextColorAdvanced(bgColor.hexa()).hexa() + " !important",
                    },
                    '&:hover': {
                        color: getTextColorAdvanced(bgColor.darken(0.3).hexa()).hexa() + " !important",
                        backgroundColor: bgColor.darken(0.3).hexa() + " !important",
                        '& .MuiSvgIcon-root': {
                            color: getTextColorAdvanced(bgColor.darken(0.3).hexa()).hexa() + " !important",
                        }
                    },
                },
                arrow: {
                    color: getTextColorAdvanced(bgColor.hexa()).hexa() + " !important",
                    '&:hover': {
                        color: getTextColorAdvanced(bgColor.darken(0.3).hexa()).hexa() + " !important",
                        backgroundColor: bgColor.darken(0.3).hexa() + " !important"
                    }
                },
                table: {
                    width: "fit-content",
                },
                tableHeader: {
                    paddingLeft: "5px",
                    paddingRight: "5px",
                    textAlign: "left"
                },
                tableCell: {
                    paddingLeft: "5px",
                    paddingRight: "5px"
                },
                dialogTitle: {
                    backgroundColor: dialogTitleBg,
                    minWidth: "200px",
                    paddingRight: "60px !important",
                    color: getTextColorAdvanced(dialogTitleBg).hexa()
                },
                accordion: {
                    margin: "5px",
                    marginTop: "10px",
                    border: "2px solid " + getBgColor(props.depth),
                    '& .MuiAccordionDetails-root': {
                        backgroundColor: whiteColor.hexa() + " !important",
                        color: blackColor.hexa()
                    },

                },
                card: {
                    padding: "10px",
                    width: "400px",
                    backgroundColor: "#eeeeee !important",
                    fontSize: "12px"
                }


            });
        }
    );
}

export default function DrillDownModal(props) {

    const [state, setState] = useContext(MyDataContext);

    let classes = useDrilldownStyles({depth: 0})()

    return (
        <Dialog open={state.mermaidModalOpen} maxWidth={false}
                onClose={state.handleDrilldownClose}>

            <DialogTitle classes={{root: classes.dialogTitle}}>
                {
                    state.drilldownHeaderText ?
                        <span style={{paddingRight: "20px"}}>{state.drilldownHeaderText}</span> :
                        <Skeleton animation={"wave"} sx={{fontSize: '2rem'}}/>
                }
                <IconButton aria-label="close" onClick={state.handleDrilldownClose}
                            sx={{position: 'absolute', right: 8, top: 11}}>
                    <CloseIcon style={{color: jsonColor1.hexa()}}/>
                </IconButton>
            </DialogTitle>

            <DialogContent>
                {
                    state.mermaidModalData !== null ?
                        <DrillDownDetail object={state.mermaidModalData} depth={0}/> :

                        <>
                            <Skeleton animation={"wave"} sx={{fontSize: '1rem'}} style={{marginBottom: "5px"}}/>
                            <Skeleton animation={"wave"} sx={{fontSize: '1rem'}} style={{marginBottom: "10px"}}/>
                            <Skeleton variant={"rounded"} animation={"wave"} width={800} height={100}
                                      style={{marginBottom: "10px"}}/>
                            <Skeleton variant={"rounded"} animation={"wave"} width={800} height={100}
                                      style={{marginBottom: "10px"}}/>
                        </>
                }
                {
                    state.detailActions ? state.detailActions.map(a =>
                        <div>
                            <Button onClick={a.onClick}>{a.name}</Button>
                            {a.description ? <Card className={classes.card}>{a.description}</Card> : null}
                        </div>
                    ) : null}

            </DialogContent>
        </Dialog>
    );
}

