import React from "react";
import PropTypes from "prop-types";
import {mapLayout} from "./FreeformUtils";

export default function CenterLayout(props: { meta: any, data: any }) {
    return <div key={JSON.stringify(props.meta)}
                style={{
                    ...props.meta.style,
                    flex          : 1,
                    display       : 'flex',
                    overflow      : 'hidden',
                    alignItems    : 'center',
                    flexDirection : 'column',
                    justifyContent: 'center'
                }}>
        {props.meta.fields?.map((f: any) => mapLayout(f, props.data ? props.data : null))}
    </div>
}


CenterLayout.propTypes = {
    meta: PropTypes.any.isRequired,
    data: PropTypes.any
}