import PropTypes, {array} from 'prop-types'
import {FormGroup, FormLabel, TextField} from "@mui/material";
import React from "react";
import CurrencyFormat from 'react-currency-format';

export default function MoneyFilter(props) {
    const filterArray = props.filterList[props.index];
    return filterArray ? <div>
        <FormLabel>{props.label}</FormLabel>
        <FormGroup row>
            <CurrencyFormat
                sx={{margin: "22px 0 5px 0"}}
                id={props.column.testId + "_filter_min"}
                value={filterArray[0] || ''}
                label={"Min:"}
                fixedDecimalScale={true}
                decimalScale={2}
                thousandSeparator={true}
                prefix={"$"}
                customInput={TextField}
                onValueChange={event => {
                    filterArray[0] = event.value;
                    if (!filterArray[1]) {
                        filterArray[1] = null
                    }
                    props.onChange(filterArray, props.index, props.column);
                }}
            />
            <CurrencyFormat
                id={props.column.testId + "_filter_max"}
                sx={{margin: "22px 0 5px 0"}}
                value={filterArray[1] || ''}
                fixedDecimalScale={true}
                decimalScale={2}
                label={"Max:"}
                thousandSeparator={true}
                customInput={TextField}
                prefix={"$"}
                onValueChange={event => {
                    if (!filterArray[0]) {
                        filterArray[0] = null
                    }

                    filterArray[1] = event.value;
                    props.onChange(filterArray, props.index, props.column);
                }}

            />
        </FormGroup>
    </div> : null
}

MoneyFilter.propTypes = {
    column: PropTypes.object.isRequired,
    filterList: PropTypes.arrayOf(array).isRequired,
    index: PropTypes.number.isRequired,
    label: PropTypes.string.isRequired,
    onChange: PropTypes.func.isRequired
}