import React from 'react';
import LoadingModal from "./LoadingModal";


export default function DbStatusModal(props) {

    const startingUpStates = [
        'Paused',
        'Pausing',
        'Resuming',
        'Scaling',
    ]

    const maintenanceStates = [
        'AutoClosed',
        'Copying',
        'Creating',
        'Disabled',
        'EmergencyMode',
        'Inaccessible',
        'Offline',
        'OfflineChangingDwPerformanceTiers',
        'OfflineSecondary',
        'OnlineChangingDwPerformanceTiers',
        'Recovering',
        'RecoveryPending',
        'Restoring',
        'Shutdown',
        'Standby',
        'Suspect',
    ]

    let content = <>
        <div>Database</div>
        <div style={{fontWeight: "bold"}}>{props.dbStatus?.name}</div>
        {startingUpStates.includes(props.dbStatus?.status) && <div> is starting up...</div>}
        {maintenanceStates.includes(props.dbStatus?.status) && <div> is down for maintenance.</div>}
    </>

    return props.dbStatus && <LoadingModal>{content}</LoadingModal>;
}

