import moment from "moment";

export function convertToMonthNames(monthIndexes: number[]) {
    const monthNames = getMonthNames()
    return monthIndexes.map((m: number) => monthNames[m - 1]);
}

export function convertToMonthName(monthIndex: number) {
    return convertToMonthNames([monthIndex])[0]
}

export function getMonthNames() {
    return [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12].map(i => moment().month(i - 1).format("MMMM")) as string[]
}
