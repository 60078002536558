import {getEmpty} from "./DrillDownFormattingUtils";
import DrillDownAccordion from "./DrillDownAccordion";
import React from "react";
import DrillDownDetail from "./DrillDownDetail";

export default function DrillDownObject(props) {
    let object = props.object
    let name = props.name
    let depth = props.depth
    
    const isEmpty = getEmpty(object);

    if (isEmpty) {
        return "--"
    }

    return <DrillDownAccordion defaultExpanded={true} summarytext={name || ''} depth={depth}>
        <DrillDownDetail object={object} depth={depth}/>
    </DrillDownAccordion>
}

