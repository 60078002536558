import AutoSizer from "react-virtualized-auto-sizer";
import {CellMeasurer, CellMeasurerCache, MultiGrid} from "react-virtualized";
import {progressSpinnerColor, tableHeaderBGColor, tableHeaderBorderColor} from "../../../styles/getMuiTheme";
import {CircularProgress} from "@mui/material";
import {useStyles} from "../mui-datatables/components/TableHeadCell";
import React, {useState} from "react";
import {DataTableTitle} from "../DataTableTitle";
import TableHeaderBeta from "./TableHeaderBeta";
import styles from './ScrollSync.example.css'
import cloneDeep from "lodash.clonedeep";
import {TableFilterList} from "../mui-datatables";
import TableToolbar from "./components/TableToolbar";
import TableCellVirtualized from "./components/TableCellVirtualized";
import getColumnLabelString from "../../../util/TextUtils";

export const MUIDataTableVirtualized = (props) => {

    const rowCount = props.data.length + 1;
    const columnCount = props.columns.length;

    const rowHeight = 40;

    const _cache = new CellMeasurerCache({
        defaultHeight: rowHeight,
        defaultWidth: 150,
        fixedHeight: true,
        fixedWidth: false
    });

    const draggableHeadCellRefs = []
    const resizeHeadCellRefs = []
    const setHeadCellRef = (index, pos, el) => {
        draggableHeadCellRefs[index] = el;
        resizeHeadCellRefs[pos] = el;
    };

    let {classes} = useStyles()
    const [dragging, setDragging] = useState(false);
    const [hoveredRowIndex, setHoveredRowIndex] = useState(-1);


    const columnNames = props.columns.map(column => ({
        name: column.name,
        filterType: column.filterType || props.options.filterType,
    }));

    const columnLabelStrings = columnNames.map((c) => getColumnLabelString(c.name));
    const columnLabelLengths = columnLabelStrings.map(c => c.split("\n").length);
    const longestColumnName = Math.max(...columnLabelLengths)

    function toggleSort(index) {

        let newOrder = props.columns[index].sortDescFirst ? 'desc' : 'asc'; // default

        let sequenceOrder = ['asc', 'desc', 'none'];
        if (props.columns[index].sortDescFirst) {
            sequenceOrder = ['desc', 'asc', 'none'];
        }
        // if (props.columns[index].sortThirdClickReset) {
        //     sequenceOrder.push('none');
        // }

        if (props.columns[index].name === props.sortOrder?.name) {
            let pos = sequenceOrder.indexOf(props.sortOrder?.direction);
            if (pos !== -1) {
                pos++;
                if (pos >= sequenceOrder.length) pos = 0;
                newOrder = sequenceOrder[pos];
            }
        }

        props.handleTableSortChange(props.tool, props.columns[index].name, newOrder)
    }


    function resetFilters() {
        props.options.onFilterChange(props.columns.map(() => []));
    }

    function handleFilterUpdate(index) {
        let newFilterList = cloneDeep(props.tool.filterList);
        newFilterList[index] = []
        filterUpdate(newFilterList)
    }

    function filterUpdate(headerFilterList) {
        const newFilterList = cloneDeep(props.tool.filterList);
        headerFilterList.forEach((v, i) => newFilterList[i] = v);
        props.options.onFilterChange(newFilterList);
    }


    function _cellRenderer({columnIndex, key, parent, rowIndex, style}) {
        // console.log(columnIndex, key, parent, rowIndex, style)

        if (columnIndex === props.columns.length) {

            let content = "";

            if (rowIndex === 0) {
                return <CellMeasurer cache={_cache}
                                     columnIndex={columnIndex}
                                     key={'paddingCell' + columnIndex}
                                     parent={parent}
                                     rowIndex={rowIndex}>
                    {({registerChild}) => (
                        <div className={styles}
                             key={key}
                             ref={registerChild}
                             style={{
                                 ...style,
                                 whiteSpace: 'nowrap',
                                 display: "flex",
                                 backgroundColor: tableHeaderBGColor.hexa(),
                                 borderRight: "1px solid " + tableHeaderBorderColor.hexa(),
                                 borderTop: "1px solid " + tableHeaderBorderColor.hexa(),
                                 borderBottom: "1px solid " + tableHeaderBorderColor.hexa()
                             }}>
                            Hello world
                        </div>
                    )}
                </CellMeasurer>
            } else {
                return <CellMeasurer cache={_cache}
                                     columnIndex={columnIndex}
                                     key={'paddingCell' + columnIndex}
                                     parent={parent}
                                     rowIndex={rowIndex}>
                    {({registerChild}) => (
                        <div className={styles}
                             key={key}
                             ref={registerChild}
                             style={{
                                 ...style,
                                 borderBottom: "1px solid " + tableHeaderBorderColor.hexa()
                             }}>
                            Hello world
                        </div>
                    )}
                </CellMeasurer>
            }
        } else {
            const column = props.columns[columnIndex]
            const serverColumn = props.tool.columns.find((c) => c.attr === column.name)

            const columnKey = column.name;

            let content = rowIndex === 0 ? column.label : props.data[rowIndex - 1][columnKey];

            if (columnIndex === 0 && content) {
                content = content.substring(0, 50)
            }
            // TODO: Maxwidth
            if (rowIndex === 0) {
                return <TableHeaderBeta
                    cache={_cache}
                    classes={classes}
                    columns={props.columns}
                    column={column}
                    columnIndex={columnIndex}
                    filterList={props.tool.filterList}
                    key={key}
                    parent={parent}
                    toggleSort={toggleSort}
                    rowIndex={rowIndex}
                    style={style}
                    styles={styles}
                    filterUpdate={filterUpdate}
                    sortDirection={column.name === props.sortOrder.name ? props.sortOrder.direction : 'none'}
                    filterReset={resetFilters}>
                    {content}
                </TableHeaderBeta>
            } else {
                return <TableCellVirtualized key={key}
                                             parent={parent}
                                             style={style}
                                             columnIndex={columnIndex}
                                             cellMeasurerCache={_cache}
                                             hoveredRowIndex={hoveredRowIndex}
                                             handleCellClick={handleCellClick}
                                             serverColumn={serverColumn}
                                             rowIndex={rowIndex}>
                    {content}
                </TableCellVirtualized>;

            }
        }


    }

    function toggleViewColumn(index) {
        const action = !props.columns[index].visible ? 'add' : 'remove';
        const changedColumn = props.columns[index].name;
        props.options.onViewColumnsChange(changedColumn, action);
    }

    function handleCellClick(rowIndex, columnIndex) {
        const rowData = props.data[rowIndex - 1]
        const rowMeta = {dataIndex: columnIndex, rowIndex: rowIndex - 1}
        props.options.onRowClick(rowData, rowMeta)
    }

    const dataLength = props.tool.toolData?.length || 0;

    return (
        <div style={{display: "flex", flexDirection: "column", width: "100%", height: "100%"}}
             onClick={console.log}>
            <TableToolbar
                columns={props.columns}
                tool={props.tool}
                data={props.tool.toolData}
                filterList={props.tool.filterList}
                options={props.options}
                resetFilters={props.resetFilters}
                searchText={'asdf'}
                searchTextUpdate={props.searchTextUpdate}
                searchClose={props.searchClose}
                tableRef={props.getTableContentRef}
                title={<DataTableTitle tool={props.tool} showTitle={false}/>}
                toggleViewColumn={toggleViewColumn}
                updateColumns={props.updateColumns}
                setTableAction={props.setTableAction}
            />
            {props.columns.length > 0 && <TableFilterList
                options={props.options}
                filterListRenderers={props.columns.map(c => {
                    if (c.options.customFilterListOptions && c.options.customFilterListOptions.render) return c.options.customFilterListOptions.render;
                    // DEPRECATED: This option is being replaced with options.customFilterListOptions.render
                    if (c.options.customFilterListRender) return c.options.customFilterListRender;

                    return f => f;
                })}
                customFilterListUpdate={props.columns.map(c => {
                    return c.options.customFilterListOptions && c.options.customFilterListOptions.update
                        ? c.options.customFilterListOptions.update
                        : null;
                })}
                filterList={props.tool.filterList}
                filterUpdate={handleFilterUpdate}
                columnNames={columnNames}
            />}

            {props.tool.isLoading &&
                <CircularProgress
                    id={props.tool?.title.replaceAll(" ", "_") + "_loading"}
                    style={{
                        margin: "20px",
                        marginLeft: "40vw",
                        color: progressSpinnerColor.hexa(),
                        float: "left"
                    }}/>}
            {!props.tool.isLoading && props.tool.initialized &&
                <div style={{
                    width: "100%",
                    height: "100%",
                    marginLeft: "-1px",
                    marginBottom: "0"
                }}
                     onClick={console.log}>
                    <AutoSizer>
                        {({width, height}) => (
                            <MultiGrid
                                cellRenderer={_cellRenderer}
                                columnWidth={_cache.columnWidth}
                                columnCount={columnCount}
                                height={height}
                                fixedColumnCount={0}
                                fixedRowCount={dataLength === 0 ? 0 : 1}
                                overscanRowCount={10}
                                overscanColumnCount={10}
                                rowHeight={({index}) => index === 0 ? (longestColumnName === 3 ? 60 : 46.28) : rowHeight}
                                rowCount={rowCount}
                                width={width}
                                hideBottomLeftGridScrollbar
                                hideTopRightGridScrollbar
                            />
                        )}
                    </AutoSizer>
                </div>}
            {!props.tool.isLoading && props.tool.initialized && dataLength === 0 &&
                <div style={{
                    position: 'fixed',
                    top: "185px",
                    left: "500px"
                }} id={props.tool?.title.replaceAll(" ", "_") + "_noRecordsFound"}>Sorry, no
                    matching records
                    found</div>}

        </div>
    );
};