import {Filter, MuiTableColumn, ServerColumn} from "../../../classes";

export type FilterOptions = {
    names?: any[],
    logic: (value: any, filters: any) => boolean,
    display: (filterList: any[], onChange: any, index: number, muiTableColumn: MuiTableColumn, filterData: any, toolFilters: Filter[], displayAsList: boolean, multiselect: boolean) => any
};
export type CustomFilterListOptions = {
    render: (v: any) => string,
    update?: (filterList: any[], filterPos: number, index: number) => any[]
};
export default class ColumnComponent {
    getDisplayValue(value: any, stringsOnly = false) {
        return value
    }

    getCustomFilterListOptions(rc: ServerColumn): CustomFilterListOptions {
        return {
            render: (v: any) => rc.title + ": " + v
        };
    }

    getSortCompare(rc: ServerColumn) {
        return null;
    }

    getFilterOptions(toolUUID: string | null, tableName: string, serverColumn: ServerColumn): FilterOptions | null {
        return null;
    }

    getFilterType(rc: ServerColumn) {
        return "custom";
    }

    getFilter(column: ServerColumn, filterArray: any): Filter | null {

        let isFullValue = false


        if (!filterArray || filterArray.length === 0) {
            return null;
        }

        if (filterArray?.find((a: any) => a !== undefined && a !== null) === undefined) {
            return null;
        }

        if (filterArray.length == 2 && filterArray[1] == "fullvalue") {
            isFullValue = true
        }

        const filter: Filter = new Filter(column.attr);

        const comparison = isFullValue ? "eq" : "contains";
        const value = filterArray[0];

        filter.addCriteria(comparison, value);

        return filter;

    }


}

