import {CircularProgress, FormControl, Grid, InputLabel, MenuItem, Select} from "@mui/material";
import React, {memo, useContext} from "react";
import {
    chartBgColor,
    chartChartTypeBgColor,
    chartContainerColor,
    chartFilterBgColor,
    DEFAULT_CHART_COLORS,
    progressSpinnerColor
} from "../../styles/getMuiTheme";
import {makeStyles} from "@mui/styles";
import PropTypes from "prop-types";
import {MyDataContext} from "../../ToolsComponent";
import {Chart} from "./react-google-charts";
import {useCustomEventListener} from 'react-custom-events'
import {MuiTableColumn, ServerColumn} from "../../classes";
import {getMuiTableColumn} from "../../ColumnsUtil";
import useDeepCompareEffect from "use-deep-compare-effect";
import cloneDeep from "lodash.clonedeep";

const useStyles = (props: any) => makeStyles((theme) => {

    let chartContainerHeight: string;

    let options = props.tool.options?.chartOptions

    if (props.tool.chartTypes?.length > 1) {
        chartContainerHeight = 'calc(100% - 74px)';
    } else {
        chartContainerHeight = "100%";
    }
    return ({
        container        : {
            display        : "flex",
            flexDirection  : "column",
            height         : "100%",
            backgroundColor: chartFilterBgColor.hexa(),
        },
        topBar           : {
            display        : "flex",
            borderBottom   : "1px solid #EAEAEB",
            alignItems     : "baseline",
            backgroundColor: chartChartTypeBgColor.hexa()
        },
        chartTypeSelect  : {
            fontSize: "13px",
            margin  : "10px !important"
        },
        chartContainer   : {
            height         : chartContainerHeight,
            backgroundColor: chartContainerColor.hexa()
        },
        noDataMessage    : {
            padding: "10px"
        },
        chartTitle       : {
            fontSize  : "18px",
            fontWeight: "600",
            margin    : "16px"
        },
        progressContainer: {
            display       : 'flex',
            alignItems    : 'center',
            justifyContent: 'center',
            width         : '100%',
            height        : '100%',
        },
        progressSpinner  : {
            color: progressSpinnerColor.hexa()
        }
    });
});

const useStylesForGrid = makeStyles((theme) => {
    return ({
        container: {
            margin           : "5px 0 0 10px !important",
            width            : "calc(100% - 10px) !important",
            "& .MuiGrid-item": {
                padding                 : "0 !important",
                margin                  : "0 5px !important",
                "& .MuiFormControl-root": {
                    minWidth: "100px"
                }
            }
        },
    })
})


const MemoChart = memo(Chart);

export default function ChartComponent(props: any) {
    const [state] = useContext(MyDataContext);

    let [filteredToolData, setFilteredToolData] = React.useState(props.tool.toolData ? props.tool.toolData : [])

    let options = props.tool.options?.chartOptions

    let multiselectFiltering  = options?.multiselectFiltering ? options?.multiselectFiltering === "true" : true
    let disableXAxisFiltering = options?.disableXAxisFiltering ? options?.disableXAxisFiltering === "true" : false
    let serverSideFiltering   = false

    const classes  = useStyles(props)()
    const classes2 = useStylesForGrid()

    /**
     * getDataRowByXAxisFilters
     *
     * This function returns the data row if its label (first item) matches all filters. Otherwise null
     *
     * @param dataRow       row of chart data
     * @param xAxisFilters  filters that have x-axis values
     */
    function getDataRowByXAxisFilters(dataRow: any, xAxisFilters: any[]) {
        const dataRowCopy = [...dataRow]

        if (xAxisFilters.length === 0) {
            return dataRowCopy;
        }

        const xAxisLabel       = dataRowCopy[0]
        const xAxisMatchExists = xAxisFilters.every((f: string[]) => f.find(v => xAxisLabel.toString().includes(v)))
        return xAxisMatchExists || disableXAxisFiltering ? dataRowCopy : null;
    }

    function getMonthName(monthNumber: number) {
        const date = new Date();
        date.setMonth(monthNumber - 1);

        return date.toLocaleString('en-US', {month: 'long'});
    }

    /**
     * refreshChartData takes the chart data set and performs filtering and aggregation on it.
     */
    function refreshChartData() {

        // If no data, no need to filter

        if (!props.tool.toolData || props.tool.toolData.length === 0) {
            return []
        }

        // Local copies of props to prevent global modification

        let toolData = [...props.tool.toolData]
        let localFilterList = cloneDeep(props.tool.filterList)

        // Make month numbers in the filters into names. Server needs numbers, but UI needs names.

        for (let i = 0; i < options.filters?.length; i++) {
            let filter = options.filters[i];
            if (filter.type.name === 'MONTH') {
                localFilterList[i] = localFilterList[i].map(getMonthName)
            }
        }

        // Get rid of any empty filters. TODO: is this needed? Can't we just read empty arrays down the line?

        let nonEmptyFilters = localFilterList?.filter((a: any) => a.length > 0) || []

        if (serverSideFiltering) {
            nonEmptyFilters = []
        }


        // Find filter values that have matches in Y-Axis labels (column headers).
        // Y-Axis labels are split by - (e.g. January - 2022 - Thing) and we check to see if filter value is in split array.
        // Must be full term match

        let yAxisLabels  = toolData[0].map((d: any) => typeof d === "string" ? d : d.label)
        let yAxisFilters = nonEmptyFilters.filter((filterArray: any) => {
            return filterArray.find((f: any) => {
                return yAxisLabels.find((y: any) => {
                    return y.split(" - ").includes(f.toString());
                });
            });
        })

        // Find filters matching the X-Axis data (first value in each row).
        // If that value (to string) includes the filter value, it gets included.
        // However, there is also an option to disable XAxis filtering

        let xAxisFilters = [] as any

        if (!disableXAxisFiltering) {
            toolData.forEach(dataRow => {
                const firstDataItem = dataRow[0].toString()
                const foundMatch    = nonEmptyFilters.find((filterArray: any) => {
                    return filterArray.find((filterValue: any) => {
                        return firstDataItem.includes(filterValue);
                    });
                })
                if (foundMatch) {
                    xAxisFilters.push(foundMatch)
                }
            });
        }

        // If there exists a filter without a match in either the Y or X axis,
        // this means that no data matches and therefore return []

        if (nonEmptyFilters.find((a: any[]) => !(xAxisFilters.includes(a) || yAxisFilters.includes(a)))) {
            return []
        }

        // Find data indexes matching the Y-Axis filters.
        // If no filters, then we include all indices.
        // Otherwise, we return the index if ALL Y-Axis filters have a match in the label.

        let yAxisFilteredIndices = [0]

        for (let i = 1; i < yAxisLabels.length; i++) {
            const labelStrs = yAxisLabels[i].split(" - ")

            if (yAxisFilters.length === 0 || yAxisFilters.every((yAxisFilterArray: any) => {
                return yAxisFilterArray.find((yAxisFilterValue: any) => {
                    return labelStrs.includes(yAxisFilterValue.toString());
                });
            })) {
                yAxisFilteredIndices.push(i)
            }
        }

        // Create new header row. If there are no Y Axis filters, then this is simply the existing header row.
        // Otherwise, use yAxisFilteredIndices to push only the filtered column headers on there.

        let newHeaderRow

        if (yAxisFilters.length === 0) {
            newHeaderRow = toolData[0]
        } else {
            // Build filtered header row
            let headerRow = toolData[0]
            newHeaderRow  = []

            for (let i = 0; i < yAxisFilteredIndices.length; i++) {
                newHeaderRow.push(headerRow[yAxisFilteredIndices[i]])
            }
        }

        // Create filtered data array, starting with the header row.

        let filteredData = [newHeaderRow]

        for (let i = 1; i < toolData.length; i++) {
            // Local copy
            const dataRow = [...toolData[i]];

            // If first item in data row is empty string, include it.
            // This allows empty columns on dual charts when filtered.
            if (dataRow[0] === '') {
                filteredData.push(dataRow)
                continue
            }

            let xAxisFilteredRow = getDataRowByXAxisFilters(dataRow, xAxisFilters);

            if (xAxisFilteredRow) {

                // If no y-axis filters, go ahead and include the full row.
                // Otherwise, use the yAxisFilteredIndices to include only that data.
                if (yAxisFilters.length === 0) {
                    filteredData.push(xAxisFilteredRow)
                } else {
                    let yAxisFilteredDataRow = []

                    for (let i1 = 0; i1 < yAxisFilteredIndices.length; i1++) {
                        yAxisFilteredDataRow.push(xAxisFilteredRow[yAxisFilteredIndices[i1]])
                    }

                    filteredData.push(yAxisFilteredDataRow)
                }
            }
        }

        if (options?.xAxisLimit) {
            filteredData = filteredData.slice(0, options?.xAxisLimit)
        }

        if (options?.legendAgg) {
            return getAggregatedData(filteredData, options?.legendAgg)
        }

        return filteredData
    }

    /**
     *
     *
     * @param originalData the dataset being used by the chart
     * @param legendAgg     the legend aggregation definition
     */
    function getAggregatedData(originalData: any, legendAgg: string) {

        // If no agg definition, return data.
        if (!legendAgg) {
            return originalData
        }

        let currentAgg = ''

        const newData = originalData.map((d: any) => [d[0]])
        const headerRow = originalData[0];

        if (legendAgg === 'SUM') {

            for (let i = 1; i < headerRow.length; i++) {

                const rowName = headerRow[i]
                const label   = typeof rowName === 'string' ? rowName : rowName.label;
                const agg     = label.split(" - ")[0];

                if (currentAgg === agg) {
                    for (let rowIndex = 1; rowIndex < newData.length; rowIndex++) {
                        const lastItemIndex              = (newData[rowIndex].length) - 1;
                        newData[rowIndex][lastItemIndex] = (newData[rowIndex][lastItemIndex] as number) + (originalData[rowIndex][i] as number)
                    }
                } else {
                    for (let rowIndex = 0; rowIndex < newData.length; rowIndex++) {
                        if (rowIndex === 0) {
                            newData[rowIndex].push(agg)
                        } else {
                            newData[rowIndex].push(originalData[rowIndex][i])
                        }
                    }
                    currentAgg = agg
                }

            }
        } else {

            let aggregates = {} as any

            legendAgg.split(",").forEach((s: string) => {
                const aggStr          = s.split(":");
                aggregates[aggStr[0]] = aggStr[1];
            })


            for (let i = 1; i < newData.length; i++) {

                const originalDataRow = originalData[i];
                const newDataRow      = newData[i];

                for (let i1 = 1; i1 < headerRow.length; i1++) {

                    const columnName = headerRow[i1]
                    const columnStr  = typeof columnName === 'string' ? columnName : columnName.label;
                    const aggKeys    = Object.keys(aggregates);
                    const aggKey     = aggKeys.find((k: string) => columnStr.indexOf(k) >= 0) as string
                    const aggOp      = aggregates[aggKey] as "SUM" | "AVG"
                    const aggIndex   = aggKeys.indexOf(aggKey) + 1

                    const originalDataColValue = originalDataRow[i1];
                    const newDataColValue      = originalDataColValue !== null ? parseFloat(originalDataColValue) : null;


                    if (newDataRow.length > aggIndex) {
                        if (newDataColValue !== null) {
                            switch (aggOp) {
                                case "SUM":
                                    newDataRow[aggIndex] = parseFloat(newDataRow[aggIndex]) + newDataColValue
                                    break;
                                case "AVG":
                                    newDataRow[aggIndex].push(newDataColValue)
                                    break;

                            }
                        }
                    } else {
                        if (i === 1) {
                            newData[0].push(aggKey)
                        }
                        if (aggOp === 'AVG') {
                            newDataRow.push(newDataColValue !== null ? [newDataColValue] : [])
                        } else {
                            newDataRow.push(newDataColValue !== null ? newDataColValue : 0)
                        }
                    }
                }
            }

            for (let i = 1; i < newData.length; i++) {
                const newDataRow = newData[i];
                for (let i1 = 1; i1 < newDataRow.length; i1++) {
                    if (Array.isArray(newDataRow[i1])) {
                        let reduce = 0
                        newDataRow[i1].forEach((d: number) => reduce += d)
                        const length = newDataRow[i1].length;
                        newDataRow[i1] = reduce / length
                    }
                }
            }

        }

        return newData
    }

    useDeepCompareEffect(() => {
        setFilteredToolData([])
        state.handleChartRefresh()
        setFilteredToolData(refreshChartData())
        state.handleChartRefresh()

    }, [props.tool.filterList, props.tool.toolData]);

    useCustomEventListener('google-chart-select', (chartWrapper: any) => {
        const chart          = chartWrapper?.getChart();
        const chartSelection = chart.getSelection();
        state.handleChartDataClick(props.tool, chartSelection, filteredToolData)

    })

    function getOptions(chartType: string, initialOptions: any) {
        if (chartType === 'DualYStackedBarChart') {
            return {
                ...(initialOptions || {}),
                height         : "100%",
                backgroundColor: chartBgColor.hex(),
                isStacked      : true,
                chartArea      : {bottom: "125"},
                hAxis          : {
                    textPosition    : "out",
                    textStyle       : {fontSize: 12},
                    showTextEvery   : 1,
                    slantedText     : true,
                    slantedTextAngle: 45
                }
            }
        } else {
            return {
                ...(initialOptions || {}),
                backgroundColor: chartBgColor.hex(),
                height         : initialOptions?.height ? initialOptions.height : "calc(100% - 5px)"
            };
        }
    }

    const options1 = getOptions(props.tool.chartType, options);

    function mapData(data: any, halfCircle: boolean) {
        return [data[0], {
            v: data[1] * (halfCircle ? 0.5 : 1.0),
            f: Math.round((data[1] + Number.EPSILON) * 100) / 100 + "%"
        }];
    }

    function getMemoChart(data: any, options: any) {
        const chartType = props.tool.chartType === 'DualYStackedBarChart' ? 'ColumnChart' : props.tool.chartType;

        if (chartType === 'PieChart') {
            let dataheader = data[0]
            let dataArrays = []
            if (options.singleValueChart) {
                for (let i = 1; i < data.length; i++) {
                    dataArrays.push([dataheader, data[i]])
                }

            } else {
                dataArrays.push(data)
            }
            return <Grid container>{dataArrays.map((d: any, idx: number) => {

                let headerArray = d[0]
                let otherArrays = d.slice(1)


                if (options.singleValueChart) {
                    const emptySpaceSlice = [null, (100 - otherArrays[0][1])]
                    otherArrays.push(emptySpaceSlice)
                }

                const mappedData = otherArrays.map((d: any) => mapData(d, options.halfCircle));
                let data1        = [headerArray, ...mappedData]


                if (options.halfCircle) {
                    data1.push([null, otherArrays.map((a: any) => a[1]).reduce((acc: number, curr: number) => acc + curr, 0) * 0.5])
                }

                let newOptions    = cloneDeep(options)
                newOptions.slices = newOptions.slices || {}

                if (options.singleValueChart) {

                    newOptions.slices[0] = {color: DEFAULT_CHART_COLORS[idx]}
                    newOptions.slices[1] = {color: '#eee', textStyle: {color: '#eee'}, enableInteractivity: false}
                    if (options.halfCircle) {
                        newOptions.slices[2] = {color: 'transparent', enableInteractivity: false}
                    }
                } else {
                    for (let i = 0; i < otherArrays.length; i++) {
                        newOptions.slices[i] = {color: DEFAULT_CHART_COLORS[i]}
                    }
                    if (options.halfCircle) {
                        newOptions.slices[otherArrays.length + (options.singleValueChart ? 1 : 0)] = {
                            color              : 'transparent',
                            enableInteractivity: false
                        }
                    }
                }

                newOptions.title         = options.singleValueChart ? d[1][0] : newOptions.title
                newOptions.pieStartAngle = (options.halfCircle ? 270 : 'auto')

                const chartHeight = options.halfCircle ? ((newOptions.height * 0.5) + 6) + "px" : newOptions.height + "px"

                return <Grid item key={props.tool.name + idx}>
                    <div style={{height: chartHeight, overflow: "hidden"}}>
                        <MemoChart
                            controls={props.fakeControls}
                            legendToggle
                            legend_toggle
                            chartType={chartType}
                            data={data1}
                            options={newOptions}
                        />
                    </div>
                </Grid>;
            })}</Grid>
        } else {
            return <MemoChart
                controls={props.fakeControls}
                legendToggle
                legend_toggle
                chartType={props.tool.chartType === 'DualYStackedBarChart' ? 'ColumnChart' : props.tool.chartType}
                data={filteredToolData}
                options={options}
            />
        }


    }

    return <div className={classes.container}
                id={props.tool.title.replaceAll(" ", "_") + "_chart"}>
        {props.tool.chartTypes?.length > 1 ? <div className={classes.topBar}>
            {props.tool.chartTypes?.length > 1 ?
                <FormControl className={classes.chartTypeSelect}>
                    <InputLabel>Display Type</InputLabel>
                    <Select label={"Select Chart Type"}
                            sx={{fontSize: "14px"}}
                            value={props.tool.chartType || ''}
                            onChange={props.onTypeChange}>
                        {props.tool.chartTypes?.map((ct: string) => <MenuItem key={ct} value={ct}>{ct}</MenuItem>)}
                    </Select>
                </FormControl> : null}
        </div> : null}
        {props.tool.columns &&
            <Grid classes={classes2}
                  container
                  direction="row"
                  justifyContent="flex-start"
                  alignItems="center"
                  spacing={5}>
                {props.tool.columns && props.tool.columns.map((c: ServerColumn, i: number) => {
                    let tableNameOption = null
                    let columnOptions   = c.options
                    if (columnOptions) {
                        tableNameOption = columnOptions.find((o: {
                            Name: string,
                            Value: string
                        }) => o.Name === 'TableName')?.Value
                    }

                    if (!tableNameOption) {
                        tableNameOption = props.tool.dbName
                    }

                    const muiTableColumn = getMuiTableColumn(c, null, props.tool, i, tableNameOption)
                    const display        = muiTableColumn.options.filterOptions.display

                    //filterList: any[], onChange: any, index: number, column: MuiTableColumn
                    const onChange = (filterList: any[], index: number, muiTableColumn: MuiTableColumn) => {
                        state.handleChartFilterChange(props.tool, props.tool.filterList, true, serverSideFiltering);
                    };
                    return display(props.tool.filterList, onChange, i, muiTableColumn, null, [], false, multiselectFiltering)
                })}

            </Grid>}

        <div className={classes.chartContainer}
             id={props.tool.title.replaceAll(" ", "_") + "_chartContainer"}>
            {!props.tool.isLoading && filteredToolData && filteredToolData.length > 0 &&
                getMemoChart(filteredToolData, options1)
            }
            {!props.tool.isLoading && props.tool.initialized && (!filteredToolData || filteredToolData.length === 0) &&
                <div className={classes.noDataMessage}>No data found</div>}

            {(props.tool.isLoading || !props.tool.initialized) && <div className={classes.progressContainer}>
                <CircularProgress className={classes.progressSpinner}/>
            </div>}
            {props.tool.isLoading || !props.tool.initialized ? null :
                <span id={props.tool?.title.replaceAll(" ", "_") + "_loaded"}/>}

        </div>
    </div>;
}

ChartComponent.propTypes = {
    tool        : PropTypes.object.isRequired,
    onTypeChange: PropTypes.func.isRequired,
    fakeControls: PropTypes.array
}