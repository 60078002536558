import PropTypes from 'prop-types'
import {FormGroup, FormLabel} from "@mui/material";
import React from "react";
import {LocalizationProvider} from "@mui/x-date-pickers/LocalizationProvider";
import {AdapterMoment} from "@mui/x-date-pickers/AdapterMoment";
import {DateTimePicker} from "@mui/x-date-pickers";
import moment from 'moment'
import {CustomTextField} from "../../forms/customMappers/CustomTextField";

export default function TimestampFilter(props) {
    const filterArray = props.filterList[props.index];

    if (!filterArray) {
        return null
    }

    if (filterArray.length > 0 && typeof filterArray[0] === 'string') {
        filterArray[0] = moment(filterArray[0])
    }

    if (filterArray.length > 1 && typeof filterArray[1] === 'string') {
        filterArray[1] = moment(filterArray[1])
    }

    return <>
        <FormLabel>{props.label}</FormLabel>
        <FormGroup row>
            <LocalizationProvider dateAdapter={AdapterMoment} adapterLocale={"en-us"}>
                <DateTimePicker
                    sx={{margin: "22px 0 5px 0"}}
                    slotProps={{
                        textField: {
                            inputProps: {
                                "id": props.column.testId + "_filter_from",
                                "inputMode": 'numeric'
                            }
                        }
                    }}
                    label={"From:"}
                    inputFormat="MM/DD/YYYY hh:mm:ss a"
                    value={filterArray[0] || null}
                    onChange={event => {
                        if (!event) {
                            filterArray[0] = null;
                            props.onChange(filterArray, props.index, props.column);
                        } else if (event.isValid()) {
                            filterArray[0] = event;
                            props.onChange(filterArray, props.index, props.column);
                        }

                    }}
                    renderInput={(params) => <CustomTextField {...params} error={false}/>}
                />
                <DateTimePicker
                    sx={{margin: "22px 0 5px 0"}}
                    slotProps={{
                        textField: {
                            inputProps: {
                                "id": props.column.testId + "_filter_to",
                                "inputMode": 'numeric'
                            }
                        }
                    }}
                    label={"To:"}
                    inputFormat="MM/DD/YYYY hh:mm:ss a"
                    value={filterArray[1] || null}
                    onChange={event => {
                        if (!event) {
                            filterArray[1] = null;
                            props.onChange(filterArray, props.index, props.column);
                        } else if (event.isValid()) {
                            filterArray[1] = event;
                            props.onChange(filterArray, props.index, props.column);
                        }
                    }}
                    renderInput={(params) => <CustomTextField {...params} error={false}/>}
                />
            </LocalizationProvider>
        </FormGroup>
    </>
}

TimestampFilter.propTypes = {
    column: PropTypes.object.isRequired,
    filterList: PropTypes.array.isRequired,
    index: PropTypes.number.isRequired,
    label: PropTypes.string.isRequired,
    onChange: PropTypes.func.isRequired
}