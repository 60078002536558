import jsonpath from "jsonpath";
import {CircularProgress, Table, TableBody, TableHead} from "@mui/material";
import React, {useContext} from "react";
import PropTypes from "prop-types";
import {getColumnComponent, getDataSourceColumnNamesInOrder, StyledToolTip} from "../../ColumnsUtil";
import TableRow from "@mui/material/TableRow";
import TableCell from "@mui/material/TableCell";
import {getColorFromText, progressSpinnerColor} from "../../styles/getMuiTheme";
import {FreeformToolContext} from "../../JsonTool";
import {ServerColumn} from "../../classes";

const StyledTableCell = (props: any) => {
    return <TableCell
        sx={{
            backgroundColor: getBgColor(props.rowIndex, props.meta),
            border         : props.meta.border || "0",
            color: getColorFromText(props.textColor || "#000"),
            overflow       : "hidden"
        }}
        key={JSON.stringify(props.meta)}
        rowSpan={props.rowSpan || 1}>
        <StyledToolTip title={props.children} key={JSON.stringify(props.meta)}>
            <div style={{width: '100%', whiteSpace: 'pre-wrap'}} key={JSON.stringify(props.meta)}>{props.children}</div>
        </StyledToolTip>
    </TableCell>

}

function getBgColor(rowIndex: number, md: any) {
    if (!md.zebraStripe) {
        return "#FFF"
    }

    return md.zebraStripe[rowIndex % md.zebraStripe.length]
}


export default function TableLayout(props: { meta: any, data: any }) {

    const [data, datasources] = useContext(FreeformToolContext);

    let dataSource: any

    if (props.meta.columnDatasource) {
        dataSource = datasources.find((ds: any) => props.meta.columnDatasource === ds.name)
    } else {
        dataSource = datasources.find((ds: any) => props.meta.data.startsWith("$." + ds.name))
    }

    let tableData: any

    try {

        if (props.data) {
            tableData = jsonpath.query(props.data, props.meta.data);
        } else {
            tableData = data ? jsonpath.query(data, props.meta.data) : null;
        }

        if (!tableData || tableData.length === 0) {
            tableData = [{'Result': 'No Data Found'}]
        }
    } catch (e) {
        tableData = [{'Result': 'No Data Found'}]
    }

    let mergeColumnIndices = [] as number[]


    let columnNamesInOrder = getDataSourceColumnNamesInOrder(dataSource)

    if (!columnNamesInOrder) {
        columnNamesInOrder = []
    }

    if (props.meta.allowedColumns) {
        columnNamesInOrder = columnNamesInOrder.filter((c: string) => props.meta.allowedColumns.includes(c))
    }

    if (props.meta.mergeColumns) {
        tableData.forEach((rowData: any, rowIndex: number) => {
            columnNamesInOrder.map((columnKey: any, columnIndex: number) => {
                const displayData             = rowData[columnKey];
                const isMergeColumn           = props.meta.mergeColumns?.indexOf(columnIndex) >= 0;
                const isFirstRow              = rowIndex === 0;
                const previousDataDoesntMatch = rowIndex > 0 && tableData[rowIndex - 1][columnKey] !== displayData;

                if (isMergeColumn && (isFirstRow || previousDataDoesntMatch)) {
                    mergeColumnIndices.push(rowIndex)
                }
            })
        })
    }

    function mapMergeColumns(columnKey: any, columnIndex: number, rowData: any, rowIndex: number) {
        if (props.meta.mergeColumns?.indexOf(columnIndex) >= 0) {
            if (!(rowIndex === 0 || tableData[rowIndex - 1][columnKey] !== rowData[columnKey])) {
                return null
            }

            let span          = 1
            let startRowIndex = rowIndex + 1

            while (startRowIndex < tableData.length && tableData[startRowIndex][columnKey] === tableData[rowIndex][columnKey]) {
                span++
                startRowIndex++
            }

            return mapColumn(columnKey, columnIndex, mergeColumnIndices.indexOf(rowIndex), rowData, span)
        } else {
            return mapColumn(columnKey, columnIndex, rowIndex, rowData)
        }
    }

    function mapColumn(columnKey: any, columnIndex: number, rowIndex: number, rowData: any, rowSpan: number = 1) {
        const value        = rowData[columnKey];
        const serverColumn = dataSource.columns.find((c: ServerColumn) => c.title === columnKey)
        const display      = getColumnComponent(serverColumn).getDisplayValue(value);

        return <StyledTableCell meta={props.meta}
                                rowSpan={rowSpan}
                                key={`${columnKey}_${columnIndex}_${rowIndex}`}
                                rowIndex={rowIndex}>
            {display}
        </StyledTableCell>
    }

    function mapHeader(columnKey: any, columnIndex: number) {
        return <StyledTableCell meta={props.meta}
                                key={`${columnKey}_${columnIndex}_header`}
                                textColor={getColorFromText(props.meta.headerTextColor)}>
            <span style={{fontWeight: "bold",}}>{columnKey}</span>
        </StyledTableCell>
    }

    return tableData ? <Table sx={{border: props.meta.border}}>
        <TableHead
            sx={{backgroundColor: getColorFromText(props.meta.headerBGColor || "#eee"), border: props.meta.border}}>
            <TableRow sx={{border: props.meta.border}}>
                {columnNamesInOrder.map(mapHeader)}
            </TableRow>
        </TableHead>
        <TableBody sx={{border: props.meta.border}}>
            {tableData.map((rowData: any, rowIndex: number) => {
                return <TableRow
                    key={JSON.stringify(props.meta) + "_row" + rowIndex}
                    sx={{
                        borderBottom: props.meta.border,
                        borderTop   : props.meta.border,
                    }}>
                    {props.meta.mergeColumns ?
                        columnNamesInOrder.map((columnKey: any, columnIndex: number) => mapMergeColumns(columnKey, columnIndex, rowData, rowIndex))
                        : columnNamesInOrder.map((columnKey: any, columnIndex: number) => mapColumn(columnKey, columnIndex, rowIndex, rowData))
                    }
                </TableRow>
            })}
        </TableBody>
    </Table> : <div style={{
        border        : props.meta.border,
        display       : "flex",
        flex          : 1,
        overflow      : 'hidden',
        minHeight     : "100%",
        minWidth      : "100%",
        alignItems    : 'center',
        flexDirection : 'column',
        justifyContent: 'center'
    }}><CircularProgress style={{
        color: progressSpinnerColor.hexa()
    }}/></div>

}

TableLayout.propTypes = {
    meta: PropTypes.any.isRequired,
    data: PropTypes.any
}