import React from "react";

function LogoComponent(props) {
    const images = require.context('../../images', true);

    let logo
    let alt

    switch (window.location.host) {
        case 'localhost:3000':
            logo = 'mike.png'
            alt = 'Mike\'s Bargain Health Insurance'
            break
        case 'demo.lionhillsusa.com':
            logo = 'lionhillslogo.png'
            alt = 'Lion Hills'
            break
        case 'iris.intus.care':
            logo = 'intuscare.png'
            alt = 'Intus Care'
            break
        default:
            logo = 'lionhillslogo.png'
            alt = 'A website'
    }
    return (
        <div style={{
            display: "flex",
            alignItems: 'center',
            justifyContent: 'center',
            paddingTop: "0", paddingBottom: "0"
        }} onClick={props.handleClick}>
            {props.isCollapsed ?
                <img height={'80%'} width={'80%'} style={{margin: "10px"}} src={images("./" + logo)} alt={alt}/> :
                <img height={props.height || "50px"} style={{margin: "10px"}} src={images("./" + logo)} alt={alt}/>}

        </div>
    )
}


export default LogoComponent;