import React, {useEffect} from 'react';
import useFieldApi from "@data-driven-forms/react-form-renderer/use-field-api";
import {Checkbox, FormControlLabel, Radio, Table, TableBody} from "@mui/material";
import TableRow from "@mui/material/TableRow";
import TableCell from "@mui/material/TableCell";
import MuiTableHead from "@mui/material/TableHead";

export default function MatrixField(props: any) {
    const {
              label,
              input,
              matrix
          } = useFieldApi(props);

    let [fieldValue, setFieldValue] = React.useState([] as any[])

    function onCheckboxChange(row: any, col: any, checked: boolean) {
        const value = row + matrix.separator + col;

        let newValue = []

        if (fieldValue.includes(value) && !checked) {
            let arr = [...fieldValue]
            arr.splice(arr.indexOf(value), 1)
            newValue = arr
        } else if (!fieldValue.includes(value) && checked) {
            newValue = [...fieldValue, value]
        }

        setFieldValue(newValue)
        if (matrix.stringValue) {
            input.onChange(newValue.join(","))
        }
    }

    function onRadioChange(row: any, col: any) {
        const value = row + matrix.separator + col;

        let newValue = []

        if (!fieldValue.includes(value)) {
            let arr = [...fieldValue]
            let existingValues
            let isnull
            if (matrix.input === "RADIO_ROW") {
                isnull         = matrix.nullValue === col
                existingValues = arr.filter((x: any) => x.startsWith(row) && x !== value)
            } else {
                isnull         = matrix.nullValue === row
                existingValues = arr.filter((x: any) => x.endsWith(col) && x !== value)
            }

            if (existingValues) {
                existingValues.forEach(v => arr.splice(arr.indexOf(v), 1))
            }
            if (!isnull) {
                arr.push(value)
            }
            arr = arr.filter((x: any) => x !== "")
            arr.sort()
            newValue = arr
        }

        setFieldValue(newValue)
        if (matrix.stringValue) {
            input.onChange(newValue.join(","))
        }

    }

    function isChecked(row: any, col: any) {
        const find = fieldValue.find(f => f === row + matrix.separator + col);
        if (find) {
            return true
        }

        if (matrix.input === "RADIO_ROW") {
            if (matrix.nullValue === col) {
                return fieldValue.find(f => f.startsWith(row)) === undefined
            }
        } else if (matrix.input === "RADIO_COLUMN") {
            if (matrix.nullValue === row) {
                return fieldValue.find(f => f.endsWith(col)) === undefined
            }
        }
        return false

    }

    useEffect(() => {
        if (matrix.stringValue) {
            setFieldValue(input.value.split(","))
        } else {
            setFieldValue(input.value)
        }

    }, []);

    return (
        <div style={{margin: "22px 0 5px 0"}}>
            <Table>
                <MuiTableHead>
                    <TableRow>
                        <TableCell>{label}</TableCell>
                        {matrix.columnOptions.map((c: any) => <TableCell key={c}>{c}</TableCell>)}
                    </TableRow>
                </MuiTableHead>
                <TableBody>
                    {matrix.rowOptions.map((r: any) => {
                        return <TableRow key={r}>
                            <TableCell>{r}</TableCell>
                            {matrix.columnOptions.map((c: any) => {
                                if (matrix.input === "CHECKBOX") {
                                    return <TableCell key={c}>
                                        <FormControlLabel
                                            label=""
                                            control={
                                                <Checkbox
                                                    onChange={(e) => onCheckboxChange(r, c, e.target.checked)}
                                                />
                                            }
                                        /></TableCell>
                                } else {
                                    return <TableCell key={c}>
                                        <Radio value={c}
                                               checked={isChecked(r, c)}
                                               onChange={(e) => onRadioChange(r, c)}
                                        /></TableCell>

                                }
                            })}
                        </TableRow>
                    })}
                </TableBody>
            </Table>
        </div>
    );
}
