import PropTypes from 'prop-types'
import {Autocomplete, CircularProgress, FormControl, Grid} from "@mui/material";
import React, {useContext, useState} from "react";
import {MyDataContext} from "../../../ToolsComponent";
import {CustomTextField} from "../../forms/customMappers/CustomTextField";


export default function TextFilter(props: any) {
    const [state] = useContext(MyDataContext);

    const [selectOptions, setSelectOptions] = useState([]);
    const [loading, setLoading]             = useState(false);

    let searchStuff         = async (value: any) => {
        setLoading(true)
        setSelectOptions([])
        const textSearch = await state.getTextSearch(props.toolUUID, props.column.name, props.tableName, value);
        setSelectOptions(textSearch)
        console.log("Select", textSearch)
        setLoading(false)
    }
    const filterListElement = props.filterList && props.filterList[props.index] && props.filterList[props.index].length ? props.filterList[props.index] : [];

    const renderInput   = (params: any) => {
        return <CustomTextField {...params}
                                id={props.column.testId + "_filter"}
                                label={props.column.label}
                                InputProps={{
                                    ...params.InputProps,
                                    endAdornment: (
                                        <React.Fragment>
                                            {loading ?
                                                <CircularProgress color="inherit" size={20}/> : null}
                                            {params.InputProps.endAdornment}
                                        </React.Fragment>
                                    ),
                                }}/>
    };
    const onInputChange = (event: any) => {
        let value = event.target.textContent ? event.target.textContent : event.target.value;
        console.log("text filter", value)
        if (event.target.value && event.target.value.length >= 2) {
            searchStuff(value)
        }
        props.onChange(value);
    };
    const onChange      = (_: any, newValue: any) => { //only send onChange if the event type is a click
        if (_.type == "click") {
            props.onChange([newValue])
        }
    };
    return filterListElement ? <div>
        <Grid item
              key={props.index}>
            <FormControl key={props.index}>
                <Autocomplete
                    freeSolo
                    inputValue={filterListElement[0] || ''}
                    filterOptions={(x) => x}
                    options={selectOptions}
                    onChange={onChange}
                    onInputChange={onInputChange}
                    renderInput={renderInput}
                />
            </FormControl>
        </Grid>
    </div> : null
}

TextFilter.propTypes = {
    toolUUID: PropTypes.string,
    column    : PropTypes.object.isRequired,
    filterList: PropTypes.array.isRequired,
    index     : PropTypes.number.isRequired,
    label     : PropTypes.string.isRequired,
    onChange  : PropTypes.func.isRequired,
    tableName : PropTypes.string.isRequired
}