import React, {useEffect} from 'react';
import {Route, Routes} from "react-router-dom";
import {MsalProvider, useMsal} from '@azure/msal-react';
import {EventType} from '@azure/msal-browser';

import {PageLayout} from './components/PageLayout';
import {b2cPolicies, protectedResources} from './auth/authConfig';
import {compareIssuingPolicy} from './util/claimUtils';

import './styles/App.css';
import ToolsComponent from "./ToolsComponent";
import {Helmet} from "react-helmet";


const Pages = () => {
    /**
     * useMsal is hook that returns the PublicClientApplication instance,
     * an array of all accounts currently signed in and an inProgress value
     * that tells you what msal is currently doing. For more, visit:
     * https://github.com/AzureAD/microsoft-authentication-library-for-js/blob/dev/lib/msal-react/docs/hooks.md
     */
    const {instance} = useMsal();
    useEffect(() => {
        const callbackId = instance.addEventCallback((event: any) => {
            if (
                (event.eventType === EventType.LOGIN_SUCCESS || event.eventType === EventType.ACQUIRE_TOKEN_SUCCESS) &&
                event.payload.account
            ) {
                /**
                 * For the purpose of setting an active account for UI update, we want to consider only the auth
                 * response resulting from SUSI flow. "tfp" claim in the id token tells us the policy (NOTE: legacy
                 * policies may use "acr" instead of "tfp"). To learn more about B2C tokens, visit:
                 * https://docs.microsoft.com/en-us/azure/active-directory-b2c/tokens-overview
                 */
                if (compareIssuingPolicy(event.payload.idTokenClaims, b2cPolicies.names.editProfile)) {
                    // retrieve the account from initial sing-in to the app
                    const originalSignInAccount = instance
                        .getAllAccounts()
                        .find(
                            (account: any) =>
                                account.idTokenClaims.oid === event.payload.idTokenClaims.oid &&
                                account.idTokenClaims.sub === event.payload.idTokenClaims.sub &&
                                compareIssuingPolicy(account.idTokenClaims, b2cPolicies.names.signUpSignIn)
                        );

                    let signUpSignInFlowRequest = {
                        authority: b2cPolicies.authorities.signUpSignIn.authority,
                        account  : originalSignInAccount,
                    };

                    // silently login again with the signUpSignIn policy
                    instance.ssoSilent(signUpSignInFlowRequest);
                }

                /**
                 * Below we are checking if the user is returning from the reset password flow.
                 * If so, we will ask the user to reauthenticate with their new password.
                 * If you do not want this behavior and prefer your users to stay signed in instead,
                 * you can replace the code below with the same pattern used for handling the return from
                 * profile edit flow
                 */
                if (compareIssuingPolicy(event.payload.idTokenClaims, b2cPolicies.names.forgotPassword)) {
                    let signUpSignInFlowRequest = {
                        authority: b2cPolicies.authorities.signUpSignIn.authority,
                        scopes   : [
                            ...protectedResources.apiTodoList.scopes.read,
                            ...protectedResources.apiTodoList.scopes.write,
                        ],
                    };
                    instance.loginRedirect(signUpSignInFlowRequest);
                }
            }

            if (event.eventType === EventType.LOGIN_FAILURE) {
                // Check for forgot password error
                // Learn more about AAD error codes at https://docs.microsoft.com/en-us/azure/active-directory/develop/reference-aadsts-error-codes
                if (event.error && event.error.errorMessage.includes('AADB2C90118')) {
                    const resetPasswordRequest = {
                        authority: b2cPolicies.authorities.forgotPassword.authority,
                        scopes   : [],
                    };
                    instance.loginRedirect(resetPasswordRequest);
                }
            }
        });

        return () => {
            if (callbackId) {
                instance.removeEventCallback(callbackId);
            }
        };
        // eslint-disable-next-line
    }, [instance]);

    return (
        <Routes>
            <Route path="/" element={<ToolsComponent/>}/>
        </Routes>
    );
};

/**
 * msal-react is built on the React context API and all parts of your app that require authentication must be
 * wrapped in the MsalProvider component. You will first need to initialize an instance of PublicClientApplication
 * then pass this to MsalProvider as a prop. All components underneath MsalProvider will have access to the
 * PublicClientApplication instance via context as well as all hooks and components provided by msal-react. For more, visit:
 * https://github.com/AzureAD/microsoft-authentication-library-for-js/blob/dev/lib/msal-react/docs/getting-started.md
 */
function App({instance}: any) {
    // {
    //     "ancestorOrigins": {},
    //     "href": "http://localhost:3000/",
    //     "origin": "http://localhost:3000",
    //     "protocol": "http:",
    //     "host": "localhost:3000",
    //     "hostname": "localhost",
    //     "port": "3000",
    //     "pathname": "/",
    //     "search": "",
    //     "hash": ""
    // }
    console.log(window.location)

    let favicon32: string
    let favicon16: string
    let description: string
    let title: string

    switch (window.location.host) {
        case 'localhost:3000':
            favicon32   = 'mike/favicon-32x32.png'
            favicon16   = 'mike/favicon-16x16.png'
            description = 'Mike\'s Bargain Health Insurance'
            title       = 'Mike\'s Bargain Health Insurance'
            break
        case 'demo.lionhillsusa.com':
            favicon32   = 'lionhills/favicon-32x32.png'
            favicon16   = 'lionhills/favicon-16x16.png'
            description = 'Lion Hills, LLC'
            title       = 'Lion Hills'
            break
        case 'iris.intus.care':
        case 'pace.lionhillsusa.com':
            favicon32   = 'intus/favicon-32x32.png'
            favicon16   = 'intus/favicon-16x16.png'
            description = 'Intus Care'
            title       = 'Intus Care'
            break
        default:
            favicon32   = 'lionhills/favicon-32x32.png'
            favicon16   = 'lionhills/favicon-16x16.png'
            description = 'A website'
            title       = 'A title'
    }

    return (
        <>
            <Helmet>
                <link rel="icon" type="image/png" sizes="32x32" href={"/" + favicon32}/>
                <link rel="icon" type="image/png" sizes="16x16" href={"/" + favicon16}/>
                <meta name="description" content={description}/>
                <title>{title}</title>
            </Helmet>
            <MsalProvider instance={instance}>
                <PageLayout>
                    <Pages/>
                </PageLayout>
            </MsalProvider>

        </>
    );
}

export default App;
