import React from "react";
import {useDrilldownStyles} from "./DrillDownModal";
import DrillDownTableRow from "./DrillDownTableRow";

export default function DrillDownSubTable(props) {
    let tableRows = props.tableRows
    let key = props.name
    let depth = props.depth

    let columnNames = tableRows[0] ? Object.keys(tableRows[0]) : []
    let classes = useDrilldownStyles({depth: depth})()

    if (typeof tableRows[0] === 'string') {
        return <div>{tableRows.map((r) => <div>{r}</div>)}</div>
    }

    return <table>
        <thead>
        <tr>
            {columnNames.map((k, i) => <th key={key + i} className={classes.tableHeader}>{k}</th>)}
        </tr>
        </thead>
        <tbody>
        {tableRows.map((row, i) => <DrillDownTableRow row={row} name={key + i} index={i} depth={depth}/>)}
        </tbody>
    </table>
}

