import React from 'react';
import {CircularProgress, Dialog} from "@mui/material";
import LogoComponent from "./icons/logoComponent";
import {dbLoadingModalBgColor, dbLoadingModalSpinnerColor, dbLoadingModalTxColor} from "../styles/getMuiTheme";


export default function LoadingModal(props) {

    const handleClose = (stuff) => {
        console.log(stuff)
    }

    return <Dialog open={true}
                   maxWidth={false}
                   onClose={handleClose}>
        <div style={{
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            backgroundColor: dbLoadingModalBgColor.hexa(),
            color: dbLoadingModalTxColor.hexa(),
            justifyContent: "center",
            width: '483px',
            height: '300px',
        }}>
            <div style={{
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'center',
                marginBottom: "20px",
                justifyContent: "center",
            }}>
                <LogoComponent/>
                {props.children}
            </div>
            <CircularProgress style={{color: dbLoadingModalSpinnerColor.hexa()}}/>
        </div>
    </Dialog>;
}

