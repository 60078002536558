import React, {useContext, useEffect, useState} from "react";
import {MyDataContext} from "../../ToolsComponent";
import {dragHandleColor, dragHandleColorHover, reallylightGray, whiteColor} from "../../styles/getMuiTheme";
import {makeStyles} from "@mui/styles";
import RGL, {WidthProvider} from "react-grid-layout";
import PropTypes from "prop-types";
import {DragHandle, InfoOutlined, OpenInFull} from "@mui/icons-material";
import BaseToolComponent from "../BaseToolComponent";
import {Tool} from "../../classes";
import {IconToolTip} from "../login/IconToolTip";
import ReactMarkdown from "react-markdown";
import gfm from "remark-gfm";
import BasicTabs from "../tabs/BasicTabs";
import LogoComponent from "../icons/logoComponent";

const ReactGridLayout = WidthProvider(RGL);

export default function ToolGridComponent(props: any) {

    const [state]                         = useContext(MyDataContext);
    const [fakeControls, setFakeControls] = useState([]);
    const [dimension, setDimension]       = useState([
        window.innerWidth,
        window.innerHeight,
    ]);

    const useStyles = makeStyles((theme) => {

        return ({
            layout       : {
                backgroundColor: whiteColor.hexa()
            },
            titleArea    : {
                fontSize       : "18px",
                fontWeight     : "600",
                padding        : "6px 16px",
                justifyContent : "space-between",
                display        : "flex",
                borderBottom   : "1px solid #EAEAEB",
                alignItems     : "center",
                backgroundColor: "#FFF"

            },
            title        : {
                display   : "flex",
                alignItems: "center",
                overflow  : "hidden",
            },
            titleText    : {
                whiteSpace  : "nowrap",
                overflow    : "hidden",
                textOverflow: "ellipsis",
                "&:hover"   : {
                    overflow  : "visible",
                    whiteSpace: "normal",
                    height    : "auto",  /* just added this line */
                }
            },
            toolArea     : {
                width   : "100%",
                height  : "100%",
                overflow: "auto"
            },
            gridItemIcons: {
                display   : "flex",
                alignItems: "center",
                color     : dragHandleColor.hexa(),
                '&:hover' : {
                    color : dragHandleColorHover.hexa(),
                    cursor: "pointer"
                }

            },
            gridItem     : {
                display        : "flex",
                flexDirection  : "column",
                boxShadow      : "0 0 2px 2px #eaeaea",
                overflow       : 'hidden',
                backgroundColor: "#FFF",
                borderRadius   : '5px',
                transform      : 'translate(10px, 10px)'
            }
        });
    });

    const classes = useStyles()

    const gridDivisions    = 12;
    const tabHeight = state.currentFrame.type === 'GROUP' && state.currentFrame.toolOrGroup?.tabs?.length > 0 ? props.existingTabPadding : 0;
    const breadCrumbHeight = 97;
    const pixelsBetween    = 10;

    const margin             = [pixelsBetween, pixelsBetween] as [number, number];
    const containerPadding   = [pixelsBetween, pixelsBetween] as [number, number]
    const paddingOutsideGrid = containerPadding[0] + containerPadding[1];
    const windowHeight       = dimension[1];
    const paddingBetweenRows = pixelsBetween * (gridDivisions - 1);


    const rowHeight = (windowHeight - (breadCrumbHeight + tabHeight + paddingBetweenRows + paddingOutsideGrid)) / gridDivisions;

    function handleLayoutChange(layout: any) {
        state.handleGridLayoutChange(props.toolGroupOrToolTab, layout)
        setFakeControls([])
    }

    useEffect(() => {
        const debouncedResizeHandler = debounce(() => {
            console.log('***** debounced resize', window.innerWidth, window.innerHeight); // See the cool difference in console
            setDimension([window.innerWidth, window.innerHeight]);
        }, 100); // 100ms
        window.addEventListener('resize', debouncedResizeHandler);
        return () => window.removeEventListener('resize', debouncedResizeHandler);
    }, []); // Note this empty array. this effect should run only on mount and unmount

    function debounce(fn: any, ms: any) {
        let timer: any;
        return (_: any) => {
            clearTimeout(timer);
            timer = setTimeout(_ => {
                timer = null;
                // @ts-ignore
                fn.apply(this, arguments);
            }, ms);
        };
    }

    if (props.toolGroupOrToolTab.tabs && props.toolGroupOrToolTab.tabs.length > 0) {
        return <BasicTabs groupOrTab={props.toolGroupOrToolTab} existingTabPadding={tabHeight}/>
    } else {
        return !props.toolGroupOrToolTab?.parsedGridLayout || !props.toolGroupOrToolTab?.parsedTools ?
            <div style={{
                display        : 'flex',
                flexDirection  : 'column',
                alignItems     : 'center',
                backgroundColor: reallylightGray.hexa(),
                color          : "#000",
                justifyContent : "center",
                width          : '100%',
                height         : '100%',
            }}>
                <div style={{
                    display       : 'flex',
                    flexDirection : 'column',
                    alignItems    : 'center',
                    marginBottom  : "20px",
                    justifyContent: "center",
                }}>
                    <LogoComponent/>
                    <div style={{fontWeight: "bold"}}>Coming Soon!</div>
                </div>
            </div> : <>
                <ReactGridLayout
                    margin={margin}
                    containerPadding={containerPadding}
                    className={classes.layout}
                    layout={props.toolGroupOrToolTab.parsedGridLayout}
                    cols={gridDivisions}
                    rowHeight={rowHeight}
                    draggableHandle=" .drag-handle"
                    onDragStop={handleLayoutChange}
                    onResizeStop={handleLayoutChange}>
                    {
                        props.toolGroupOrToolTab.parsedTools?.map((tool: Tool) => {
                            return <div key={tool.dbName}
                                        id={tool.title?.replaceAll(" ", "_") + "_gridcontainer"}
                                        className={classes.gridItem}>
                                <div className={classes.titleArea}>
                                    <div className={classes.title}>
                                        <div
                                            className={classes.titleText}>{tool.customTitle ? tool.customTitle : tool.title}</div>
                                        {tool.description &&
                                            <IconToolTip iconButton={<InfoOutlined style={{width: "18px"}}/>}
                                                         title={"Info"}>
                                                <ReactMarkdown remarkPlugins={[gfm]}>
                                                    {tool.description}
                                                </ReactMarkdown>
                                            </IconToolTip>}
                                    </div>
                                    <div className={classes.gridItemIcons}>
                                        <OpenInFull onClick={() => state.handleFullscreenClick(tool.uuid)}/>
                                        <DragHandle style={{fontSize: "28px"}} className="drag-handle"/>
                                    </div>
                                </div>
                                <div className={classes.toolArea}>
                                    <BaseToolComponent tool={tool}
                                                       key={tool.uuid}
                                                       showTitle={true}/>
                                </div>
                            </div>
                        })
                    }
                </ReactGridLayout>
            </>;
    }
}

ToolGridComponent.propTypes = {
    toolGroupOrToolTab: PropTypes.object,
    existingTabPadding: PropTypes.number
}