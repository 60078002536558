import PropTypes from 'prop-types'
import {Button, Checkbox, FormControl, Grid, InputLabel, ListItemText, MenuItem, Select} from "@mui/material";
import React, {useContext, useEffect, useState} from "react";
import {MyDataContext} from "../../../ToolsComponent";
import {Filter} from "../../../classes";
import {doPost} from "../../../util/RestUtil";
import {TABLE_ENUM_URL} from "../../../util/RestRoutes";


export default function YearFilter(props: any) {

    const [state] = useContext(MyDataContext);

    const renderValue                       = (values: any[]) => values && values.length > 0 ? values.map(v => v).join(', ') : "--";
    const [selectOptions, setSelectOptions] = useState(props.selectOptions && props.selectOptions.length > 0 ? props.selectOptions : null) as any[];
    const [loadError, setLoadError]         = useState(null)
    const [showSelect, setShowSelect]       = React.useState(false);

    const [value, setValue] = useState(selectOptions ? props.filterValue.filter((val: any) => selectOptions.includes(val)) : props.filterValue)

    const multiselect = props.multiselect !== null && props.multiselect !== undefined ? props.multiselect : true

    const displayAsList = props.displayAsList !== null && props.displayAsList !== undefined ? props.displayAsList : false

    const onOpen = async () => {
        setShowSelect(true)
        setLoadError(null)

        if (selectOptions && selectOptions.length > 0) {
            return;
        }
        try {
            const config   = {
                params: {
                    columnName: props.columnName,
                    tableName : props.tableName
                }
            };
            const response = await doPost(TABLE_ENUM_URL, {filters: props.existingFilters || []}, config, state.currentCustomer?.id) as any
            const years    = response.data
            years.sort()

            if (Array.isArray(props.filterValue)) {
                props.filterValue
                    .filter((v: number) => years.indexOf(v) === -1)
                    .forEach((v: number) => years.push(v));

            } else if (!years.includes(props.filterValue)) {
                years.push(props.filterValue)
            }

            years.sort((a: number, b: number) => b - a)
            setSelectOptions(years)
        } catch (e: any) {
            setLoadError(e.toString())
        }
    }

    useEffect(() => {
        if (displayAsList && (!selectOptions || selectOptions.length === 0)) {
            onOpen()
        }
    }, []);

    useEffect(() => {
        setValue(selectOptions ? props.filterValue.filter((val: any) => selectOptions.includes(val)) : props.filterValue)
    }, [props.filterValue]);

    function handleSelection(e: any) {
        const val = e

        let filter
        if (value.includes(val)) {
            filter = [...value].filter((v: any) => v !== val);
        } else {
            filter = [...value, val];
        }
        setValue(filter)
        props.onChange({target: {value: filter}})

    }

    return <Grid item key={props.testId}>
        <FormControl key={props.testId} style={{margin: "22px 0 5px 0"}}>
            <InputLabel htmlFor={props.columnName}>{props.label}</InputLabel>
            {displayAsList ? <div>

                {
                    selectOptions && selectOptions.length > 0 ? selectOptions.map((v: any) => {
                            const option  = v === null ? "(blank)" : v
                            const checked = props.filterValue.indexOf(option) >= 0;
                            return <MenuItem value={option}
                                             key={option}
                                             onClick={() => handleSelection(option)}
                                             id={props.testId + "_option_" + option}>
                                {multiselect && <Checkbox color="primary"
                                                          checked={checked}
                                                          value={option}/>}
                                <ListItemText primary={option}/>
                            </MenuItem>;
                        }) :
                        <MenuItem>
                            <ListItemText primary={loadError ? loadError : "Loading..."}/>
                        </MenuItem>
                }


            </div> : <Select
                id={props.testId + "_filter"}
                open={showSelect}
                multiple={multiselect}
                displayEmpty={true}
                value={value}
                renderValue={renderValue}
                name={props.columnName}
                onOpen={onOpen}
                onClose={() => setShowSelect(false)}
                onChange={props.onChange}>
                {
                    selectOptions ? selectOptions.map((v: any) => {
                            const option  = v === null ? "(blank)" : v
                            const checked = multiselect && props.filterValue.indexOf(option) >= 0;
                            return <MenuItem value={option}
                                             key={option}
                                             id={props.testId + "_option_" + option}>
                                {multiselect && <Checkbox color="primary"
                                                          checked={checked}
                                                          value={option}/>}
                                <ListItemText primary={option}/>
                            </MenuItem>;
                        }) :
                        <MenuItem>
                            <ListItemText primary={"Loading..."}/>
                        </MenuItem>
                }
                {multiselect &&
                    <Button id={props.columnName.replaceAll(" ", "_") + "_filter_close_button"} variant={'text'}
                            onClick={() => setShowSelect(false)}>
                        Close
                    </Button>}
            </Select>}
        </FormControl>
    </Grid>
}

YearFilter.propTypes = {
    filterValue    : PropTypes.array,
    multiselect    : PropTypes.bool,
    selectOptions  : PropTypes.array,
    columnName     : PropTypes.string.isRequired,
    testId         : PropTypes.string.isRequired,
    label          : PropTypes.string.isRequired,
    tableName      : PropTypes.string.isRequired,
    onChange       : PropTypes.func.isRequired,
    existingFilters: PropTypes.arrayOf(PropTypes.instanceOf(Filter)),
    displayAsList  : PropTypes.bool
}