import DrillDownAccordion from "./DrillDownAccordion";
import React from "react";
import DrillDownArray from "./DrillDownArray";
import DrillDownObject from "./DrillDownObject";
import {getFormattedKey} from "./DrillDownFormattingUtils";

export default function DrillDownLabeledArray(props) {

    let arr = props.arr
    let key = props.name
    let depth = props.depth

    return <DrillDownAccordion defaultExpanded={true}
                               summarytext={`${getFormattedKey(key)} (${arr.length})`}
                               depth={depth}>
        {
            arr.map(item => {
                if (typeof item === "object" && Array.isArray(item)) {
                    return <DrillDownArray  arr={item} name={key} depth={depth + 1}/>;
                } else {
                    return <DrillDownObject object={item} name={item["FormLabel"]} depth={depth+1}/>;
                }
            })
        }
    </DrillDownAccordion>
}
