import React, {useContext, useState} from "react";
import PropTypes from "prop-types";
import {Box, Tabs} from "@mui/material";
import Tab from "@mui/material/Tab";
import {mapLayout} from "./FreeformUtils";
import jsonpath from "jsonpath";
import {FreeformToolContext} from "../../JsonTool";
import {trueWhite} from "../../styles/getMuiTheme";

interface TabPanelProps {
    children?: React.ReactNode;
    index: number;
    value: number;
}

function TabPanel(props: TabPanelProps) {
    const {children, value, index, ...other} = props;

    return (
        <div
            role="tabpanel"
            style={{padding: "10px"}}
            hidden={value !== index}
            id={`simple-tabpanel-${index}`}
            aria-labelledby={`simple-tab-${index}`}
            {...other}
        >
            {value === index && <>{children}</>}
        </div>
    );
}

const styles = {
    '& .MuiTab-root'            : {
        backgroundColor: trueWhite.hexa(),
        textTransform  : "none",
        whiteSpace     : "pre",
        maxWidth       : "none",
        minHeight      : "42px",
        // boxShadow: "inset 0 0 5px gray",
        paddingTop   : "5px !important",
        paddingBottom: "5px !important",
        margin       : "6px 0 10px 10px",
        '&:hover'    : {
            backgroundColor: "#EFF8FF",
            color          : "#175CD3",
            opacity        : 1
        },
    },
    '& .MuiTabs-indicator'      : {
        backgroundColor: "#175CD3",
        marginBottom   : "10px"
    },
    '& .MuiTabScrollButton-root': {
        '&$disabled': {
            opacity: 0.2,
        },
    },
}

export default function TabsLayout(props: { meta: any, data: any }) {
    const [data, datasources, detailID, idColumnName] = useContext(FreeformToolContext);

    const [selectedTabIndex, setSelectedTabIndex] = useState(0)

    const updateTabIndex = (event: React.SyntheticEvent, newValue: number) => {
        setSelectedTabIndex(newValue)
    }

    return <Box sx={{width: '100%'}}>
        <Box sx={{borderBottom: 1, borderColor: 'divider'}}>
            <Tabs value={selectedTabIndex}
                  sx={styles}
                  onChange={updateTabIndex}
                  aria-label="basic tabs example">
                {props.meta.tabs.map((t: any) => {

                    if (t.options?.hideWhenNoData) {
                        try {
                            let tableData = data ? jsonpath.query(data, t.options?.hideWhenNoData) : null;
                            if (tableData === null || tableData === undefined) {
                                return null
                            } else {
                                return <Tab key={JSON.stringify(t)}
                                            sx={styles}
                                            id={t.name.replaceAll(" ", "_") + "_tab"}
                                            label={t.name}/>
                            }
                        } catch (e) {
                            return null
                        }
                    } else {
                        return <Tab key={JSON.stringify(t)}
                                    sx={styles}
                                    id={t.name.replaceAll(" ", "_") + "_tab"}
                                    label={t.name}/>
                    }

                })}
            </Tabs>
        </Box>
        {props.meta.tabs?.map((t: any, index: number) => {
            const tabContents = t.fields?.map((f: any) => mapLayout(f, props.data ? props.data : null));
            return <TabPanel key={JSON.stringify(t) + index} index={index}
                             value={selectedTabIndex}>{tabContents}</TabPanel>;
        })}
    </Box>
}
TabsLayout.propTypes = {
    meta: PropTypes.any.isRequired,
    data: PropTypes.any
}