import {Autocomplete, CircularProgress} from "@mui/material";
import React, {useEffect, useState} from "react";
import AutocompleterDisplay from "./AutocompleterDisplay";
import {CustomTextField} from "./CustomTextField";
import PropTypes from "prop-types";
import {doGet} from "../../../util/RestUtil";
import {debounce} from "lodash";

export default function AutocompleterComponent({
                                                   id,
                                                   isReadOnly,
                                                   isRequired,
                                                   label,
                                                   name,
                                                   onChange,
                                                   autocompleter,
                                                   url,
                                                   valid,
                                                   value,
                                                   customerObjectID,
                                               }: any) {

    const [searchResults, setSearchResults] = useState([]);
    const [loading, setLoading]           = useState(false);
    const [searchValue, setSearchValue]   = useState('');
    const [displayValue, setDisplayValue] = useState(autocompleter?.initialDisplayValue);

    // TODO: add placeholder to autocompleter input

    const renderInput   = (params: any) => {

        const labelEl = <>
            {label} {isRequired && !valid && <span style={{color: "#f00", fontSize: "0.75rem"}}>
            (Required)
            </span>}
        </>;

        const inputProps = {
            ...params.InputProps,
            endAdornment: <>
                {loading ? <CircularProgress color="inherit" size={20}/> : null}
                {params.InputProps.endAdornment}
            </>,
        };

        return <CustomTextField {...params}
                                id={id}
                                label={labelEl}
                                required={isRequired && !autocompleter?.clearDisplay}
                                InputProps={inputProps}/>;
    };
    const onValueChange = (e: any, newDisplayValue: any) => {
        setDisplayValue(newDisplayValue && !autocompleter?.clearDisplay ? newDisplayValue.displayName : null)
        if (autocompleter?.clearDisplay) {
            setSearchValue('')
        }
        onChange(e, newDisplayValue)
    };

    async function searchForValue(event: any, newSearchValue: any) {
        if (event.target.value && event.target.value.length >= 2) {
            setLoading(true)
            setSearchResults([])

            const response = await doGet(url, {params: {searchStr: newSearchValue}}, customerObjectID) as any
            const options  = response.data;

            setSearchResults(options)
            setLoading(false)
            setSearchValue(newSearchValue)
        }
    }

    const onInputChange = async (event: any) => {

        if (!event) {
            return
        }

        let newInputValue = event.target.textContent ? event.target.textContent : event.target.value;

        debouncedOnInputChange(event, newInputValue);
    };

    const debouncedOnInputChange = debounce(searchForValue, 500);

    const onDisplayClose = () => {
        setDisplayValue(null);
        setSearchValue('');
        onChange(null)
    };

    const filterOptions = (x: any) => x;
    const optionLabel   = (o: any) => o.displayName || '';


    useEffect(() => {
        if (value) {
            setLoading(true)
            doGet(`${url}/${value}PATH_UNIQUE_KEY=${name}###`, {}, customerObjectID).then((resp: any) => {
                setLoading(false)
                setDisplayValue(resp?.data?.displayName);
            })
        }
    }, []);

    return displayValue ? <AutocompleterDisplay displayValue={displayValue}
                                                isReadOnly={isReadOnly}
                                                onClose={onDisplayClose}
                                                label={label}/> :
        <Autocomplete key={name}
                      freeSolo
                      autoHighlight={true}
                      filterOptions={filterOptions}
                      value={value}
                      getOptionLabel={optionLabel}
                      options={searchResults}
                      onInputChange={onInputChange}
                      onChange={onValueChange}
                      renderInput={renderInput}
        />
}

AutocompleterComponent.propTypes = {
    id              : PropTypes.string.isRequired,
    isReadOnly      : PropTypes.bool,
    isRequired      : PropTypes.bool,
    label           : PropTypes.string.isRequired,
    name            : PropTypes.string.isRequired,
    onChange        : PropTypes.func.isRequired,
    autocompleter: PropTypes.object,
    url             : PropTypes.string.isRequired,
    valid           : PropTypes.bool,
    value           : PropTypes.any,
    customerObjectID: PropTypes.string
}
