import React, {useContext} from 'react';
import {Calendar, momentLocalizer} from 'react-big-calendar';
import moment from 'moment';
import 'react-datepicker/dist/react-datepicker.css';
import 'react-big-calendar/lib/css/react-big-calendar.css';
import {FreeformToolContext} from '../../JsonTool';
import jsonpath from 'jsonpath';


const localizer = momentLocalizer(moment);


export default function CalendarComponent(props: any) {

    const [data, datasources] = useContext(FreeformToolContext);


    let tableData: any

    let dataSource = datasources.find((ds: any) => props.meta.data.startsWith("$." + ds.name))


    try {
        tableData = data ? jsonpath.query(data, props.meta.data) : null;
        if (!tableData || tableData.length === 0) {
            tableData = [[{'Result': 'No Data Found'}]]
        }
    } catch (e) {
        tableData = [[{'Result': 'No Data Found'}]]
    }


    const newData = tableData.map((item: { start: string | number | Date; end: string | number | Date; }) => {
        // Convert date strings to Date objects
        const startDate = new Date(item.start);
        const endDate   = new Date(item.end);

        // Return a new object with Date objects
        return {
            ...item,
            start: startDate,
            end  : endDate
        };
    });


    return (
        <div className="app" style={{margin: "5px"}}>
            {/* <pre>{JSON.stringify(tableData, null, 2)},</pre> */}
            {/* { newData[0].CalendarName} */}

            <Calendar
                localizer={localizer}
                events={newData}
                startAccessor="start"
                endAccessor="end"
                style={{height: 500}}

            />

        </div>
    );
};

