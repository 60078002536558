import {CellMeasurer} from "react-virtualized";
import clsx from "clsx";
import {tableHeaderBGColor, tableHeaderBorderColor, themeColor1} from "../../../styles/getMuiTheme";
import {Tooltip} from "@mui/material";
import Button from "@mui/material/Button";
import TableSortLabel from "@mui/material/TableSortLabel";
import React, {useState} from "react";
import IconButton from "@mui/material/IconButton";
import {FilterList} from "@mui/icons-material";
import Popover from "../mui-datatables/components/Popover";
import PropTypes from "prop-types";
import cloneDeep from "lodash.clonedeep";
import {makeStyles} from "tss-react/mui";


const useStyles = makeStyles({name: 'MUIDataTableHeadCell'})(theme => ({
    root: {},
    fixedHeader: {
        position: 'sticky',
        top: '0',
        zIndex: 100,
        backgroundColor: tableHeaderBGColor.hexa(),
        borderRight: "1px solid " + tableHeaderBorderColor.hexa(),
        borderTop: "1px solid " + tableHeaderBorderColor.hexa(),
        borderBottom: "1px solid " + tableHeaderBorderColor.hexa(),
        padding: "0",
        width: "1px",
        "&:last-child": {
            width: "100% !important",
            backgroundColor: tableHeaderBGColor.hexa(),
        }

    },
    tooltip: {
        cursor: 'pointer',
    },
    filterPaper: {
        maxWidth: '50%',
        minWidth: '200px'
    },
    filterCloseIcon: {
        position: 'absolute',
        right: 0,
        top: 0,
        zIndex: 100,
    },
    mypopper: {
        '&[data-x-out-of-boundaries]': {
            display: 'none',
        },
    },
    data: {
        display: 'inline-block',
    },
    sortAction: {
        display: 'flex',
        cursor: 'pointer',
    },
    dragCursor: {
        cursor: 'grab',
    },
    sortLabelRoot: {
        height: '20px',
    },
    sortActive: {
        color: theme.palette.text.primary,
    },
    toolButton: {
        borderRadius: "0",
        width: "100%",
        height: "100%",
        justifyContent: "left",
        textTransform: 'none',
        margin: 0,
        '&:hover': {
            backgroundColor: themeColor1.fade(0.9).hexa(),
            color: themeColor1.hexa()
        },
        minWidth: 0,
        paddingLeft: '0',
        paddingRight: '8px',
    },
    filterIcon: {
        borderRadius: "0",
        '&:hover': {
            backgroundColor: themeColor1.fade(0.9).hexa(),
            color: themeColor1.hexa()
        },
    },
    contentWrapper: {
        display: 'flex',
        alignItems: 'center',
        '&:hover': {
            backgroundColor: themeColor1.fade(0.9).hexa(),
            color: themeColor1.hexa()
        },
    },
    contentWrapperHover: {
        display: 'flex',
        alignItems: 'center',
        backgroundColor: themeColor1.fade(0.9).hexa(),
        color: themeColor1.hexa()
    },
    hintIconAlone: {
        marginTop: '-3px',
        marginLeft: '3px',
    },
    hintIconWithSortIcon: {
        marginTop: '-3px',
    },
}));

export default function TableHeaderBeta(props) {
    const [filterTooltipOpen, setFilterTooltipOpen] = useState(false);
    const classes = useStyles()
    const sortActive = props.sortDirection && props.sortDirection !== 'none';
    const ariaSortDirection = props.sortDirection === 'none' ? false : props.sortDirection;
    const sortLabelProps = {
        classes: {root: props.classes.sortLabelRoot},
        tabIndex: -1,
        active: sortActive,
        hideSortIcon: true,
        ...(ariaSortDirection ? {direction: props.sortDirection} : {}),
    };
    const showFilterTooltip = () => setFilterTooltipOpen(true);
    const hideFilterTooltip = () => setFilterTooltipOpen(false);
    const [hideFilterPopover, setHideFilterPopover] = useState(true);
    const initialState = cloneDeep(props.filterList);
    const [filterList, setFilterList] = useState(initialState);

    function resetFilters() {
        const newFilterList = props.columns.map(() => []);
        setFilterList(newFilterList);
        props.filterReset();
    }

    function applyFilters() {
        props.filterUpdate(filterList)
        return filterList;
    }


    function handleFilterChange(value, index) {
        let newFilterList = filterList.slice(0);
        newFilterList[index] = value
        setFilterList(newFilterList);
    }

    function renderCustomField(column, index) {
        const width = (column.options.filterOptions && column.options.filterOptions.fullWidth) === true ? 12 : 6;
        const display = (column.options.filterOptions && column.options.filterOptions.display);

        if (!display) {
            console.error('Property "display" is required when using custom filter type.');
            return;
        }
        if (column.filterListOptions && column.filterListOptions.renderValue) {
            console.warning('"renderValue" is ignored for custom filter fields');
        }

        return <div style={{padding: "20px"}}>

            {display(filterList, handleFilterChange, props.columnIndex, props.column, [], null, true)}
            <Button color="primary"
                    variant={""}
                    onClick={() => {
                        setHideFilterPopover(true)
                        applyFilters();
                    }}>Apply Filters</Button>
            <Button color="primary"
                    className={props.classes.resetLink}
                    tabIndex={0}
                    aria-label={'Reset Filters'}
                    data-testid={'filterReset-button'}
                    onClick={resetFilters}>
                Reset Filters
            </Button>


        </div>
    }

    return <CellMeasurer cache={props.cache}
                         columnIndex={props.columnIndex}
                         key={props.key}
                         parent={props.parent}
                         rowIndex={props.rowIndex}>
        {({registerChild}) => (
            <div className={props.styles}
                 key={props.key}
                 ref={registerChild}
                 style={{
                     ...props.style,
                     whiteSpace: 'nowrap',
                     display: "flex",
                     backgroundColor: tableHeaderBGColor.hexa(),
                     borderRight: "1px solid " + tableHeaderBorderColor.hexa(),
                     borderTop: "1px solid " + tableHeaderBorderColor.hexa(),
                     borderBottom: "1px solid " + tableHeaderBorderColor.hexa()
                 }}>
                <div style={{flexGrow: 1}}>
                    <Tooltip title={'title'}
                             placement="bottom"
                             open={false}
                             onOpen={console.log}
                             onClose={console.log}
                             classes={{
                                 tooltip: props.classes.tooltip,
                                 popper: props.classes.mypopper,
                             }}>

                        <Button id={`${props.columnIndex}_sort`}
                                variant=""
                                onKeyUp={console.log}
                                onClick={() => props.toggleSort(props.columnIndex)}
                                className={props.classes.toolButton}
                                data-testid={`headcol-${props.columnIndex}`}
                                ref={null}>

                            <div className={props.classes.sortAction}>
                                <div className={clsx({
                                    [props.classes.data]: true,
                                    [props.classes.sortActive]: true,
                                    [props.classes.dragCursor]: false,
                                })}>
                                    <div style={{whiteSpace: "pre"}}>
                                        {props.children}
                                    </div>
                                </div>
                                <div className={props.classes.sortAction}>
                                    <TableSortLabel {...sortLabelProps} />
                                </div>
                            </div>
                        </Button>
                    </Tooltip>
                </div>
                <div>
                    <Popover
                        refExit={console.log}
                        hide={hideFilterPopover}
                        classes={{paper: props.classes.filterPaper, closeIcon: props.classes.filterCloseIcon}}
                        trigger={
                            <Tooltip title={'Filter'}
                                     onOpen={showFilterTooltip}
                                     onClose={hideFilterTooltip}
                                     open={filterTooltipOpen}
                                     disableFocusListener>
                                <IconButton
                                    className={props.classes.filterIcon}
                                    data-testid={props.key + '-' + 'Filter' + '-iconButton'}
                                    id={props.key + '-' + 'Filter' + '-iconButton'}
                                    aria-label={props.key}
                                    onClick={() => setHideFilterPopover(false)}>
                                    <FilterList
                                        style={{color: filterList[props.columnIndex]?.length > 0 ? themeColor1.hexa() : null}}/>
                                </IconButton>
                            </Tooltip>
                        }
                        content={renderCustomField(props.column, props.columnIndex)}
                    />
                </div>
            </div>
        )}
    </CellMeasurer>;
}
TableHeaderBeta.propTypes = {
    cache: PropTypes.any.isRequired,
    children: PropTypes.any.isRequired,
    classes: PropTypes.any.isRequired,
    column: PropTypes.any.isRequired,
    columnIndex: PropTypes.any.isRequired,
    filterList: PropTypes.any.isRequired,
    key: PropTypes.any,
    parent: PropTypes.any.isRequired,
    filterReset: PropTypes.any.isRequired,
    rowIndex: PropTypes.any.isRequired,
    sortDirection: PropTypes.any.isRequired,
    style: PropTypes.any.isRequired,
    styles: PropTypes.any.isRequired,
    toggleSort: PropTypes.any.isRequired

}