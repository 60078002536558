import React, {useContext, useEffect, useState} from 'react';
import PropTypes from "prop-types";
import {QUESTIONNAIRES_URL, TOOLS_URL} from "./util/RestRoutes";
import {DataSource, Filter, FilterCriterion, RestParams} from "./classes";
import {doGet, doPost, getFreeformToolMetadata} from "./util/RestUtil";
import {startCase} from "lodash";
import {parseDefaultSorts} from "./util/ToolUtils";
import {MyDataContext} from "./ToolsComponent";
import {mapLayout} from "./components/freeform/FreeformUtils";
import cloneDeep from "lodash.clonedeep";

export const FreeformToolContext         = React.createContext(undefined as any);
export const FreeformToolDispatchContext = React.createContext(undefined as any);

export default function JsonTool(props: any) {

    const [state]                                           = useContext(MyDataContext);
    const [data, setData]                                   = useState(null as any)
    const [layouts, setLayouts]                             = useState(props.tool.layouts as any)
    const [datasources, setDatasources]                     = useState(props.tool.datasources as DataSource[])
    const [detailID, setDetailID]                           = useState(props.tool.detailID)
    const [idColumnName, setIdColumnName]                   = useState(props.tool.idColumnName)
    const [displayTextColumnName, setDisplayTextColumnName] = useState(props.tool.displayTextColumnName as string)

    async function fetchMetadata() {

        if (!layouts || !datasources || !displayTextColumnName) {
            let [newDatasources, newLayouts, newDisplayTextColumnName]: any = await getFreeformToolMetadata(props.tool, state.currentCustomer?.id)
            props.tool.datasources                                          = newDatasources
            props.tool.layouts                                              = newLayouts
            props.tool.displayTextColumnName                                = newDisplayTextColumnName
            setDatasources(newDatasources)
            setLayouts(newLayouts)
            setDisplayTextColumnName(newDisplayTextColumnName)
            doLoadTool(newDatasources, newDisplayTextColumnName)
        } else {
            doLoadTool(datasources, displayTextColumnName)
        }


    }

    useEffect(() => {
        setDisplayTextColumnName(props.tool.displayTextColumnName)
    }, [props.tool.displayTextColumnName]);

    useEffect(() => {
        if (props.tool.isLoading) {
            fetchMetadata()
        }
    }, [props.tool.isLoading]);

    function updateParamFilters(value: any, params: RestParams, name: any) {

        if (!value || value.length === 0) {
            return {
                ...params,
                filters: [...params.filters.filter((f: Filter) => f.attr !== name)]
            }
        }

        const filter   = new Filter(name);
        const isArray  = Array.isArray(value);
        const isObject = typeof value == 'object'
        const multiple = isArray && value.length > 1;

        if (isObject && !isArray) {
            value = value['id']; // Default case for autocompleter
        } else if (isArray && !multiple) {
            value = value[0]
        }
        filter.addCriteria(isArray && multiple ? 'in' : 'eq', value)

        return {
            ...params,
            filters: [...params.filters.filter((f: Filter) => f.attr !== name), filter]
        }
    }

    function updateFilter(filteredDataSources: any, name: any, value: any, reload: 'RELOAD' | 'CLEAR' | 'CLEAR_ALL' | 'NONE') {


        switch (reload) {
            case "RELOAD":
                const newDatasources = datasources.map((d: any) => {
                    let ds = {...d}
                    if (filteredDataSources.map((x: any) => x.name).includes(ds.name)) {

                        if (!ds.params) {
                            ds.params = {
                                filters     : [],
                                sorts       : [],
                                paging      : {page: 0, rows: 20},
                                entityType  : ds.entityType,
                                toolType    : ds.toolType,
                                idColumnName: null
                            }
                        }


                        ds.params = updateParamFilters(value, ds.params, name);
                    }
                    return ds;
                });
                setDatasources(cloneDeep(newDatasources))
                doLoadTool(newDatasources, displayTextColumnName)
                break;
            case "CLEAR":
                datasources.forEach((ds: any) => {
                    if (filteredDataSources.map((x: any) => x.name).includes(ds.name)) {
                        if (!ds.params) {
                            ds.params = {
                                filters     : [],
                                sorts       : [],
                                paging      : {page: 0, rows: 20},
                                entityType  : ds.entityType,
                                toolType    : ds.toolType,
                                idColumnName: null
                            }
                        }

                        ds.params = updateParamFilters(value, ds.params, name);
                    }
                });
                doLoadTool(datasources, displayTextColumnName, true)
                break;
            case "NONE":
                datasources.forEach((ds: any) => {
                    if (filteredDataSources.map((x: any) => x.name).includes(ds.name)) {
                        if (!ds.params) {
                            ds.params = {
                                filters     : [],
                                sorts       : [],
                                paging      : {page: 0, rows: 20},
                                entityType  : ds.entityType,
                                toolType    : ds.toolType,
                                idColumnName: null
                            }
                        }

                        ds.params = updateParamFilters(value, ds.params, name);
                    }
                });
                break;

        }
    }

    function doAction(action: "RELOAD") {
        switch (action) {
            case "RELOAD":
                doLoadTool(datasources, displayTextColumnName)
        }
    }

    let dispatch = {
        handleFilterChange: (filteredDataSources: any, name: any, value: any, reload: 'RELOAD' | 'CLEAR' | 'CLEAR_ALL' | 'NONE') => updateFilter(filteredDataSources, name, value, reload),
        handleAction      : (action: 'RELOAD') => doAction(action),
    }


    async function doLoadTool(newDataSources: any, displayTextCol: string, clearData: boolean = false) {

        if (!newDataSources) {
            return
        }

        if (clearData) {
            setData({})
            return
        }

        let tool = props.tool
        console.log("Start load tool: " + tool.title)

        try {

            let config: any = {params: {count: 0}}

            if (tool.searchStr) {
                config.params['searchStr'] = tool.searchStr
            }

            if (tool.detailID) {
                config.params['detailID'] = tool.detailID
            }

            tool.error       = false
            tool.lastUpdated = new Date()

            if (!tool.title) {
                let title = startCase(tool.dbName)
                if (title.endsWith(" View")) {
                    title = title.substring(0, title.indexOf(" View"))
                }
                tool.title = title
            }

            if (tool.pagingInfo) {
                tool.pagingInfo.recordCount = null
                tool.pagingInfo.totalPages  = null
            }


            // save()

            let toolData = {...data}

            for (const ds of newDataSources) {
                let currentParams = ds.params

                if (!currentParams) {
                    currentParams = {
                        filters     : [],
                        sorts       : [],
                        paging      : {page: 0, rows: 20},
                        entityType  : ds.entityType,
                        toolType    : ds.toolType,
                        idColumnName: null
                    };
                    ds.params     = currentParams
                }

                if (tool.idColumnName) {
                    const filter = new Filter(tool.idColumnName)
                    filter.criteria.push(new FilterCriterion("eq", tool.detailID))
                    currentParams = {
                        ...currentParams,
                        filters     : [...currentParams.filters.filter((f: Filter) => f.attr !== tool.idColumnName), filter],
                        idColumnName: tool.idColumnName
                    }
                }

                const sortstr      = ds.source.split('|').find((s: string) => s.startsWith("SORTS="))
                const defaultSorts = parseDefaultSorts(sortstr)
                const source       = ds.source.split('|')[0]

                if (source.startsWith('FormQuestionnaireView')) {
                    const questionnaireID = ds.source.split('|').find((s: string) => s.startsWith("ID="))?.split("=")[1];
                    let url               = `${QUESTIONNAIRES_URL}/${questionnaireID}`
                    try {
                        const resp        = await doGet(url, config, state.currentCustomer?.id) as any
                        toolData[ds.name] = resp.data
                        setData({...toolData})
                    } catch (e) {
                        toolData[ds.name] = e
                        setData({...toolData})
                        tool.error = true
                    }

                } else {
                    let url = `${TOOLS_URL}/${source}PATH_UNIQUE_KEY=${ds.name}###`
                    try {
                        const resp = await doPost(url, {
                            ...currentParams,
                            sorts: [...currentParams.sorts, ...defaultSorts]
                        }, config, state.currentCustomer?.id) as any

                        toolData[ds.name] = resp.data
                        setData({...toolData})

                    } catch (e) {
                        toolData[ds.name] = e
                        setData({...toolData})
                        tool.error = true
                    }

                }

            }
            // if (displayTextCol) {
            //     //     let obj        = data ? jsonpath.query(data, props.meta.value)[0] : null;
            //     let customTitle = toolData ? jsonpath.query(toolData, displayTextCol)[0] : null
            //     if (customTitle !== null && props.tool.uuid) {
            //         const message = 'tool_customTitle';
            //         PubSub.publish(message, [props.tool.uuid, customTitle]);
            //     }
            //
            // }

            state.handleFreeformToolLoad(tool.uuid)
            console.log("End load tool: " + tool.title)


        } catch (e) {
            state.handleFreeformToolLoad(tool.uuid, true)
            console.error(e);
        }

    }

    const children = datasources ? layouts?.map((f: any) => mapLayout(f, props.data ? props.data : null)) : null;
    return (
        <FreeformToolContext.Provider value={[data, datasources, detailID, idColumnName]}>
            <FreeformToolDispatchContext.Provider value={dispatch}>
                {/*<pre>{JSON.stringify(tool, null, 2)}</pre>*/}
                {/*<pre>{JSON.stringify(datasources, null, 2)}</pre>*/}
                {children}
                {/*<pre>{JSON.stringify(layouts, null, 2)}</pre>*/}
            </FreeformToolDispatchContext.Provider>
        </FreeformToolContext.Provider>
    );
//             Layout = "custom-layout",
//         Switch = "switch-field",
//         Line = "line-field",
//         File = "file-field",
//         Group = "grid-item",
//         Paper = "paper-layout",
//         Outline = "outline-layout",
//         Expansion = "expansion-layout",
//         Radio = "radio-field",
//         Checkbox = "checkbox-field",
//         Text = "text-field",
//         Date = "date-field",
//         Time = "time-field",
//         Progress = "progress-field",
//         Component = "component-field",
//         Slider = "slider-field",
//         Combo = "combo-field",
//         Choose = "choose-field",
//         Init = "init-field",
//         Complete = "complete-field",
//         Items = "items-field",
//         Rating = "rating-field",
//         Typography = "typography-field",
//         Fragment = "fragment-layout",
//         Div = "div-layout",
//         Box = "box-layout",
//         Tabs = "tabs-layout",
//         Hero = "hero-layout",
//         Center = "center-layout",
//         Stretch = "stretch-layout",
//         Condition = "condition-layout"
}

JsonTool.propTypes = {
    metadata      : PropTypes.any,
    data          : PropTypes.any,
    tool: PropTypes.object,
    onFilterChange: PropTypes.func
}
