import React from "react";
import useFieldApi from "@data-driven-forms/react-form-renderer/use-field-api";
import PubSub from 'pubsub-js'
import {AUTOCOMPLETER_URL} from "../../../util/RestRoutes";
import AutocompleterComponent from "./AutocompleterComponent";
import useFormApi from "@data-driven-forms/react-form-renderer/use-form-api";

export default function AutocompleterCustom(props: any) {
    const {
              dbName,
              customerObjectID,
              formFieldValue,
              autocompleter,
              mappings,
              label,
              input,
              isRequired,
              meta
          }                  = useFieldApi(props);
    const {getState, change} = useFormApi();

    const {dirty, submitFailed, error} = meta;
    const isError                      = (dirty || submitFailed) && error && typeof error === 'string';

    // TODO: add placeholder to autocompleter input

    const onChange = (e: any, value: any) => {

        if (mappings && value) {
            let mappingStr = value.mapping

            Object.keys(mappings).forEach((k) => {
                const mapping = mappings[k];
                const message = 'self_' + mapping.formField;
                const data    = k === mappingStr ? value[mapping.formFieldValue || 'id'] : null;

                PubSub.publish(message, data);
            })

        } else {
            const message = 'autocompleter' + input.name;
            PubSub.publish(message, value);
        }

        input.onChange(value ? value[formFieldValue || 'id'] : null);

        if (autocompleter?.fillFields) {
            autocompleter.fillFields.forEach((ff: string) => {
                change(ff, value ? value[ff] : null);
            })
        }

    };

    return <AutocompleterComponent id={input.name + "_input"}
                                   isReadOnly={props.isReadOnly}
                                   isRequired={isRequired}
                                   label={label}
                                   customerObjectID={customerObjectID}
                                   autocompleter={autocompleter}
                                   name={input.name}
                                   onChange={onChange}
                                   url={`${AUTOCOMPLETER_URL}/${dbName}`}
                                   valid={meta.valid}
                                   value={input.value}
    />
}